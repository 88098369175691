import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Image,
  Button,
  VStack,
  HStack,
  Text,
  Wrap,
  WrapItem,
  Input,
  Stack,
  Radio,
  RadioGroup,
  Checkbox,
  Spinner,
  FormControl,
  Container,
  FormLabel,
  Select as Selected,
  Progress,
  Box,
  Avatar,
  Textarea,
  Link,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  Flex,
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";
import React, { useState, useEffect } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import {
  GradYear,
  AllsubjectsList,
  SchoolOptions,
  groupedOptions,
  graduationYearList,
} from "../../data/groupedOptions";
import { Images } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearState, newAccount } from "../../store/userSlice";
import PhoneNumberInput from "./PhoneNumberInput";
import { COUNTRIES } from "./countries";
import {
  FetchUniversidad,
  getAllSchool,
  getSubjects,
} from "../../utils/ApiConfig";
import {
  ChevronDownIcon,
  EditIcon,
  DeleteIcon,
  CloseIcon,
  AddIcon,
  ChevronRightIcon,
  InfoOutlineIcon,
  StarIcon,
} from "@chakra-ui/icons";
import { Remove } from "@mui/icons-material";
import { BsCamera } from "react-icons/bs";
import SelectedComponent from "../SelectComponent/SelectComponent";
import { MAIN_COLOR } from "../../utils/constants";

const Form = (props) => {
  const InputTextHandler = (e) => {
    // console.log(e.target.value);
    props.setInputText(e.target.value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (props.InputText !== "") {
      props.setTodo([
        ...props.todos,
        { email: props.InputText, id: Math.random() * 1000 },
      ]);
    }
    props.setInputText("");
  };
  const statusHandler = (e) => {
    props.setStatus(e.target.value);
    console.log(e.target.value);
  };

  return (
    <Stack
      m={3}
      direction={{ base: "column", sm: "row" }}
      align={"center"}
      w={"100%"}
      justify={"center"}
    >
      <Input
        w={{ base: "250px", sm: "300px" }}
        placeholder={"Email"}
        value={props.InputText}
        onChange={InputTextHandler}
        type="text"
      />
      <Button
        bgColor={"#FFE55F"}
        color={"white"}
        mr={3}
        onClick={submitHandler}
      >
        Agregar
      </Button>
    </Stack>
  );
};

const Todo = ({ setInputText, text, todo, todos, setTodo }) => {
  const deleteHandler = () => {
    setTodo(todos.filter((el) => el.id !== todo.id));
  };
  const EditHandler = () => {
    setTodo(
      todos.map((el) => {
        if (el.id === todo.id) {
          setInputText(el.email);
        }
        return el;
      })
    );
    setTodo(todos.filter((el) => el.id !== todo.id));
  };
  return (
    <Stack
      p={1}
      direction={"row"}
      align={"center"}
      justify={"space-between"}
      w={"100%"}
    >
      <Text fontSize={"80%"}>{text}</Text>
      <Stack direction={"row"}>
        <Button onClick={EditHandler}>
          <EditIcon />
        </Button>
        <Button onClick={deleteHandler}>
          <DeleteIcon />
        </Button>
      </Stack>
    </Stack>
  );
};

const TodoList = (props) => {
  return (
    <>
      <Stack>
        {props.todos.map(
          (todo) => {
            return (
              <Todo
                InputText={props.InputText}
                setInputText={props.setInputText}
                key={todo.id}
                todo={todo}
                todos={props.todos}
                setTodo={props.setTodo}
                text={todo.email}
              />
            );
          }
          // console.log(todo.Text);
        )}
      </Stack>
    </>
  );
};

/* this userModalComponent is used for loging temp user for creating there full account (i.e student or parent)  */
const UserInfoModal = ({ isOpen, onOpen, onClose: onCloseMod }) => {
  // const UserInfoModal = () => {
  // const { isOpen, onOpen, onClose: onCloseMod } = useDisclosure();
  // const {
  //   isOpen: isOpenModal,
  //   onOpen: onOpenModal,
  //   onClose: onCloseModal,
  // } = useDisclosure();
  const { userInfo, errorMessage, isFetching, isError, isNewAccount } =
    useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* UserModal Field  */
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [role, setRole] = useState("Student");
  const [school, setSchool] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  // const [SubjectList, setSubjectList] = useState([]);
  // makeing new changes to selecting subject list
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const [modal1, setModal1] = useState(false);
  const toast = useToast();
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(33.33);
  const [progressP, setProgressP] = useState(50);

  const [allSchool, setAllSchool] = useState([]);
  // let allSchool =[];
  const [allSubject, setAllSubject] = useState([]);
  const [getCountryCode, setGetCountryCode] = useState("");
  const [otherschool, setOtherSchool] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);

  const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    label: name,
    value: iso,
  }));

  useEffect(() => {
    if (isNewAccount) {
      toast({
        title: "Cuenta creada satisfactoriamente!",
        status: "success",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
      navigate("/dashboard");
      onCloseMod();
      dispatch(clearState());
    }
    // if (isError) {
    //   console.log("error");
    //   toast({
    //     title: errorMessage,
    //     status: "error",
    //     position: "top-center",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    //   dispatch(clearState());
    // }
  }, [isNewAccount, isError, errorMessage]);

  const CreateNewAccount = () => {
    if (
      name &&
      surname &&
      cellPhone &&
      role &&
      school &&
      graduationYear &&
      selectedSubjects &&
      todos
    ) {
      // const subjects = SubjectList.map((item) => item.value);
      const emails = todos.map((todo) => todo.email);
      const subjects = selectedSubjects.map((sub) => sub.id);
      const userData = {
        tempUserId: userInfo?._id,
        invite_emails: emails,
        newSchoolName: school === "Otro" ? otherschool.trim() : null,
        new_user: {
          role: role,
          country: getCountryCode,
          subjects,
          year: graduationYear,
          phone: cellPhone.trim(),
          name: name.trim(),
          surname: surname.trim(),
          school: school === "Otro" ? "" : school,
          family: userInfo.joinFamilyId,
          wants_news: userInfo.wantNews,
        },
      };
      console.log(userData);
      dispatch(newAccount(userData));
    }
  };

  const CreateNewAccountP = () => {
    if (name && surname && cellPhone && role && todos) {
      if (todos.length === 0) {
        toast({
          title: `Enter atleast one email address`,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 9000,
        });
      } else {
        const emails = todos.map((todo) => todo.email);

        const userData = {
          tempUserId: userInfo?._id,
          invite_emails: emails,
          new_user: {
            role: role,
            phone: cellPhone.trim(),
            name: name.trim(),
            surname: surname.trim(),
            family: userInfo.joinFamilyId,
            wants_news: userInfo.wantNews,
          },
        };
        // console.log(userData);
        dispatch(newAccount(userData));
      }
    }
  };

  const ModalClickHandle = () => {
    onOpen();
    setModal1(true);
  };

  // const SiguienteClickHandle = () => {
  //   onCloseMod();
  // };
  // NextHandler

  const NextHandler = async () => {
    // for school we need country code
    // for subject college name
    if (!getCountryCode) {
      toast({
        title: "Necesita escoger el país de su teléfono!",
        status: "error",
        position: "top-center",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setStep(step + 1);
    setProgress(progress + 33.33);
    setProgressP(progressP + 50);
    const school = await getAllSchool(getCountryCode);
    const subjects = await getSubjects();

    setAllSchool(school);
    setAllSubject(subjects);
  };

  const handleSchool = (e) => {
    // if otro then create custom input
    setSchool(e.target.value);
  };

  const handleYear = (e) => {
    setGraduationYear(e.target.value);
  };

  const [InputText, setInputText] = useState("");
  const [todos, setTodo] = useState([]);

  const [status, setStatus] = useState("all");

  const handleMultiSelectChange = (selected) => {
    setSelectedSubjects(selected);
  };
  const handleOptionDelete = (removedOptionId) => {
    const newSelectedOptions = selectedSubjects.filter(
      (option) => option.id !== removedOptionId
    );
    setSelectedSubjects(newSelectedOptions);
  };
  // tutorModal State
  const [AreaScores, setAreaScores] = useState([
    { name: "IB", score: "45" },
    { name: "SAT", score: "1600" },
  ]);
  const [languageLevel, setLanguageLevel] = useState([
    { name: "Inglés", level: "Avanzado" },
    { name: "Francés", level: "Intermedio" },
  ]);

  const [Carrera, setCarrera] = useState("");
  const [Universidad, setUniversidad] = useState(null);
  const [newUniversidad, setNewUniversidad] = useState(null);
  const [UniversidadOptions, setUniversidadOtions] = useState(null);
  const [newUniversidadCheck, setNewUniversidadCheck] = useState(false);

  const [achievements, setAchievements] = useState([
    "Descripción de la experiencia laboral",
    "Descripción del logro académico",
  ]);
  const [imageFile, setImageFile] = useState(null);
  // extra information state
  const [extraInformation, setExtraInformation] = useState("");

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };
  // areaScores
  const handleAreaChange = (e, index) => {
    const newAreaScores = [...AreaScores];
    newAreaScores[index].name = e.target.value;
    setAreaScores(newAreaScores);
  };

  const handleScoreChange = (e, index) => {
    const newAreaScores = [...AreaScores];
    newAreaScores[index].score = e.target.value;
    setAreaScores(newAreaScores);
  };
  const handleDeleteAreaScore = (index) => {
    const newAreaScores = [...AreaScores];
    newAreaScores.splice(index, 1);
    setAreaScores(newAreaScores);
  };

  const addAreaScoreHanlder = () => {
    const newAreaScores = [...AreaScores, { name: "", score: "" }];
    setAreaScores(newAreaScores);
  };

  // languageLevel

  const handleLanguageChange = (e, index) => {
    const newLanguage = [...languageLevel];
    newLanguage[index].name = e.target.value;
    setLanguageLevel(newLanguage);
  };
  const handleLanguageLevel = (e, index) => {
    const newLanguage = [...languageLevel];
    newLanguage[index].level = e.target.value;
    setLanguageLevel(newLanguage);
  };

  const addLanguageHanlder = () => {
    const newLanguage = [...languageLevel, { name: "", level: "" }];
    setLanguageLevel(newLanguage);
  };

  const handleDeleteLanguage = (index) => {
    const newLanguage = [...languageLevel];
    newLanguage.splice(index, 1);
    setLanguageLevel(newLanguage);
  };

  // textArea
  const achievementDeleteHandler = (index) => {
    const newLanguage = [...achievements];
    newLanguage.splice(index, 1);
    setAchievements(newLanguage);
  };

  const achievementHandler = (e, index) => {
    const newAchievement = [...achievements];
    newAchievement[index] = e.target.value;
    setAchievements(newAchievement);
  };

  const AddAchievementHandler = () => {
    const newAchievement = [...achievements, ""];
    setAchievements(newAchievement);
  };

  // createNewAccountTutor
  const CreateNewAccountTutor = () => {
    if (name && surname && cellPhone && role) {
      const userData = {
        tempUserId: userInfo?._id,
        new_user: {
          role: role,
          country: getCountryCode,
          phone: cellPhone.trim(),
          name: name.trim(),
          surname: surname.trim(),
          wants_news: userInfo.wantNews || false,
          AreaScores,
          languageLevel,
          Carrera,
          Universidad:
            Universidad.value === "Otro"
              ? newUniversidad.value
              : Universidad.value,
          achievements,
          extraInformation,
          imageFile,
        },
      };
      console.log(userData);
      // dispatch(newAccount(userData));
    }
  };
  // Description
  const [tutorDescriptionScores, setTutorDescriptionScores] = useState("");
  const [tutorDescriptionAchievements, setTutorDescriptionAchievements] =
    useState("");
  const [tutorDescriptionMajor, setTutorDescriptionMajor] = useState("");
  const [tutorDescriptionLanguage, setTutorDescriptionLanguage] = useState("");
  const [tutorYoutubeLink, setTutorYoutubeLink] = useState("");
  const [tutorSelfDescription, setTutorSelfDescription] = useState("");
  const [currency, setCurrency] = useState("");

  useEffect(() => {
    async function getUniversidad() {
      try {
        const response = await FetchUniversidad();

        let allUniversidad = response?.map((item) => {
          return {
            id: item._id,
            value: item.name,
            label: item.name,
          };
        });
        allUniversidad = [
          { label: "Mi colegio no aparece", value: "Otro", id: "Otro" },
          ...allUniversidad,
        ];

        setUniversidadOtions(allUniversidad);
      } catch (err) {
        console.error(err);
      }
    }
    getUniversidad();
  }, []);
  useEffect(() => {
    if (Universidad?.value === "Otro") {
      setNewUniversidadCheck(true);
    } else {
      setNewUniversidadCheck(false);
    }
  }, [Universidad]);

  const newUniversidadHandler = (e) => {
    setNewUniversidad({
      label: "My universidad",
      value: e.target.value,
      id: "Otro",
    });
  };
  return (
    <>
      <Button display={"none"} onClick={ModalClickHandle}>
        Update Profile
      </Button>

      <Modal
        isOpen={isOpen}
        size={"2xl"}
        closeOnOverlayClick={false}
        onClose={onCloseMod}
      >
        <ModalOverlay>
          <Image src={Images.LOGIN_OVERLAY} />
        </ModalOverlay>
        {step === 1 ? (
          <>
            <ModalContent
              p={10}
              w={{ base: "100vw", sm: "90%", md: "70%", lg: "65%", xl: "50%" }}
            >
              <Progress
                hasStripe
                value={role === "Student" ? progress : progressP}
                mb="5%"
                mx="5%"
                isAnimated
                // bgColor={MAIN_COLOR}
                colorScheme="teal"
              ></Progress>
              <VStack m={5}>
                <Image src={Images.XPLAIN_LOGO} alt="..." />
                <ModalHeader textAlign={"center"} fontSize={"200%"}>
                  ¡Completa tu registro!
                </ModalHeader>
              </VStack>
              <ModalBody justify={"center"}>
                <Wrap mt={10} spacing={9}>
                  <WrapItem>
                    <Stack>
                      <Text fontWeight={500}>Nombre</Text>
                      <Input
                        w={"250px"}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Stack>
                  </WrapItem>
                  <WrapItem>
                    <Stack>
                      <Text fontWeight={500}>Apellido</Text>
                      <Input
                        w={"250px"}
                        type="text"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                      />
                    </Stack>
                  </WrapItem>
                  <WrapItem>
                    <Stack>
                      <Text fontWeight={500}>Celular</Text>
                      <PhoneNumberInput
                        value={cellPhone}
                        options={countryOptions}
                        setGetCountryCode={setGetCountryCode}
                        onChange={(value) => setCellPhone(value)}
                      />
                    </Stack>
                  </WrapItem>
                </Wrap>
                <Wrap mt={10} spacing={5} justify={"space-between"}>
                  <WrapItem>
                    <Stack>
                      <Text fontWeight={"500"}>Soy</Text>
                      <RadioGroup onChange={setRole} value={role}>
                        <Stack>
                          <Radio
                            onChange={(e) => setRole(e.target.value)}
                            value="Student"
                          >
                            Estudiante
                          </Radio>
                          <Radio
                            onChange={(e) => setRole(e.target.value)}
                            value="Parent"
                          >
                            Padre/Madre
                          </Radio>
                          {/* <Radio
                            onChange={(e) => setRole(e.target.value)}
                            value="Tutor"
                          >
                            Profesor
                          </Radio> */}
                        </Stack>
                      </RadioGroup>
                    </Stack>
                  </WrapItem>
                  <WrapItem>
                    <Stack>
                      <Text fontWeight={"500"}>
                        ¿Qué recursos/guías te interesan?
                      </Text>
                      <Checkbox
                        defaultChecked
                        // onChange={(e) =>
                        //   selectProfile(e.target.value, "resources.IB")
                        // }
                        // value={true}
                      >
                        Bachillerato Internacional IB
                      </Checkbox>
                      <Checkbox
                        // onChange={(e) =>
                        //   selectProfile(e.target.value, "resources.IB")
                        // }
                        value={true}
                      >
                        Universidad americanas
                      </Checkbox>
                    </Stack>
                  </WrapItem>
                </Wrap>
              </ModalBody>

              <ModalFooter mt={8}>
                <Text p={2} color={"#5555"}>
                  1/3
                </Text>
                {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button> */}
                {/* <UserInfoFinal onClick={onCloseMod} /> */}
                <Button
                  bgColor={MAIN_COLOR}
                  color={"white"}
                  onClick={NextHandler}
                >
                  Siguiente
                </Button>
              </ModalFooter>
            </ModalContent>
          </>
        ) : (
          <>
            {role === "Student" ? (
              <>
                {step === 2 ? (
                  <>
                    <ModalContent
                      p={10}
                      w={{
                        base: "100vw",
                        sm: "90%",
                        md: "70%",
                        lg: "65%",
                        xl: "50%",
                      }}
                    >
                      <Progress
                        hasStripe
                        value={progress}
                        mb="5%"
                        mx="5%"
                        colorScheme="teal"
                        isAnimated
                      ></Progress>
                      <VStack m={5}>
                        <Image src={Images.XPLAIN_LOGO} alt="..." />
                        <ModalHeader textAlign={"center"} fontSize={"150%"}>
                          {`¡Bienvenido, ${name}! Cuéntanos sobre ti...`}
                        </ModalHeader>
                      </VStack>
                      <ModalBody>
                        <Container mt={10}>
                          <FormControl>
                            <WrapItem>
                              <Stack direction={{ base: "column", lg: "row" }}>
                                <Stack>
                                  <FormLabel w={"250px"} fontWeight={500}>
                                    Colegio
                                  </FormLabel>
                                  <Selected
                                    top="0"
                                    left="0"
                                    opacity={1}
                                    height={"5vh"}
                                    w={"250px"}
                                    value={school}
                                    onChange={handleSchool}
                                    icon={<ChevronDownIcon />}
                                  >
                                    <option value="" />
                                    {allSchool.map((schools) => (
                                      <option value={schools.value}>
                                        {schools.label}
                                      </option>
                                    ))}
                                  </Selected>
                                  {school === "Otro" ? (
                                    <>
                                      <FormLabel w={"250px"} fontWeight={500}>
                                        Ingrese el nombre de su escuela
                                      </FormLabel>
                                      <Input
                                        w={"250px"}
                                        type="text"
                                        // value={}
                                        onChange={(e) =>
                                          setOtherSchool(e.target.value)
                                        }
                                      />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Stack>
                                <Stack>
                                  <FormLabel w={"250px"} fontWeight={500}>
                                    Año de Graduación
                                  </FormLabel>
                                  <Selected
                                    top="0"
                                    left="0"
                                    opacity={1}
                                    height={"5vh"}
                                    w={"250px"}
                                    value={graduationYear}
                                    onChange={handleYear}
                                    icon={<ChevronDownIcon />}
                                  >
                                    <option value="" />
                                    {graduationYearList.map((grad) => (
                                      <option value={grad.value}>
                                        {grad.label}
                                      </option>
                                    ))}
                                  </Selected>
                                </Stack>
                              </Stack>
                            </WrapItem>
                            <WrapItem>
                              <Stack
                                marginTop={5}
                                width={"100%"}
                                display={"flex"}
                                flexDir={{ base: "column", md: "row" }}
                                gap={4}
                                alignItems={"flex-Start"}
                              >
                                <Stack width={{ base: "100%", md: "50%" }}>
                                  <Container width={"100%"} p={0}>
                                    <FormControl>
                                      <FormLabel>
                                        ¿En qué materias necesitas ayuda?
                                      </FormLabel>
                                      <Select
                                        value={selectedSubjects}
                                        isMulti
                                        name="subjects"
                                        classNamePrefix="chakra-react-select"
                                        options={allSubject}
                                        placeholder="Select  your subjects .."
                                        closeMenuOnSelect={false}
                                        size="sm"
                                        onChange={handleMultiSelectChange}
                                        controlShouldRenderValue={false}
                                      />
                                    </FormControl>
                                  </Container>
                                </Stack>

                                <Stack
                                  maxH={"20vh"}
                                  overflowY={"scroll"}
                                  width={{ base: "100%", md: "50%" }}
                                  display={"grid"}
                                >
                                  {selectedSubjects.map((item, index) => {
                                    return (
                                      <WrapItem
                                        alignItems={"center"}
                                        rounded={"md"}
                                        px={3}
                                        py={2}
                                        display={"flex"}
                                        justify={"space-between"}
                                        gap={2}
                                        fontSize={"50%"}
                                        bgColor={"#C3C3C340"}
                                        color={"black"}
                                        width={"max-content"}
                                        key={`subjects-${index}`}
                                        cursor={"pointer"}
                                        onClick={() =>
                                          handleOptionDelete(item.id)
                                        }
                                      >
                                        <CloseIcon />
                                        <Text width={"max-content"}>
                                          {" "}
                                          {item.label}
                                        </Text>
                                      </WrapItem>
                                    );
                                  })}
                                </Stack>
                              </Stack>
                            </WrapItem>
                          </FormControl>
                        </Container>
                      </ModalBody>

                      <ModalFooter mt={10}>
                        <Text p={2} color={"#5555"}>
                          2/3
                        </Text>
                        <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={() => {
                            setStep(step - 1);
                            setProgress(progress - 33.3);
                          }}
                        >
                          Volver
                        </Button>
                        <Button
                          leftIcon={isFetching ? <Spinner /> : null}
                          bgColor={MAIN_COLOR}
                          color={"white"}
                          onClick={() => {
                            setStep(step + 1);
                            setProgress(progress + 33.33);
                          }}
                        >
                          Siguiente
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </>
                ) : (
                  <>
                    <ModalContent
                      p={10}
                      w={{
                        base: "100vw",
                        sm: "90%",
                        md: "70%",
                        lg: "65%",
                        xl: "50%",
                      }}
                    >
                      <Progress
                        hasStripe
                        value={progress}
                        mb="5%"
                        mx="5%"
                        colorScheme="teal"
                        isAnimated
                      ></Progress>
                      <VStack m={5}>
                        <Image src={Images.XPLAIN_LOGO} alt="..." />
                        <ModalHeader textAlign={"center"} fontSize={"150%"}>
                          {/* {`Eso es todo, ${name}!`} */}
                          Un honor que seas parte de IB Masters, {name}! 🤩
                        </ModalHeader>
                      </VStack>
                      <ModalBody>
                        {/* <Text textAlign="center">
                          Un honor que seas parte de IB Masters! 🤩
                        </Text> */}
                        <Text textAlign="center">
                          En caso quieras registrar a más usuarios como parte de
                          tu familia...
                        </Text>
                        <Container mt={10}>
                          <FormControl>
                            <Form
                              todos={todos}
                              setTodo={setTodo}
                              InputText={InputText}
                              setInputText={setInputText}
                              setStatus={setStatus}
                            />
                            <TodoList
                              InputText={InputText}
                              setInputText={setInputText}
                              // filteredTodo={filteredTodo}
                              setTodo={setTodo}
                              todos={todos}
                            />
                            <Text
                              textAlign={"center"}
                              fontSize={{ base: "60%", md: "70%" }}
                              fontWeight={500}
                            >
                              Se les enviará emails a ellos para que se
                              registren como parte de tu familia
                            </Text>
                          </FormControl>
                        </Container>
                      </ModalBody>

                      <ModalFooter mt={10}>
                        <Text p={2} color={"#5555"}>
                          3/3
                        </Text>
                        <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={() => {
                            setStep(step - 1);
                            setProgress(progress - 33.33);
                          }}
                        >
                          Volver
                        </Button>
                        <Button
                          leftIcon={isFetching ? <Spinner /> : null}
                          bgColor={MAIN_COLOR}
                          color={"white"}
                          onClick={CreateNewAccount}
                        >
                          Listo
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </>
                )}
              </>
            ) : (
              <>
                {role === "Parent" ? (
                  <>
                    <ModalContent
                      p={10}
                      w={{
                        base: "100vw",
                        sm: "90%",
                        md: "70%",
                        lg: "65%",
                        xl: "50%",
                      }}
                    >
                      <Progress
                        hasStripe
                        value={progressP}
                        mb="5%"
                        mx="5%"
                        colorScheme="teal"
                        isAnimated
                      ></Progress>
                      <VStack m={5}>
                        <Image src={Images.XPLAIN_LOGO} alt="..." />
                        <ModalHeader textAlign={"center"} fontSize={"150%"}>
                          {/* {`¡Bienvenido, ${name}! Cuéntanos sobre tu hijo/a...`} */}
                          Un honor que seas parte de IB Masters, {name}! 🤩
                        </ModalHeader>
                      </VStack>
                      <ModalBody>
                        <Container mt={10}>
                          {/* <Text textAlign="center">
                            Un honor que seas parte de IB Masters! 🤩
                          </Text> */}
                          {/* <Text textAlign="center">
                            Una vez creada tu cuenta podrás invitar a otros
                            miembros de tu familia
                          </Text> */}
                          <Text textAlign="center">
                            En caso quieras registrar a más usuarios como parte
                            de tu familia...
                          </Text>
                          <FormControl>
                            <Form
                              todos={todos}
                              setTodo={setTodo}
                              InputText={InputText}
                              setInputText={setInputText}
                              setStatus={setStatus}
                            />
                            <TodoList
                              InputText={InputText}
                              setInputText={setInputText}
                              // filteredTodo={filteredTodo}
                              setTodo={setTodo}
                              todos={todos}
                            />
                            <Text
                              textAlign={"center"}
                              fontSize={{ base: "60%", md: "70%" }}
                              fontWeight={500}
                            >
                              Se les enviará emails a ellos para que se
                              registren como parte de tu familia
                            </Text>
                          </FormControl>
                        </Container>
                      </ModalBody>

                      <ModalFooter mt={10}>
                        <Text p={2} color={"#5555"}>
                          2/2
                        </Text>
                        <Button
                          colorScheme="blue"
                          mr={3}
                          onClick={() => {
                            setStep(step - 1);
                            setProgress(progress - 50);
                          }}
                        >
                          Volver
                        </Button>
                        <Button
                          leftIcon={isFetching ? <Spinner /> : null}
                          bgColor={MAIN_COLOR}
                          color={"white"}
                          onClick={CreateNewAccountP}
                        >
                          Listo
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </>
                ) : (
                  <>
                    {step === 2 ? (
                      <ModalContent
                        p={{ base: 5, md: 5 }}
                        maxW={{ base: "initial", md: "4xl" }}
                        w={{
                          base: "100vw",
                          sm: "90%",
                          md: "70vw",
                          lg: "70vw",
                          xl: "70vw",
                        }}
                      >
                        {/* <Progress
                          hasStripe
                          value={progressP}
                          mb="5%"
                          mx="5%"
                          colorScheme="teal"
                          isAnimated
                        ></Progress> */}
                        <VStack>
                          <Image
                            width={"30%"}
                            height={"max-content"}
                            src={Images.XPLAIN_LOGO}
                            alt="..."
                          />
                          <ModalHeader
                            textAlign={"center"}
                            fontSize={{ base: "xl", md: "3xl" }}
                            fontWeight={600}
                          >
                            Cuéntanos sobre ti...
                          </ModalHeader>
                        </VStack>

                        <ModalBody>
                          {/* pic,score,language */}
                          <HStack
                            gap={5}
                            alignItems={{
                              base: "flex-start",
                              md: "flex-start",
                            }}
                            flexDir={{ base: "column", md: "row" }}
                          >
                            {/* <Box display={"flex"} flexDir={"column"} gap={2}>
                              <Text fontSize={"xl"} fontWeight={500}>
                                Foto{" "}
                              </Text>
                              <Box
                                position={"relative"}
                                width={"100%"}
                                height={"100%"}
                              >
                                <WrapItem width={"100%"} height={"100%"}>
                                  <Avatar
                                    name={`${name} ${surname}`}
                                    size={"xl"}
                                    src={
                                      imageFile
                                        ? URL.createObjectURL(imageFile)
                                        : null
                                    }
                                    backgroundColor={"#00000078"}
                                    onMouseEnter={() =>
                                      setMouseHover(!mouseHover)
                                    }
                                    onMouseLeave={() =>
                                      setMouseHover(!mouseHover)
                                    }
                                  >
                                    {mouseHover && (
                                      <BsCamera
                                        top={0}
                                        left={0}
                                        color={"#000000"}
                                        style={{
                                          zIndex: 1,
                                          position: "absolute",
                                        }}
                                        size="30px"
                                      />
                                    )}
                                    <Input
                                      type="file"
                                      height="100%"
                                      width="100%"
                                      position="absolute"
                                      top="0"
                                      left="0"
                                      opacity="0"
                                      zIndex={5}
                                      aria-hidden="true"
                                      accept="image/*"
                                      cursor={"pointer"}
                                      onChange={handleFileChange}
                                    />
                                  </Avatar>
                                </WrapItem>
                              </Box>
                            </Box> */}
                            <Box
                              width={"100%"}
                              display={"flex"}
                              flexDir={"column"}
                              gap={2}
                            >
                              {/* <Text fontSize={"lg"} fontWeight={500} as="span">
                                Notas IB y/o SAT{" "}
                                <Popover
                                  placement="top-start"
                                  trigger="hover"
                                  marginLeft={10}
                                >
                                  <PopoverTrigger>
                                    <InfoOutlineIcon
                                      boxSize={"15px"}
                                      color={"#07A58E"}
                                    />
                                  </PopoverTrigger>
                                  <PopoverContent w={"200px"}>
                                    <PopoverBody>
                                      {" "}
                                      <Text
                                        fontSize={"70%"}
                                        textAlign={"start"}
                                      >
                                        Aqui sses un buen lugar para que
                                        coloques notas en IB y/o SAT
                                      </Text>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              </Text>

                              <Box
                                width={"100%"}
                                alignItems={"center"}
                                gap={3}
                              >
                                <Input
                                  backgroundColor={"#F3F3F3"}
                                  value={tutorDescriptionScores}
                                  onChange={(e) =>
                                    setTutorDescriptionScores(e.target.value)
                                  }
                                />
                              </Box> */}
                              {/* <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Text fontSize={"md"}>Selecciona área</Text>
                                <Text fontSize={"md"}>Puntaje</Text>
                              </Box>
                              {AreaScores.map((areaScore, index) => {
                                return (
                                  <Box
                                    key={index}
                                    width={"100%"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1.5}
                                  >
                                    <Selected
                                      color={MAIN_COLOR}
                                      value={areaScore.name}
                                      onChange={(e) =>
                                        handleAreaChange(e, index)
                                      }
                                    >
                                      <option value="IB">IB</option>
                                      <option value="SAT">SAT</option>
                                      <option value="Tutorías">Tutorías</option>
                                    </Selected>

                                    {areaScore.name === "Otro" && (
                                      <Input
                                        // value={areaScore.name}
                                        onChange={(e) =>
                                          handleAreaChange(e, index)
                                        }
                                      />
                                    )}
                                    <Input
                                      value={areaScore.score}
                                      onChange={(e) =>
                                        handleScoreChange(e, index)
                                      }
                                    />
                                    <DeleteIcon
                                      cursor={"pointer"}
                                      onClick={(index) =>
                                        handleDeleteAreaScore(index)
                                      }
                                    />
                                  </Box>
                                );
                              })}
                              <Button
                                backgroundColor={MAIN_COLOR}
                                display={"flex"}
                                color={"white"}
                                alignItems={"center"}
                                alignSelf={"flex-end"}
                                gap={1}
                                cursor={"pointer"}
                                onClick={addAreaScoreHanlder}
                              >
                                <AddIcon />
                                <Text> Añadir</Text>
                              </Button> */}
                            </Box>
                            {/* Idiomas */}

                            {/* <Box
                              width={"100%"}
                              alignItems={"center"}
                              gap={3}
                              flexDir={"column"}
                            >
                              <Text fontSize={"lg"} fontWeight={500}>
                                Logros académicos y/o experiencia laboral{" "}
                                <Popover placement="top-start" trigger="hover">
                                  <PopoverTrigger>
                                    <InfoOutlineIcon
                                      boxSize={"15px"}
                                      color={"#07A58E"}
                                    />
                                  </PopoverTrigger>
                                  <PopoverContent w={"200px"}>
                                    <PopoverBody>
                                      {" "}
                                      <Text
                                        fontSize={"70%"}
                                        textAlign={"start"}
                                      >
                                        Puedes poner cualquier logro académico
                                        que tengas (de preferencia algo corto)
                                      </Text>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              </Text>
                              <Box
                                width={"100%"}
                                // display={{ base: "none", md: "flex" }}
                                alignItems={"center"}
                                gap={3}
                              >
                                <Input
                                  backgroundColor={"#F3F3F3"}
                                  value={tutorDescriptionAchievements}
                                  onChange={(e) =>
                                    setTutorDescriptionAchievements(
                                      e.target.value
                                    )
                                  }
                                  type="text"
                                />
                              </Box>
                            </Box> */}
                          </HStack>

                          {/* Carrera,Universidad */}
                          {/* <HStack
                            marginTop={3}
                            width={"100%"}
                            alignItems={"center"}
                            flexDir={{ base: "column", md: "row" }}
                          >
                            <FormControl>
                              <FormLabel>Carrera</FormLabel>
                              <Input
                                value={Carrera}
                                onChange={(e) => setCarrera(e.target.value)}
                                placeholder="Carrera"
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel>Universidad</FormLabel>{" "}
                              <SelectedComponent
                                Value="Universidad"
                                dataOptions={UniversidadOptions}
                                setSelectedOptions={setUniversidad}
                              />
                              {newUniversidadCheck && (
                                <Box
                                  display={"flex"}
                                  flexDir={"column"}
                                  gap={2}
                                >
                                  <Text>Enter your University</Text>
                                  <Input
                                    backgroundColor={"#F3F3F3"}
                                    value={newUniversidad?.value}
                                    onChange={newUniversidadHandler}
                                    type="text"
                                  />
                                </Box>
                              )}

                            </FormControl>
                          </HStack> */}

                          <VStack
                            width={"100%"}
                            alignItems={"flex-start"}
                            marginTop={3}
                            gap={4}
                          >
                            <Box
                              width={"100%"}
                              display={"flex"}
                              flexDir={"column"}
                              gap={2}
                            >
                              {/* <Text fontSize={"lg"} fontWeight={500}>
                                Idiomas 
                                <Popover
                                  placement="top-start"
                                  trigger="hover"
                                  marginLeft={10}
                                >
                                  <PopoverTrigger>
                                    <InfoOutlineIcon
                                      boxSize={"15px"}
                                      color={"#07A58E"}
                                    />
                                  </PopoverTrigger>
                                  <PopoverContent w={"200px"}>
                                    <PopoverBody>
                                      {" "}
                                      <Text
                                        fontSize={"70%"}
                                        textAlign={"start"}
                                      >
                                        Por ejemplo: Inglés (avanzado), Español
                                        (nativo)
                                      </Text>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                              </Text> */}
                              {/* <Box
                                width={"100%"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"flex-start"}
                              >
                                <Box
                                  width={"100%"}
                                  display={{ base: "none", md: "flex" }}
                                  alignItems={"center"}
                                  gap={3}
                                >
                                  <Input
                                    backgroundColor={"#F3F3F3"}
                                    value={tutorDescriptionLanguage}
                                    onChange={(e) =>
                                      setTutorDescriptionLanguage(
                                        e.target.value
                                      )
                                    }
                                    type="text"
                                  />
                                </Box>

                                <Box
                                  width={"100%"}
                                  display={{ base: "flex", md: "none" }}
                                  alignItems={"center"}
                                  gap={3}
                                >
                                  <Input
                                    backgroundColor={"#F3F3F3"}
                                    value={tutorDescriptionLanguage}
                                    onChange={(e) =>
                                      setTutorDescriptionLanguage(
                                        e.target.value
                                      )
                                    }
                                    type="text"
                                  />
                                </Box>
                              </Box> */}
                              {/* <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Text fontSize={"md"}>Selecciona idioma</Text>
                                <Text fontSize={"md"}>Nivel</Text>
                              </Box>
                              {languageLevel.map((idioma, index) => {
                                return (
                                  <Box
                                    key={index}
                                    width={"100%"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={1.5}
                                  >
                                    <Selected
                                      color={MAIN_COLOR}
                                      value={idioma.name}
                                      onChange={(e) =>
                                        handleLanguageChange(e, index)
                                      }
                                    >
                                      <option value="Inglés">Inglés</option>
                                      <option value="Francés">Francés</option>
                                      <option value="English">English</option>
                                    </Selected>
                                    <Selected
                                      value={idioma.level}
                                      onChange={(e) =>
                                        handleLanguageLevel(e, index)
                                      }
                                    >
                                      <option value="Principiante">
                                        Principiante
                                      </option>
                                      <option value="Intermedio">
                                        Intermedio
                                      </option>
                                      <option value="Avanzado">Avanzado</option>
                                    </Selected>

                                    <DeleteIcon
                                      cursor={"pointer"}
                                      onClick={(index) =>
                                        handleDeleteLanguage(index)
                                      }
                                    />
                                  </Box>
                                );
                              })}
                              <Button
                                backgroundColor={MAIN_COLOR}
                                color={"white"}
                                display={"flex"}
                                alignItems={"center"}
                                alignSelf={"flex-end"}
                                gap={1}
                                cursor={"pointer"}
                                onClick={addLanguageHanlder}
                              >
                                <AddIcon />
                                <Text> Añadir</Text>
                              </Button> */}
                            </Box>
                            {/* {achievements?.map((achievement, index) => {
                              return (
                                <Box
                                  gap={2}
                                  width={"100%"}
                                  key={index}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Textarea
                                    value={achievement}
                                    onChange={(e) =>
                                      achievementHandler(e, index)
                                    }
                                    placeholder="write your achivements"
                                    size="lg"
                                  />
                                  <DeleteIcon
                                    cursor={"pointer"}
                                    onClick={(index) =>
                                      achievementDeleteHandler(index)
                                    }
                                  />
                                </Box>
                              );
                            })}
                            <Button
                              backgroundColor={MAIN_COLOR}
                              color={"white"}
                              display={"flex"}
                              alignItems={"center"}
                              alignSelf={"flex-end"}
                              gap={1}
                              cursor={"pointer"}
                              onClick={AddAchievementHandler}
                            >
                              <AddIcon />
                              <Text> Añadir</Text>
                            </Button> */}
                          </VStack>
                          <WrapItem>
                            <Stack
                              marginTop={5}
                              width={"100%"}
                              display={"flex"}
                              flexDir={{ base: "column", md: "row" }}
                              gap={4}
                              alignItems={"flex-Start"}
                            >
                              <Stack
                                width={{ base: "100%", md: "50%" }}
                                marginBottom={5}
                              >
                                <Container width={"100%"} p={0}>
                                  <FormControl>
                                    <FormLabel>
                                      ¿En qué materias puedes ayudar?
                                    </FormLabel>
                                    <Select
                                      value={selectedSubjects}
                                      isMulti
                                      name="subjects"
                                      classNamePrefix="chakra-react-select"
                                      options={allSubject}
                                      placeholder="Select  your subjects .."
                                      closeMenuOnSelect={false}
                                      size="sm"
                                      onChange={handleMultiSelectChange}
                                      controlShouldRenderValue={false}
                                    />
                                  </FormControl>
                                </Container>
                              </Stack>

                              <Stack
                                maxH={"20vh"}
                                overflowY={"scroll"}
                                width={{ base: "100%", md: "50%" }}
                                display={"grid"}
                              >
                                {selectedSubjects.map((item, index) => {
                                  return (
                                    <WrapItem
                                      alignItems={"center"}
                                      rounded={"md"}
                                      px={3}
                                      py={2}
                                      display={"flex"}
                                      justify={"space-between"}
                                      gap={2}
                                      fontSize={"50%"}
                                      bgColor={"#C3C3C340"}
                                      color={"black"}
                                      width={"max-content"}
                                      key={`subjects-${index}`}
                                      cursor={"pointer"}
                                      onClick={() =>
                                        handleOptionDelete(item.id)
                                      }
                                    >
                                      <CloseIcon />
                                      <Text width={"max-content"}>
                                        {" "}
                                        {item.label}
                                      </Text>
                                    </WrapItem>
                                  );
                                })}
                              </Stack>
                            </Stack>
                          </WrapItem>
                          {/* <Stack
                            p={10}
                            rounded={"lg"}
                            bgColor={"white"}
                            boxShadow={"lg"}
                            w={{ base: "80%", md: "50%" }}
                            className="select-container"
                          > */}
                          {/* </Stack> */}
                          {/* <VStack alignItems={"flex-start"} gap={3}>
                            <Text fontSize={"lg"} fontWeight={500}>
                              Sobre mí
                            </Text>
                            <Textarea
                              value={extraInformation}
                              onChange={(e) =>
                                setExtraInformation(e.target.value)
                              }
                              placeholder="Aquí puedes ir en detalle sobre lo que desees"
                              size="lg"
                            />
                          </VStack> */}
                          <Stack
                            justify={"space-between"}
                            direction="row"
                            marginTop={10}
                          >
                            <Stack w={"100%"}>
                              <Text fontSize={"lg"} fontWeight={500}>
                                Con qué moneda te pagaremos?
                              </Text>
                              <Flex flex={1} justifyContent={"space-around"}>
                                <Button
                                  opacity={currency === "USD" ? 1 : 0.5}
                                  onClick={() => setCurrency("USD")}
                                >
                                  🇺🇸 USD
                                </Button>
                                <Button
                                  opacity={currency === "PEN" ? 1 : 0.5}
                                  onClick={() => setCurrency("PEN")}
                                >
                                  🇵🇪 PEN
                                </Button>
                              </Flex>
                            </Stack>
                          </Stack>
                        </ModalBody>

                        {/* footer */}
                        <ModalFooter mt={10}>
                          <Text p={2} color={"#5555"}>
                            2/3
                          </Text>
                          <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={() => {
                              setStep(step - 1);
                              setProgress(progress - 50);
                            }}
                          >
                            Volver
                          </Button>
                          <Button
                            backgroundColor={MAIN_COLOR}
                            color={"white"}
                            mr={3}
                            onClick={() => {
                              setStep(step + 1);
                              setProgress(progress - 50);
                            }}
                          >
                            Siguiente
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    ) : (
                      <ModalContent
                        p={{ base: 5, md: 5 }}
                        maxW={{ base: "initial", md: "3xl" }}
                        w={{
                          base: "100vw",
                          sm: "90%",
                          md: "70vw",
                          lg: "70vw",
                          xl: "70vw",
                        }}
                      >
                        <VStack>
                          <Image
                            width={"30%"}
                            height={"max-content"}
                            src={Images.XPLAIN_LOGO}
                            alt="..."
                          />
                          <ModalHeader
                            textAlign={"center"}
                            fontSize={{ base: "xl", md: "3xl" }}
                            fontWeight={600}
                          >
                            ¡Crea un calendario!
                          </ModalHeader>
                        </VStack>
                        <ModalBody>
                          <Text>
                            Esto es necesario para que los estudiantes puedan
                            ver qué horas tienes disponible.
                          </Text>
                          <Box
                            marginTop={10}
                            display={"flex"}
                            flexDir={"column"}
                            gap={5}
                          >
                            <Text fontSize={"xl"} fontWeight={500}>
                              {" "}
                              Pasos a seguir:
                            </Text>
                            <HStack alignItems={"flex-start"} gap={1}>
                              <Text fontSize={"lg"} fontWeight={400}>
                                1.
                              </Text>{" "}
                              <Text fontSize={"lg"} fontWeight={400}>
                                Crea un calendario de Google con este formato
                                Nombre-Apellido-IBMasters (Por ejemplo,
                                Raul-Alcantara-IBMasters)
                              </Text>
                            </HStack>

                            <HStack alignItems={"flex-start"} gap={1}>
                              <Text fontSize={"lg"} fontWeight={400}>
                                2.
                              </Text>{" "}
                              <Text fontSize={"lg"} fontWeight={400}>
                                Crea un calendario de Google con este formato
                                Nombre-Apellido-IBMasters (Por ejemplo,
                                Raul-Alcantara-IBMasters)
                              </Text>
                            </HStack>
                            <HStack alignItems={"flex-start"} gap={1}>
                              <Text fontSize={"lg"} fontWeight={400}>
                                3.
                              </Text>{" "}
                              <Text fontSize={"lg"} fontWeight={400}>
                                Cuando termines este calendario con tus bloques
                                de horas libres semanales,{" "}
                                <Link
                                  color={MAIN_COLOR}
                                  href="https://www.youtube.com/watch?v=HgKMb0oicQc"
                                >
                                  compártelo
                                </Link>{" "}
                                con el email{" "}
                                <span style={{ color: MAIN_COLOR }}>
                                  ibmasters.academy@gmail.com
                                </span>{" "}
                                y aségurate de que tengamos permisos de hacer
                                cambios y compartir.
                              </Text>
                            </HStack>
                            <HStack alignItems={"flex-start"} gap={1}>
                              <Text fontSize={"lg"} fontWeight={400}>
                                4.
                              </Text>{" "}
                              <Text fontSize={"lg"} fontWeight={400}>
                                Finalmente, avísanos por el WhatsApp de
                                coordinación académica para que puedas tener
                                acceso a la app.
                              </Text>
                            </HStack>
                          </Box>
                        </ModalBody>
                        {/* footer */}
                        <ModalFooter mt={10}>
                          <Text p={2} color={"#5555"}>
                            2/3
                          </Text>
                          <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={() => {
                              setStep(step - 1);
                              setProgress(progress - 50);
                            }}
                          >
                            Volver
                          </Button>
                          <Button
                            leftIcon={isFetching ? <Spinner /> : null}
                            bgColor={MAIN_COLOR}
                            color={"white"}
                            onClick={CreateNewAccountTutor}
                          >
                            Listo
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default UserInfoModal;
