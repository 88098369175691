import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  WrapItem,
} from "@chakra-ui/react";
import React from "react";
import { Images } from "../../utils";
import { feedbackToData } from "../../utils/functions";

const ViewTutorFeedbackModal = ({ isOpen, onClose, tutorFeedback }) => {
  return (
    <>
      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          onClick={onClose}
          backgroundColor={"rgba(65, 65, 65, 0.3)"}
        />
        <ModalContent p={5}>
          <ModalHeader fontSize={"2xl"} alignSelf={"center"}>
            Reporte de clase
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack
              p={5}
              gap={5}
              marginTop={5}
              borderRadius={12}
              alignItems={"flex-start"}
              overflowY={"scroll"}
              scrollBehavior={"smooth"}
              backgroundColor={"#4444441A"}
              maxH={"50vh"}
            >
              {tutorFeedback ? (
                feedbackToData(tutorFeedback).map(([q, a], index) => (
                  <Box
                    key={`item-${index}`}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Text fontSize={"lg"} fontWeight={500}>
                      {q}
                    </Text>
                    <Text fontSize={"md"} fontWeight={400}>
                      {a}
                    </Text>
                  </Box>
                ))
              ) : (
                <Text>El tutor aún no manda su reporte. Este atento!</Text>
              )}
              {/* {ReportmodelData.map((item, index) => {
                return (
                  <Box
                    key={`item-${index}`}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Text fontSize={"lg"} fontWeight={500}>
                      {item.question}
                    </Text>
                    <Text fontSize={"md"} fontWeight={400}>
                      {item.answer}
                    </Text>
                  </Box>
                );
              })} */}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewTutorFeedbackModal;
