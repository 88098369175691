export const Images = {
  UNIVERSIDADES_IMAGES: require("../assets/estados-unidos 2.png"),
  BACHILLERATO_IMAGES: require("../assets/ib1.png"),
  RESOURCE_CALULATOR: require("../assets/calculator.png"),
  RESOURCE_BOOK: require("../assets/book.png"),
  RESOURCE_LEAF: require("../assets/leaf.png"),
  RESOURCE_BRAIN: require("../assets/brain.png"),
  RESOURCE_100: require("../assets/100.png"),
  RESOURCE_CAP: require("../assets/cap.png"),
  RESOURCE_EARTH: require("../assets/earth.png"),
  RESOURCE_DOLLAR: require("../assets/dollar.png"),
  RESOURCE_MAIL: require("../assets/mail.png"),
  RESOURCE_SAKET: require("../assets/scket.png"),
  RESOURCE_XYZ: require("../assets/xyz.png"),
  RESOURCE_STAR: require("../assets/star.png"),
  RESOURCE_EDIT: require("../assets/edit.png"),
  XPLAIN_LOGO: require("../assets/IB_MASTERS_LOGO.jpeg"),
  AVATAR_URL: require("../assets/image 1.png"),
  MIPERFIL: require("../assets/university.png"),
  MIPERFIL_BALLONS: require("../assets/ballons.png"),
  MIPERFIL_PHONE: require("../assets/phone.png"),
  XBACKGROUND: require("../assets/BackgroundImage.png"),
  IB_IMAGE: require("../assets/Ib.jpg"),
  UTEC_IMAGE: require("../assets/utec.png"),
  IB42_IMAGE: require("../assets/Rectangle 49.png"),
  ROOTX_IMAGE: require("../assets/Vector (16).png"),
  IDIOMAS_IMAGE: require("../assets/Vector (17).png"),
  LOGIN_OVERLAY: require("../assets/loginOverlay.png"),
};
