import React, { useState, useReducer, useEffect } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "./DropDown.css";
import PaymentModal from "../../components/PaymentModal";
import SelectedComponent from "../../components/SelectComponent/SelectComponent.jsx";
import {
  Modal,
  InputGroup,
  Center,
  InputRightElement,
  Image,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  FormControl,
  Input,
  FormLabel,
  ModalBody,
  Button,
  Stack,
  Checkbox,
  VStack,
  Divider,
  HStack,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
// import {
//   ChatIcon,
//   ,
//   HamburgerIcon,
//   CalendarIcon,
//   ExternalLinkIcon,
//   CheckIcon,
//   InfoOutlineIcon,
//   ChevronDownIcon,
// } from "@chakra-ui/icons";

import {
  CalendarIcon,
  CheckCircleIcon,
  CloseIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import {
  getSaleAmount,
  getValidNumHours,
  getFamily,
  processPayment,
  registerSale,
} from "../../utils/ApiConfig";
import { MAIN_COLOR } from "../../utils/constants";
import PaymentPage from "./PaymentPage";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/es";

const Comprar = () => {
  const {
    isOpen: isOpenMP,
    onOpen: onOpenMP,
    onClose: onCloseMP,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmation,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();

  const {
    isOpen: isOpenError,
    onOpen: onOpenError,
    onClose: onCloseError,
  } = useDisclosure();
  const { userInfo } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);

  // This is needeed to calculate the price
  const [hoursBought, setHoursBought] = useState();
  const [referralId, setReferralId] = useState("");
  const [hourType, setHourType] = useState();
  const [currency, setCurrency] = useState();

  const [hasReferral, setHasReferral] = useState(false);

  const [amountPEN, setAmountPEN] = useState(0);
  const [amountOtherCurrency, setAmountOtherCurrency] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValidReferral, setIsValidReferral] = useState(false);
  const [discountFromReferral, setDiscountFromReferral] = useState(0);
  const [discountFromPreviousReferrals, setDiscountFromPreviousReferrals] =
    useState(0);

  const [referralsToUse, setReferralsToUse] = useState(0);

  const [isGroupHour, setIsGroupHour] = useState(false); //For now lets assume it's always individidual

  const [amount, setAmount] = useState();

  const [validNumHours, setValidNumHours] = useState({});

  const avaliableHourTypes = ["IB", "tutoring"];
  const availableHours = [1, 4, 8, 12, 16, 24, 36, 48];

  const [family, setFamily] = useState({});

  // const [valueHours, setValueHours] = useState({ label: "0", value: "0" });
  // const hourOptions = [
  //   { label: "1 horas", value: "1" },
  //   { label: "2 horas", value: "2" },
  //   { label: "3 horas", value: "3" },
  //   { label: "5 horas", value: "5" },
  //   { label: "8 horas", value: "8" },
  //   { label: "24 horas", value: "24" },
  // ];
  // const hourTypeOption = [
  //   { label: "IB", currency: "IB" },
  //   { label: "SAT", currency: "SAT" },
  //   { label: "SAT", currency: "SAT" },
  //   { label: "SAT", currency: "SAT" },
  // ];
  let currencyNames = {
    PEN: "soles",
    USD: "dólares",
  };

  // if (currency !== "PEN" && currency !== "USD") {
  //   setErrorMessage(`Not valid currency ${currency}`);
  // }

  const calculateAmount = async (e) => {
    if (!hoursBought || !hourType || !currency || !userInfo) {
      return;
    }
    let formData = {
      hoursBought,
      referralId,
      hourType,
      currency,
      familyId: userInfo.family,
    };
    let res = await getSaleAmount(formData);
    if (res.success) {
      setAmount(res.finalAmountOtherCurrency);
      setAmountPEN(res.finalAmountPEN);

      setIsValidReferral(res.isValidReferral);
      setDiscountFromReferral(res.discountFromReferral);
      setDiscountFromPreviousReferrals(res.discountFromPreviousReferrals);
      setReferralsToUse(res.referralsToUse)
    } else {
      setErrorMessage(res.message);
    }
  };

  async function get_amount() {
    return;
    if (!hoursBought || !hourType || !currency) {
      return;
    }
    if (amountPEN) {
      return;
    }
    console.log("Getting amount...");
    let formData = {
      hoursBought,
      referralId,
      hourType,
      currency,
      familyId: userInfo.family,
    };
    let res = await getSaleAmount(formData);
    setAmountPEN(res.finalAmountPEN);
    setAmountOtherCurrency(res.finalAmountOtherCurrency);
    if (res.isValidReferral) {
      setIsValidReferral(true);
      setDiscountFromReferral(res.discountFromReferral);
    }
    setDiscountFromPreviousReferrals(res.discountFromPreviousReferrals);
  }

  useEffect(() => {
    calculateAmount();
  }, [hoursBought, referralId, hourType, currency]);
  useEffect(() => {
    if (!userInfo) {
      return;
    }
    async function getInfo() {
      let r = await getValidNumHours();
      console.log(userInfo);
      let fam = await getFamily(userInfo.family);
      console.log(fam);
      setValidNumHours(r);
      setFamily(fam);
    }
    getInfo();
  }, [userInfo]);
  const hourLabels = {
    IB: "Tutorías Especializadas (IB)",
    tutoring: "Tutorías (Pre-IB)",
  };
  const getSpanisHourName = (r) => {
    return hourLabels[r];
  };
  if (
    !userInfo ||
    (userInfo.role !== "Student" && userInfo.role !== "Parent")
  ) {
    return `Usuario es ${userInfo?.role}, solo estudiantes o padres pueden comprar horas.`;
  }

  /**
   * Callback for succesful payment
   */
  const handlePay = async (param) => {
    let { payment_output, cardData } = param;
    let { id, status } = payment_output;
    if (status !== "approved") {
      alert("Hubo un error con el pago");
      console.log(payment_output);
      return;
    }
    await registerSale({
      hoursBought,
      referralId,
      hourType,
      currency,
      payment_id: id,
      familyId: userInfo.family,
      user_role: userInfo.role,
      source: "mercadopago",
      amount: amount,
      amountPEN: amountPEN,
      isValidReferral: isValidReferral,
      family: family,
      payment_output: payment_output,
      extraData: cardData,
      referralsToUse: referralsToUse,
    });
    onOpenConfirmation();

    //     let x = await axios.post(`${process.env.REACT_APP_BACKENDURL}/sale/register_class`, {
    //       hoursBought,
    //       referralId,
    //       hourType,
    //       currency,
    //       payment_id,
    //       familyId: user.family,
    //       user_role: user.role,
    //       source: "mercadopago"
    //   });
    // console.log(x);

    //     let output = await processPayment({
    //       amount: amount,
    //       family: userInfo.family,
    //       currency: currency,
    //       hoursBought: hoursBought,
    //       isGroupHour: isGroupHour,
    //       referralId: referralId,
    //       user_role: userInfo.role,
    //       source: "deprecated-shouldnt-be-used",
    //     });

    //     if (output.success) {
    //       // Good payment, show the confirmation page
    //       onOpenConfirmation();
    //     } else {
    //       alert("There was an error with the payment");
    //       console.error("Hubo un error con el pago...!");
    //       console.log(output);
    //     }
  };
  return (
    <>
      <Stack alignItems={"center"} p={10}>
        <Text fontSize={{ base: "150%", md: "200%" }} fontWeight={500}>
          Comprar horas
        </Text>
        {validNumHours && (
          <Stack
            p={10}
            rounded={"lg"}
            bgColor={"white"}
            boxShadow={"lg"}
            w={{ base: "80%", md: "50%" }}
          >
            <Stack justify={"space-between"} direction="row">
              <Stack w={"100%"}>
                <Text w={"250px"} fontWeight={500}>
                  Qué tipo de horas?
                </Text>
                <SelectedComponent
                  Value="Hours"
                  dataOptions={avaliableHourTypes.map((r) => {
                    return { label: getSpanisHourName(r), value: r };
                  })}
                  setSelectedOptions={(opt) => {
                    setHourType(opt.value);
                  }}
                  style={{ zIndex: 1000 }}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
        {hourType && (
          <Stack
            p={10}
            rounded={"lg"}
            bgColor={"white"}
            boxShadow={"lg"}
            w={{ base: "80%", md: "50%" }}
            className="select-container"
          >
            <Stack justify={"space-between"} direction="row">
              <Stack w={"100%"}>
                <Text w={"250px"} fontWeight={500}>
                  Con qué moneda pagará?
                </Text>
                <Flex flex={1} justifyContent={"space-around"}>
                  <Button
                    opacity={currency === "USD" ? 1 : 0.5}
                    onClick={() => setCurrency("USD")}
                  >
                    🇺🇸 USD
                  </Button>
                  <Button
                    opacity={currency === "PEN" ? 1 : 0.5}
                    onClick={() => setCurrency("PEN")}
                  >
                    🇵🇪 PEN
                  </Button>
                </Flex>
              </Stack>
            </Stack>
          </Stack>
        )}
        {currency && hourType && (
          <Stack
            p={10}
            rounded={"lg"}
            bgColor={"white"}
            boxShadow={"lg"}
            w={{ base: "80%", md: "50%" }}
            // className="select-container"
          >
            <Stack justify={"space-between"} direction="row">
              <Stack w={"100%"}>
                <Text w={"250px"} fontWeight={500}>
                  Cuántas horas?
                </Text>
                <SelectedComponent
                  Value="Hours"
                  dataOptions={availableHours.map((r) => {
                    return { label: `${r} horas`, value: r };
                  })}
                  // dataOptions={hourTypeOptions}
                  setSelectedOptions={(opt) => {
                    setHoursBought(opt.value);
                  }}
                  style={{ zIndex: 500 }}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
        {hoursBought &&
          hoursBought >= 4 &&
          !family.isReferred &&
          family.hoursBought === 0 && (
            <Stack
              p={10}
              rounded={"lg"}
              bgColor={"white"}
              boxShadow={"lg"}
              w={{ base: "80%", md: "50%" }}
              className="select-container"
            >
              <Checkbox
                isChecked={hasReferral}
                onChange={(e) => setHasReferral(e.target.checked)}
              >
                Tengo código de referidos
              </Checkbox>
              {hasReferral && (
                <>
                  <Input
                    type="text"
                    placeholder="Inserte el código de referidos"
                    onChange={(e) => setReferralId(e.target.value)}
                    value={referralId}
                  />
                  {referralId && (
                    <Text
                      fontSize="md"
                      color={isValidReferral ? "green" : "red"}
                    >
                      {isValidReferral ? "Código válido" : "Código inválido"}
                      {/* {isValidReferral ? <CheckIcon /> : <CloseIcon />} */}
                    </Text>
                  )}
                </>
              )}
            </Stack>
          )}

        {hoursBought && (
          <>
            <VStack>
              <HStack fontSize={"100%"}>
                <Text>El monto total por </Text>
                <Text color={MAIN_COLOR}>
                  {hoursBought} hora{hoursBought !== 1 && "s"}
                </Text>
                <Text>de</Text>
                <Text color={MAIN_COLOR}>{getSpanisHourName(hourType)}</Text>
                <Text> es de</Text>
                {/* <Text color="#07A58E"> */}
                <Text color={MAIN_COLOR}>
                  {" "}
                  {amount} {currency}{" "}
                  {currency !== "PEN" && `(${amountPEN} Soles)`}
                </Text>
              </HStack>
              <Button onClick={onOpenMP} color="white" bgColor={MAIN_COLOR}>
                Pagar
              </Button>
            </VStack>
          </>
        )}

        <PaymentModal
          amountPEN={amountPEN} //actual amount to be paid
          payText={`Pagar ${amount} ${currency}`}
          payTitle={`Comprando ${hoursBought} hora${
            hoursBought > 1 && "s"
          } de ${getSpanisHourName(hourType)}`}
          isOpen={isOpenMP}
          onOpen={onOpenMP}
          onClose={onCloseMP}
          onPay={handlePay}
          onError={onOpenError}
        />

        {errorMessage && <Text color="red">{errorMessage}</Text>}
      </Stack>

      {/** Payment finished modal */}
      <Modal isOpen={isOpenConfirmation} onClose={onCloseConfirmation}>
        <ModalOverlay />
        <ModalContent p={5}>
          {/* <ModalHeader>
            <Center><Image src="#" alt="..." /></Center>
          </ModalHeader>
          <Divider /> */}
          {/* <ModalCloseButton /> */}
          <ModalBody p={10} justify={"center"} align={"center"}>
            <CheckCircleIcon boxSize={"6vh"} color={MAIN_COLOR} />
            <Stack gap={4}>
              <Text fontSize={"150%"} fontWeight={500}>
                Compra realizada
              </Text>
              {/* <Text>Detalle del movimiento</Text> */}
              <Text fontSize={"250%"} fontWeight={500} color={MAIN_COLOR}>
                {amount} {currency}
              </Text>
              {/* <Text fontSize={"80%"}>{new Date().toLocaleString()}</Text> */}
              <Stack gap={4}>
                <HStack justify={"space-between"}>
                  <Text fontSize={"90%"}>Cantidad de horas</Text>
                  <Text fontSize={"90%"} fontWeight={500}>
                    {hoursBought}
                  </Text>
                </HStack>
                <Divider />
                <HStack justify={"space-between"}>
                  <Text fontSize={"90%"}>Tipo de horas</Text>
                  <Text fontSize={"90%"} fontWeight={500}>
                    {getSpanisHourName(hourType)}
                  </Text>
                </HStack>
                <Divider />
              </Stack>
              <Text fontSize={"80%"} fontWeight={500}>
                {!referralId
                  ? `No se brindó un codigo de referidos.`
                  : !isValidReferral
                  ? `No se brindó un código válido de referidos`
                  : ` Se aplicó un descuento de ${
                      discountFromReferral + discountFromPreviousReferrals
                    } ${currencyNames[currency] || currency}`}
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex flex={1} justifyContent={"center"}>
              {/* <Text textAlign={"center"} fontSize={"80%"}>
                Este comprobante fue enviado al e-mail proporcionado durante la
                transacción.
              </Text> */}
              <Button
                color={"white"}
                bgColor={MAIN_COLOR}
                mr={3}
                onClick={() => {
                  onCloseConfirmation();
                  window.location.reload(false);
                }}
              >
                Finalizar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenError} onClose={onCloseError}>
        <ModalOverlay />
        <ModalContent p={5}>
          {/* <ModalHeader>
            <Center><Image src="#" alt="..." /></Center>
          </ModalHeader>
          <Divider /> */}
          {/* <ModalCloseButton /> */}
          <ModalBody p={10} justify={"center"} align={"center"}>
            <CloseIcon boxSize={"6vh"} color={"red"} />
            <Stack gap={4}>
              <Text fontSize={"150%"} fontWeight={500}>
                Hubo un error con la compra. Por favor intente de nuevo.
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex flex={1} justifyContent={"center"}>
              {/* <Text textAlign={"center"} fontSize={"80%"}>
                Este comprobante fue enviado al e-mail proporcionado durante la
                transacción.
              </Text> */}
              <Button
                color={"white"}
                bgColor={"red"}
                mr={3}
                onClick={onCloseError}
              >
                Cerrar
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Comprar;
