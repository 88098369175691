import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  WrapItem,
} from "@chakra-ui/react";
import React from "react";
import { Images } from "../../utils";
import { MAIN_COLOR } from "../../utils/constants";

const CalendarModal = ({
  isOpen,
  onClose,
  classCancel,
  classCancelHandler,
  modalData,
}) => {
  return (
    <>
      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          onClick={onClose}
          backgroundColor={"rgba(65, 65, 65, 0.3)"}
        />
        <ModalContent p={5}>
          <ModalHeader alignSelf={"center"}>{modalData.header}</ModalHeader>

          <ModalBody>
            <Box
              display={"flex"}
              gap={4}
              flexDir={{ base: "column", md: "row" }}
            >
              <Box width={{ base: "100%", md: "initial" }} display={"flex"}>
                <WrapItem position={"relative"}>
                  <Avatar
                    zIndex={1}
                    border={" 3.64583px solid rgba(68, 68, 68, 0.25)"}
                    size={"xl"}
                    name="Segun Adebayo"
                    src="https://bit.ly/dan-abramov"
                  ></Avatar>

                  <Avatar
                    bottom={0}
                    right={0}
                    zIndex={10}
                    position={"absolute"}
                    size={"sm"}
                    borderRadius={"50%"}
                    src={Images.IB_IMAGE}
                  ></Avatar>
                </WrapItem>
              </Box>
              <Box>
                <Text
                  textDecoration={classCancel ? "line-through" : "none"}
                  fontSize={"lg"}
                  fontWeight={600}
                >
                  {modalData.className}
                </Text>
                <Text
                  textDecoration={classCancel ? "line-through" : "none"}
                  fontSize={"md"}
                  fontWeight={500}
                >
                  {modalData.tutor}
                </Text>
                <Text
                  fontSize={"xl"}
                  color={classCancel ? "#FF2B2B" : MAIN_COLOR}
                  fontWeight={600}
                >
                  {modalData.timing}
                </Text>
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter alignSelf={{ base: "flex-start", md: "center" }}>
            {!classCancel && (
              <Button
                backgroundColor={"#FF2B2B"}
                mr={12}
                color={"#FCFCFC"}
                onClick={classCancelHandler}
              >
                Cancelar
              </Button>
            )}
            <Button
              onClick={onClose}
              backgroundColor={"#E8E8E8"}
              color={"#8D8D8D"}
            >
              Volver
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CalendarModal;
