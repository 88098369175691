import {
  initMercadoPago,
  CardPayment,
  createCardToken,
} from "@mercadopago/sdk-react";
import { Text } from "@chakra-ui/react";

import { processPayment } from "../../utils/ApiConfig";

/**
 * Only purpose to show the modal of the
 */
const PaymentPage = ({ amount, onPay, onError, payText, payTitle }) => {
  initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY, {
    locale: "es-PE",
  });

  return (
    <>
      <div style={{ border: "", borderRadius: 10 }}>
        <CardPayment
          initialization={{ amount: amount, installments: 1 }} //todo: change
          customization={{
            paymentMethods: {
              minInstallments: 1,
              maxInstallments: 1,
            },
            visual: {
              style: {
                // theme: "dark",
                // textPrimaryColor: "green",
              },
              texts: {
                formTitle: payTitle,
                formSubmit: payText,
              },
            },
          }}
          onError={(err) => {
            console.log("[MERCADO PAGO] Hubo un error:");
            console.log(err);
            onError();
          }}
          onSubmit={async (cardData) => {
            // fetch(`${process.env.REACT_APP_BACKEND_URL}/mp/process_payment`, {
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            //   body: JSON.stringify(cardData),
            // })
            //   .then((response) => response.json())
            //   .then((response) => {
            //     console.log(response);
            //   });

            // return;

            console.log("carData fond");
            // cardda;;
            // console.log(cardData);
            // let cardToken = await createCardToken({
            //   // cardId:
            //   cardholderName: "Raul Alcantara",
            //   identificationType: cardData.payer.identification.type,
            //   identificationNumber:
            //     cardData.payer.identification.identificationNumber,
            // });

            let res = await processPayment({
              ...cardData,
              description: payTitle,
              token: cardData.token,
            });
            if (!res.success) {
              console.log("There was an error processing the payment");
              console.log(res);
              onError();
              return;
            }
            let payment_output = res.data;
            console.log("------------Payment!--------------------");
            console.log(res);
            if (res.success) {
              console.log(cardData);
              console.log(payment_output);

              onPay({ payment_output, cardData });
            } else {
            }
          }}
        />
      </div>
      <Text textAlign={"start"} fontSize={"80%"} fontStyle={"italic"}>
        Esta página viene directamente de MercadoPago. IBMasters no tiene acceso
        a ningún tipo de información sobre su pago.
      </Text>
    </>
  );
};

export default PaymentPage;
