import { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import { EVENTS } from "../../data/events";
import {
  ChakraProvider,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button as ChakraButton,
  ModalBody,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  DatePicker,
  FormControl,
  FormLabel,
  Select,
  TimePicker,
  Input,
  Box,
  ModalHeader,
  ModalFooter,
  VStack,
  Textarea,
  useToast,
} from "@chakra-ui/react";

// import { ModalTesting } from "../../pages";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getTutorDetail, getSubjectById } from "../../utils/ApiConfig.js";
import {
  PENDING_CLASS_COLOR,
  CONFIRMED_CLASS_COLOR,
  PAST_CLASS_COLOR,
  MAIN_COLOR,
} from "../../utils/constants";
import PendingClassModal from "./PendingClassModal";
import PastClassModal from "./PastClassModal";
import FutureClassModal from "./FutureClassModal";
import "./CalendarClasses.css";
import esLocale from "date-fns/locale/es";

const CustomEditor = ({
  scheduler,
  tutorDetail,
  subjectDetail,
  // setClassTimeForm,
  // ClassTimeForm,
  createNewClassHandler,
}) => {
  const event = scheduler.edited;
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const [startDate, setStartDate] = useState(scheduler.state.start.value);
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [duration, setDuration] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const DescriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const formattedStartDate = moment(startDate).format(
    "ddd, MMM D, YYYY h:mm A"
  );

  const startTime = moment(startDate).format("HH:mm:ss");

  const startDateTimeString = moment(startDate).format("YYYY-MM-DD");
  const endDateTime = moment(startDateTimeString + " " + startTime).add(
    duration,
    "hours"
  );

  const formattedEndDate = moment(endDateTime).format(
    "ddd MMM DD YYYY HH:mm:ss [GMT+0530 (India Standard Time)]"
  );
  const endDateObj = moment(
    formattedEndDate,
    "ddd MMM DD YYYY HH:mm:ss [GMT+0530 (India Standard Time)]"
  ).toDate();

  const start = moment(startDate).format("dddd, MMMM D, h:mm a");
  const end = moment(endDateObj).format("h:mm a");

  const formattedDatetoShow = `${start} - ${end}`;
  const handleAccept = async () => {
    try {
      scheduler.loading(true);
      const NewClassCreate = await new Promise((res) => {
        setTimeout(() => {
          res({
            event_id: event?.event_id || Math.random(),
            title: subjectDetail?.name,
            start: startDate,
            end: endDate,
            description,
          });
        }, 3000);
      });

      const newClassObject = {
        tutor: tutorDetail,
        subject: subjectDetail,
        students: [userInfo],
        parents: [],
        prevInfo: description,
        event: {
          start: startDate,
          end: endDate,
        },
      };
      createNewClassHandler(newClassObject);
      scheduler.onConfirm(NewClassCreate, event ? "edit" : "create");
      scheduler.close();
    } finally {
      scheduler.loading(false);
    }
  };

  useEffect(() => {
    setEndDate(endDateObj);
  }, [duration]);

  const handleSubmit = async () => {
    try {
      scheduler.loading(true);

      onOpen();
    } finally {
      scheduler.loading(false);
    }
  };

  const ReserveModal = () => {
    return (
      <>
        <ChakraProvider>
          <Modal size={"xl"} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody w={"100%"}>
                <Stack p={10} align={"center"} gap={5}>
                  <Text fontSize={"120%"} fontWeight={500}>
                    ¿Deseas reservar esta clase?
                  </Text>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    align={"center"}
                    gap={5}
                  >
                    <Avatar size={"xl"}>
                      <AvatarBadge boxSize="1.25em" bg="green.500" />
                    </Avatar>
                    <Stack>
                      <Text fontSize={"85%"}>{subjectDetail?.name}</Text>
                      <Text
                        fontSize={"70%"}
                      >{`con ${tutorDetail?.name} ${tutorDetail?.surname}`}</Text>
                      <Text fontSize={"80%"} color={MAIN_COLOR}>
                        {formattedDatetoShow}
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack direction gap={5}>
                    <ChakraButton
                      bgColor={MAIN_COLOR}
                      color={"white"}
                      onClick={() => {
                        onClose();
                        handleAccept();
                      }}
                    >
                      Aceptar
                    </ChakraButton>{" "}
                    <ChakraButton
                      onClick={() => {
                        onClose();
                        scheduler.close();
                      }}
                    >
                      Volver
                    </ChakraButton>
                  </Stack>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </ChakraProvider>
      </>
    );
  };

  return (
    <ChakraProvider>
      <VStack
        marginTop={{ base: "auto", md: "initial" }}
        marginBottom={{ base: "auto", md: "initial" }}
        minH={{ base: "60vh", md: "initial" }}
        alignSelf={"center"}
        border={{ base: "1px solid grey", md: "initial" }}
        // backgroundColor={{ base: "", md: "initial" }}
        overflow={"hidden"}
        width={{ base: "80vw", md: "50vw" }}
        alignItems={"flex-start"}
        px={{ base: 5, md: 10 }}
        rounded={"lg"}
        gap={3}
        py={5}
      >
        <Text fontSize={"xl"} fontWeight={500}>
          Schedule Class
        </Text>
        <Box
          width={"100%"}
          gap={5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          flexDir={{ base: "column", md: "row" }}
        >
          <FormControl>
            <FormLabel>Start Date</FormLabel>
            <Text
              whiteSpace={"nowrap"}
              fontSize={"lg"}
              fontWeight={400}
            >{`${formattedStartDate}`}</Text>
          </FormControl>
          <FormControl>
            <FormLabel>Duration</FormLabel>
            <Select value={duration} onChange={handleDurationChange}>
              <option value={1}>1 hour</option>
              <option value={1.5}>1.5 hours</option>
              <option value={2}>2 hours</option>
              <option value={2.5}>2.5 hours</option>
              <option value={3}>3 hours</option>
            </Select>
          </FormControl>
        </Box>

        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea
            backgroundColor={"#F3F3F3"}
            width={"100%"}
            minH="100px"
            value={description}
            onChange={DescriptionHandler}
            placeholder="Write something ..."
            size={{ base: "sm", md: "md" }}
          />
        </FormControl>
        <Box gap={10} display={"flex"} alignSelf={"flex-end"}>
          <ChakraButton onClick={scheduler.close}>Cancel</ChakraButton>
          <ChakraButton
            onClick={handleSubmit}
            color={"white"}
            bgColor={MAIN_COLOR}
          >
            Confirm
          </ChakraButton>
        </Box>

        <ReserveModal />
      </VStack>
    </ChakraProvider>
  );
};

function CalendarClasses({
  width,
  // setClassTimeForm,
  // ClassTimeForm,
  createNewClassHandler,
  tutorDetail,
  subjectDetail,

  pendingClasses,
  futureClasses,
  pastClasses,
}) {
  const { userInfo } = useSelector((state) => state.user);

  const classCalendarRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleScheduleClick(schedule) {
    const startTime = new Date(schedule.start);
    console.log("Start Time:", startTime);
  }
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    let allClasses = [...pendingClasses, ...futureClasses, ...pastClasses];
    let calendarFormat = allClasses.map((currClass) => ({
      title: currClass.info.subjectDescription,
      start: new Date(currClass.class.start),
      end: new Date(currClass.class.end),
      type: currClass.info.type,
      ...currClass.calendar,
      extra: currClass, //Save info for later use
    }));
    setCalendarEvents(calendarFormat);
    setLoading(false);
  }, []);
  // useEffect(() => {
  //   let todayCellId = `hour-${new Date().getHours()}`;
  //   let el = document.getElementById(todayCellId);
  //   if (el) {
  //     el.scrollIntoView({ smooth: true });
  //   } else {
  //     console.log("didnt found any!");
  //   }
  // });
  if (!userInfo) {
    return "Cargando usuario...";
  }
  return loading ? (
    "Cargando..."
  ) : (
    <div className="classes-scheduler-container">
      <Scheduler
        height={300}
        ref={classCalendarRef}
        translations={{
          navigation: {
            month: "Mes",
            week: "Semana",
            day: "Día",
            today: "Hoy",
          },
          form: {
            addTitle: "Agregar evento",
            editTitle: "Editar evento",
            confirm: "Confirmar",
            delete: "Borrar",
            cancel: "Cancelar",
          },
          event: {
            title: "Título",
            start: "Empieza",
            end: "Termina",
            allDay: "Todo el día",
          },
          moreEvents: "Más...",
          loading: "Cargando...",
        }}
        onScheduleClick={handleScheduleClick}
        events={calendarEvents}
        // fetchRemoteEvents={fetchRemoteEvents}
        // draggable
        // view={width ? "day" : "week"}
        month={null}
        day={null}
        locale={esLocale}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: new Date().getDay(),
          startHour: 0,
          endHour: 23,
          step: 30,
          cellRenderer: ({ height, start, onClick, end, ...props }) => {
            // Fake some condition up
            const hour = start.getHours();
            const disabled = true; //hour === 14;
            const restProps = disabled ? {} : props;
            return (
              <Button
                id={`hour-${start.getHours()}`}
                style={{
                  height: "100%",
                  // backgroundColor: "rgba(255, 255, 255, 0.75)",
                  // background: disabled ? "#eee" : "transparent",
                  cursor: disabled ? "not-allowed" : "pointer",
                }}
                onClick={() => {
                  if (disabled) {
                    //     handleClick();
                    //     return <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    //     This slot is already booked!
                    //   </Alert>;
                    setOpen(true);
                    return setOpen(true);
                  }
                  onClick();
                }}
                disableRipple={disabled}
                // disabled={disabled}
                // {...restProps}
              ></Button>
            );
          },
        }}
        //border-width:0;border-style:solid;box-sizing:border-box

        // loading={loading}
        customEditor={(scheduler) =>
          userInfo.role === "Student" && (
            <CustomEditor //Dont have any popups for Parents
              subjectDetail={subjectDetail}
              tutorDetail={tutorDetail}
              createNewClassHandler={createNewClassHandler}
              scheduler={scheduler}
            />
          )
        }
        viewerExtraComponent={(fields, event) => {
          console.log(fields);
          console.log(event);
          //Dont have any popups for Parents
          if (userInfo.role !== "Student") {
            return;
          }
          return (
            <>
              <ChakraProvider>
                {event.type === "pending" ? (
                  <PendingClassModal event={event} />
                ) : event.type === "past" ? (
                  <PastClassModal
                    event={event}
                    subjectInfo={event.extra.info.subjectInfo}
                    tutorInfo={event.extra.info.tutorInfo}
                    classInfo={event.extra.class}
                    subjectDescription={event.extra.info.subjectDescription}
                    timeDescription={event.extra.info.timeDescription}
                  />
                ) : event.type === "future" ? (
                  <FutureClassModal event={event} />
                ) : (
                  <></>
                )}
                {/* <ModalTesting /> */}
              </ChakraProvider>
            </>
          );
        }}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Solo se puede agregar eventos cuando se está reservando una clase!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CalendarClasses;
