import {
  Stack,
  extendTheme,
  Image,
  Text,
  VStack,
  Flex,
  Button,
  Input,
  Checkbox,
  InputGroup,
  InputRightElement,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { dividerTheme } from "./Divider.jsx";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import RegisterModal from "./RegisterModal.jsx";
import google from "../assets/Group 6.png";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import divide from "./../assets/Group 108.png";
import underline1 from "./../assets/Group 114.png";
import { MAIN_COLOR } from "../utils/constants";

export const theme = extendTheme({
  components: { Divider: dividerTheme },
});

const Register = () => {
  const navigate = useNavigate();

  const onIniciarSesinTextClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  // const onGroupButton1Click = useCallback(() => {
  //   Please sync "Post-Regístrate " to the project
  // }, []);

  return (
    <Flex
      display={{ md: "block", lg: "flex" }}
      justify={"space-evenly"}
      alignItems={"center"}
    >
      <Stack
        display={{ base: "block", md: "none", lg: "block" }}
        align={"center"}
        textAlign={"center"}
      >
        <Text
          color={"black.400"}
          fontSize={{ base: 50, md: 70 }}
          fontWeight={650}
        >
          Educación
        </Text>
        <Text
          color={MAIN_COLOR}
          fontSize={{ base: 50, md: 70 }}
          fontWeight={650}
        >
          a <u>tu</u> medida
        </Text>
      </Stack>
      <Stack>
        <VStack
          alignSelf={"center"}
          display={{ base: "none", md: "flex" }}
          width={{ base: "75%", md: "50%", lg: "75%" }}
          height={"50%"}
          bgColor={"#FFFF"}
          rounded={"lg"}
          boxShadow={"xl"}
          p={"10"}
          m={"20"}
        >
          <HStack gap={4} justifyContent={"space-evenly"}>
            <Stack>
              <Text
                cursor={"pointer"}
                fontSize={"125%"}
                onClick={onIniciarSesinTextClick}
              >
                Iniciar sesión
              </Text>
            </Stack>
            <Stack>
              <Text fontSize={"125%"}>¡Regístrate aquí!</Text>
            </Stack>
          </HStack>
          <Image maxW={"320px"} src={underline1} />
          <Text fontSize={"23"} color={"black.400"} fontWeight={500}>
            ¡Bienvenido a IB Masters!
          </Text>
          <Button
            mb={10}
            bgColor={"#2A99FF"}
            color={"whiteAlpha.900"}
            fontWeight={500}
            fontSize={23}
            p={7}
          >
            <Image src={google} />
            &nbsp; | Continúa con Google
          </Button>
          <Stack mt={10}></Stack>
          <Image maxW={"320px"} src={divide} />

          <Flex textAlign={"left"} display={"block"} p={5}>
            <Text fontWeight={500}>E-mail</Text>
            <Input
              placeholder="Your email"
              width={"270px"}
              border={"1px solid gray"}
            ></Input>
            <Text fontWeight={500} mt={3}>
              Contraseña
            </Text>
            <PasswordInput />
            <Stack maxW={"270px"}>
              <Text textAlign={"start"} color={"#6C6C6C"} fontSize={8}>
                Crea una contraseña segura usando al menos 8 caracteres,
                mayúsculas y símbolos.
              </Text>
            </Stack>
            <Text fontWeight={500} mt={3}>
              Confirmar contraseña
            </Text>
            <PasswordInput />
            <Stack maxW={"270px"}>
              <Checkbox defaultChecked fontWeight={500} mt={5}>
                <Text fontSize={13}>Acepto los términos y condiciones</Text>
              </Checkbox>
              <Checkbox fontWeight={500} mb={5}>
                <Text fontSize={13}>
                  Quiero recibir promociones y novedades sobre IB Masters
                </Text>
              </Checkbox>
            </Stack>
          </Flex>

          <Button
            bgColor={MAIN_COLOR}
            p={3}
            fontWeight={500}
            color={"whiteAlpha.900"}
            boxShadow={5}
          >
            Registarme
          </Button>
        </VStack>

        <VStack
          display={{ base: "flex", md: "none" }}
          alignSelf={"center"}
          w={"80vw"}
          bgColor={"#FCFCFC"}
          rounded={"lg"}
          boxShadow={"lg"}
          p={"10"}
          m={"20"}
        >
          <Text fontSize={"15"} color={"black.400"} fontWeight={500}>
            ¡Bienvenido a IB Masters!
          </Text>
          {/* <Button mb={10} bgColor={'#2A99FF'} color={'whiteAlpha.900'} fontWeight={500} fontSize={15} p={3}>
                            <Image src={google} w={'20px'} />
                            &nbsp; | Continúa con Google</Button> */}

          {/* <div style={{ borderTop: "1px solid gray", paddingLeft: '10em', marginLeft: 20, marginRight: 20 }}></div> */}
          <HStack gap={4} justifyContent={"space-evenly"}>
            <Stack>
              <Text
                cursor={"pointer"}
                fontSize={"100%"}
                onClick={onIniciarSesinTextClick}
              >
                Iniciar sesión
              </Text>
            </Stack>
            <Stack>
              <Text fontSize={"100%"}>¡Regístrate aquí!</Text>
            </Stack>
          </HStack>
          <Image maxW={"280px"} src={underline1} />
          <RegisterModal />
        </VStack>

        {/* <VStack display={{base: 'none', md: 'flex', lg:'none'}} width={'90vw'} height={'60vh'} bgColor={'#FCFCFC'} rounded={'lg'} boxShadow={'lg'} p={'10'} m={'20'}>
                        <HStack gap={4} justifyContent={'space-evenly'}>
                          <Stack>  
                          <Text cursor={'pointer'} fontSize={'125%'} onClick={onIniciarSesinTextClick}>Iniciar sesión</Text>
                          </Stack>
                          <Stack>
                          <Text fontSize={'125%'}>¡Regístrate aquí!</Text>
                          </Stack>
                        </HStack>
                        <Image maxW={'320px'} src={underline1} />

                    <HStack alignSelf={'center'} justifyContent={'space-around'}>
                      <VStack>
                        <Text fontSize={'23'} color={'black.400'} fontWeight={500}>¡Bienvenido a IB Masters!</Text>
                        <Button mb={10} bgColor={'#2A99FF'} color={'whiteAlpha.900'} fontWeight={500} fontSize={23} p={7}>
                            <Image src={google} />
                            &nbsp; | Continúa con Google</Button>
                      </VStack>      

                        <Divider orientation="vertical" />
                        
                        <VStack>
                        <Flex textAlign={'left'} display={'block'} p={5}>
                            <Text fontWeight={500}>E-mail</Text>
                            <Input placeholder='Your email' width={'270px'} border={'1px solid gray'}></Input>
                            <Text fontWeight={500} mt={3}>Contraseña</Text>
                            <PasswordInput />
                            <Stack maxW={'270px'}>
                              <Text textAlign={'start'} color={'#6C6C6C'} fontSize={8}>Crea una contraseña segura usando al menos 8 caracteres, mayúsculas y símbolos.</Text>
                            </Stack>
                            <Text fontWeight={500} mt={3}>Confirmar contraseña</Text>
                            <PasswordInput />
                            <Stack maxW={'270px'}>
                              <Checkbox defaultChecked fontWeight={500} mt={5}><Text fontSize={13}>Acepto los términos y condiciones</Text></Checkbox>
                              <Checkbox fontWeight={500} mb={5}><Text fontSize={13}>Quiero recibir promociones y novedades sobre IB Masters</Text></Checkbox>
                            </Stack>
                        </Flex>
                        
                        <Button bgColor={MAIN_COLOR} p={3} fontWeight={500} color={'whiteAlpha.900'} boxShadow={5}>Registarme</Button>
                        </VStack>
                    </HStack>
                    </VStack> */}
      </Stack>
    </Flex>
  );
};

function PasswordInput() {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        w={"270px"}
        pr="4.5rem"
        type={show ? "text" : "password"}
        placeholder="Enter password"
        border={"1px solid gray"}
      />
      <InputRightElement width="4.5rem">
        <Button h="1.75rem" size="sm" onClick={handleClick}>
          {show ? <ViewOffIcon /> : <ViewIcon />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}

// function PasswordSmInput() {
//   const [show, setShow] = React.useState(false);
//   const handleClick = () => setShow(!show);

//   return (
//     <VStack display={"block"}>
//       <InputGroup size="md">
//         <Input
//           fontSize={9}
//           pr="4.5rem"
//           type={show ? "text" : "password"}
//           border={"1px solid gray"}
//         />
//         <Button h="1.75rem" size="sm" onClick={handleClick}>
//           {show ? <ViewOffIcon /> : <ViewIcon />}
//         </Button>
//       </InputGroup>
//     </VStack>
//   );
// }

export default Register;
