import React from "react";
// import { CalendarModal, ReportClassModal } from "../../components";
import CalendarModal from "../../components/CalendarModal/CalendarModal";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import { Button, Text, useDisclosure, Stack } from "@chakra-ui/react";
import CancelClassModal from "./CancelClassModal";
import { CANCEL_CLASS_COLOR } from "../../utils/constants";
import {
  getValidDriveLink,
  getDayInfo,
  getHourAndMinutes,
} from "../../utils/functions";
import { useSelector } from "react-redux";

const FutureClassModal = ({ event }) => {
  const { userInfo } = useSelector((state) => state.user);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const ModalData1 = {
    header: "¿Seguro que deseas cancelar esta clase?",
    className: "Matemáticas IB en 2 meses",
    tutor: "con Valentín Quezada",
    timing: "Viernes 11 mar, 11:00 - 12:00 hrs.",
  };
  const ModalData2 = {
    header: "Clase cancelada",
    className: "Matemáticas IB en 2 meses",
    tutor: "con Valentín Quezada",
    timing: "Viernes 11 mar, 11:00 - 12:00 hrs.",
  };

  const classCancelHandler = () => {
    onClose();
    setTimeout(() => {
      onOpen1();
    }, 600);
  };
  return (
    <>
      <Text fontSize={"md"} margin={3}>
        El tutor confirmó tu clase. El link de esta se te enviará media hora
        antes de que comienze.
      </Text>
      <Stack direction gap={3}>
        <Button
          backgroundColor={CANCEL_CLASS_COLOR}
          color={"white"}
          onClick={onOpen}
        >
          <Text>Cancelar Class</Text>
        </Button>
        {/* <Button backgroundColor={"#0ED37B"} color={"white"} onClick={onOpen2}>
          <Text>Report Class Modal</Text>
        </Button> */}
      </Stack>
      <CancelClassModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        classId={event.extra.class._id}
        tutorName={event.extra.info.tutorInfo.name}
        tutorImage={getValidDriveLink(
          event.extra.info.tutorInfo.imageDriveLink
        )}
        classTitle={event.title}
        classDescription={`${getDayInfo(event.start)},${getHourAndMinutes(
          event.start
        )} - ${getHourAndMinutes(event.end)}`}
        isTutor={false}
        classInfo={event.extra.class}
      />
      {/* <CalendarModal
        classCancelHandler={classCancelHandler}
        classCancel={false}
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        modalData={ModalData1}
      />
      <CalendarModal
        classCancel={true}
        modalData={ModalData2}
        onOpen={onOpen1}
        isOpen={isOpen1}
        onClose={onClose1}
      /> */}
      <ReportClassModal onOpen={onOpen2} isOpen={isOpen2} onClose={onClose2} />
    </>
  );
};

export default FutureClassModal;
