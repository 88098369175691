import React from "react";
import {
  Modal,
  Stack,
  Image,
  InputRightElement,
  ModalBody,
  Text,
  InputGroup,
  Checkbox,
  VStack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  FormControl,
  ModalCloseButton,
  Button,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import divide from "./../assets/Group 108.png";
import google from "../assets/Group 6.png";
import { useState } from "react";
import { validateEmail } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { RegisterTemp, RegisterTempUsingGoogle } from "../store/userSlice";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MAIN_COLOR } from "../utils/constants";
import { useGoogleLogin } from "@react-oauth/google";

export default function RegisterModal({ isOpen, onOpen, onClose }) {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast();
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const join_family_id = searchParams.get("join_family_id");

  const [joinFamilyId, setJoinFamilyId] = useState(join_family_id || "");

  const setJoinFamilyIdHandler = (e) => {
    console.log(e.target.value);
    setJoinFamilyId(e.target.value);
  };

  const RegisterHandler = () => {
    const Email = email.trim();
    const isEmail = validateEmail(Email);
    const Password = password.trim();
    const ConfirmPassword = confirmPassword.trim();

    const isPasswordMatch = Password === ConfirmPassword ? true : false;
    if (!Email || !Password || !ConfirmPassword) {
      toast({
        title: "Todos los campos son obligatorios",
        status: "error",
        position: "top-center",
        duration: 4000,
        isClosable: true,
      });
    }
    if (!isEmail && Email !== "") {
      toast({
        title: "Porfavor entre un email valido",
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    }
    if (!isPasswordMatch) {
      toast({
        title: "Las contraseñas no coinciden",
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    }

    if (!!Email && isEmail && !!Password && !!isPasswordMatch) {
      const registerObj = {
        email: Email,
        password: Password,
        joinFamilyId,
      };
      console.log(registerObj);
      dispatch(RegisterTemp(registerObj));
      setEmail("");
      onClose();
      setConfirmPassword("");
      setPassword("");
    }
  };
  const registerGoogleButton = useGoogleLogin({
    onSuccess: async (response) => {
      console.log(`Response from google`);
      console.log(response);
      dispatch(RegisterTempUsingGoogle(response));
    },
    flow: "auth-code",
    scope: [],
  });
  return (
    <>
      <Button onClick={onOpen}>Registarme</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>¡Regístrate aquí!</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack justifySelf={"center"}>
              <Button
                bgColor={"#2A99FF"}
                color={"whiteAlpha.900"}
                fontWeight={500}
                fontSize={23}
                p={7}
                onClick={registerGoogleButton}
              >
                <Image src={google} />
                &nbsp; | Regístrate con Google
              </Button>
              <Stack mt={10}></Stack>
              <Image maxW={"100%"} src={divide} />
              <Stack mt={10}></Stack>
            </Stack>
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </FormControl>

            <FormControl mt={4} verticalAlign={"center"}>
              <FormLabel>Contraseña</FormLabel>
              {/* <Input placeholder='Last name' /> */}
              <PasswordSmInput password={password} setPassword={setPassword} />
            </FormControl>

            <FormControl mt={4} verticalAlign={"center"}>
              <FormLabel>Confirmar contraseña</FormLabel>
              {/* <Input placeholder='Last name' /> */}
              <PasswordSmInput
                password={confirmPassword}
                setPassword={setConfirmPassword}
              />
            </FormControl>

            <Text textAlign={"start"} color={"#6C6C6C"} fontSize={10}>
              Crea una contraseña segura usando al menos 8 caracteres,
              mayúsculas y símbolos.
            </Text>
            <Text fontWeight={500} mt={3}>
              Codigo de familia (Opcional)
            </Text>
            <Input
              type="text"
              value={joinFamilyId}
              onChange={setJoinFamilyIdHandler}
              placeholder="Código (Opcional)"
              border={"1px solid gray"}
            ></Input>
            {/* <Checkbox defaultChecked fontWeight={500}>Recordarme</Checkbox> */}
            <Checkbox defaultChecked fontWeight={500} m={1} mt={5}>
              <Text fontSize={13}>Acepto los términos y condiciones</Text>
            </Checkbox>
            <Checkbox fontWeight={500} m={1}>
              <Text fontSize={13}>
                Quiero recibir promociones y novedades sobre IB Masters
              </Text>
            </Checkbox>
          </ModalBody>

          <VStack align={"center"} m={10}>
            <Button
              bgColor={MAIN_COLOR}
              color={"whiteAlpha.900"}
              mr={3}
              onClick={RegisterHandler}
            >
              Registarme
            </Button>
            {/* <Text color={'black.400'} fontSize={12}>¿Aún no tienes una cuenta?</Text> */}
            {/* <Text color={MAIN_COLOR} fontSize={12}><u><Link href='#'>¿Olvidaste tu contraseña?</Link></u></Text> */}
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

function PasswordSmInput({ setPassword, password }) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <VStack display={"block"}>
      <InputGroup size="md">
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Your password"
          pr="4.5rem"
          type={show ? "text" : "password"}
          border={"1px solid gray"}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClick}>
            {show ? <ViewOffIcon /> : <ViewIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
}
