import React, { useContext, useState, useEffect } from "react";
import {
  Stack,
  VStack,
  HStack,
  Image,
  Flex,
  SimpleGrid,
  Divider,
  Text,
  Avatar,
  Link,
  AvatarBadge,
  Button,
  ChakraProvider,
  Box,
  WrapItem,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { BellIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Images } from "../utils";
import { Link as NavigationLink } from "react-router-dom";
import Header from "./../components/Header";
import { useSelector } from "react-redux";
// import { UniversityComponent } from "../components";
import UniversityComponent from "../components/UniversityComponent/UniversityComponent";
import HomeTutor from "./HomeTutor";

import { getClasses, getFamilyInfo } from "../utils/ApiConfig";
import ClassWithCountdown from "../pages/Clases/ClassWithCountdown";
import {
  getValidDriveLink,
  extractMoreInfoFromClass,
  getHoursFromFamily,
  getHumanReadableTimeUntil,
} from "../utils/functions";
import { MAIN_COLOR } from "../utils/constants";

const HOURS_TO_SHOW_WARNING = 3; //00;
const TIME_TO_SHOW_WARNING = 7 * 24 * 60 * 60 * 1000; // a week
const EXPIRE_HOURS = 3 * 30 * 24 * 60 * 60 * 1000; //3 months

const Home = () => {
  const [hours, setHours] = useState(-1);
  const [enoughHoursView, setEnoughHoursView] = useState(true);
  const [expireHoursView, setExpireHoursView] = useState(true);
  const [expireHoursMessage, setExpireHoursMessage] = useState("");
  const { userInfo } = useSelector((state) => state.user);

  const [futureClasses, setFutureClasses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userInfo?._id) {
      setLoading(false);
      return;
    }
    async function get_info() {
      setLoading(true);
      let family = await getFamilyInfo(userInfo.family);
      let hrs = getHoursFromFamily(family);
      setHours(hrs);
      let filter =
        userInfo.role === "Student"
          ? { studentIds: userInfo._id }
          : { families: userInfo.family };
      let futureClassesRes = await getClasses({
        ...filter,
        start: { $gte: new Date() },
      });
      let future = futureClassesRes?.data || [];
      future = await Promise.all(
        future.map((futureClass) =>
          extractMoreInfoFromClass(futureClass, "future")
        )
      );
      setFutureClasses(future);
      setLoading(false);

      setEnoughHoursView(hrs <= HOURS_TO_SHOW_WARNING);

      let show_expire = false;
      const lastPurchase = family.lastTimePurchase;
      //Will show expire only if there are hours remaining
      if (lastPurchase && hrs > 0) {
        let purchase = new Date(lastPurchase);
        let deadline = new Date(purchase.getTime() + EXPIRE_HOURS);
        let now = new Date();
        let left = deadline.getTime() - now.getTime();
        console.log(left);
        show_expire = left < TIME_TO_SHOW_WARNING;
        if (show_expire) {
          setExpireHoursMessage(getHumanReadableTimeUntil(deadline));
        }
      }

      setExpireHoursView(show_expire);
    }
    get_info();
  }, [userInfo]);
  if (loading || !userInfo?._id) {
    return (
      <Text>
        Cargando <Spinner />
      </Text>
    );
  }
  // if (!userInfo?._id) {
  //   return <Text>No se encontró usuario. Por favor inicie sesión.</Text>;
  // }
  return (
    <div>
      <ChakraProvider>
        {!loading && enoughHoursView && (
          <Stack
            w={"101%"}
            align={"center"}
            direction
            color={"white"}
            bgColor={"red"}
            justify={"space-between"}
          >
            <Stack direction w={"100%"} align={"center"}>
              <Text w={"100%"} textAlign={"center"} p={3} fontSize={"90%"}>
                <InfoOutlineIcon color={"white"} /> Te quedan {hours} HORAS de
                clase.{" "}
                <NavigationLink to="/comprar">
                  <Text textDecoration={"underline"} as="span">
                    ¡Renueva tu paquete aquí!
                  </Text>
                </NavigationLink>
              </Text>
            </Stack>
            <Button
              justify={"right"}
              align={"right"}
              _hover={{ bgColor: "red" }}
              color={"white"}
              bgColor={"red"}
              onClick={() => {
                setEnoughHoursView(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Stack>
        )}
        {!loading && expireHoursView && (
          <Stack
            w={"101%"}
            align={"center"}
            direction
            color={"white"}
            bgColor={"red"}
            justify={"space-between"}
          >
            <Stack direction w={"100%"} align={"center"}>
              <Text w={"100%"} textAlign={"center"} p={3} fontSize={"90%"}>
                <InfoOutlineIcon color={"white"} /> Tus horas se vencerán en{" "}
                {expireHoursMessage}!{" "}
                <NavigationLink to="/comprar">
                  <Text textDecoration={"underline"} as="span">
                    ¡Renueva tu paquete aquí!
                  </Text>
                </NavigationLink>
              </Text>
            </Stack>
            <Button
              justify={"right"}
              align={"right"}
              _hover={{ bgColor: "red" }}
              color={"white"}
              bgColor={"red"}
              onClick={() => {
                setEnoughHoursView(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Stack>
        )}
        <Header />
        <Flex flexDirection="column" style={{ padding: 30 }}>
          <Text
            marginLeft={{ base: "3", md: "none" }}
            textAlign={"start"}
            fontSize={{ base: "xl", md: "3xl" }}
            fontWeight={600}
          >
            {` ¡Que bueno verte de nuevo ${userInfo?.name}!`}
          </Text>
          {futureClasses.length > 0 && (
            <ClassWithCountdown
              name={futureClasses[0].info.subjectDescription}
              schedule={futureClasses[0].info.timeDescription}
              tutorName={futureClasses[0].info.tutorInfo.name}
              tutorImage={getValidDriveLink(
                futureClasses[0].info.tutorInfo.imageDriveLink
              )}
              startTime={futureClasses[0].class.start}
              endTime={futureClasses[0].class.end}
              classId={futureClasses[0].class._id}
              isParent={userInfo.role === "Parent"}
              // schedule="Jueves 09 de marzo a las 15:00 hrs."
            />
          )}
          <NavigationLink
            // alignSelf={"flex-end"}
            // href="/clases"
            to="/clases"
            // color={"#07A58E"}
          >
            <Text
              fontSize={"xl"}
              textAlign={"right"}
              color={MAIN_COLOR}
              textDecoration={"underline"}
            >
              Ver todas las clases
            </Text>
          </NavigationLink>
          <VStack
            width={"100%"}
            alignSelf={"flex-start"}
            alignItems={"flex-start"}
            gap={4}
          >
            <Text
              marginLeft={{ base: "3", md: "none" }}
              textAlign={"start"}
              fontSize={{ base: "xl", md: "3xl" }}
              fontWeight={600}
            >
              Nuestros recursos
            </Text>
            <UniversityComponent />
          </VStack>
        </Flex>
      </ChakraProvider>
    </div>
  );
};

export default Home;
