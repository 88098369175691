import React, { useContext, useState, useEffect } from "react";
import {
  Stack,
  VStack,
  HStack,
  Image,
  Flex,
  SimpleGrid,
  Divider,
  Text,
  Avatar,
  Link,
  AvatarBadge,
  Button,
  ChakraProvider,
  Tooltip,
} from "@chakra-ui/react";
import { BsNewspaper, BsCalculator } from "react-icons/bs";
import { BellIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { TbBooks, TbTestPipe2 } from "react-icons/tb";
import { Link as NavigationLink } from "react-router-dom";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

import { useSelector } from "react-redux";
import {
  getClasses,
  getFilteredSubjects,
  getStrikes,
} from "../../utils/ApiConfig";
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getMonthYearName,
} from "../../utils/functions";
import { MAIN_COLOR } from "../../utils/constants";
import "./MonthPayInfo.css";

const MonthPayInfo = ({ year, month, startExpanded }) => {
  const { userInfo } = useSelector((state) => state.user);
  const [totalClasses, setTotalClasses] = useState(0);
  const [pendingClassCount, setPendingClassCount] = useState(0);
  const [pendingReports, setPendingReports] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [monthName, setMonthName] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [strikes, setStrikes] = useState([]);
  const [totalStrike, setTotalStrike] = useState(0);
  useEffect(() => {
    async function get_info() {
      if (!userInfo?._id) {
        return;
      }
      if (startExpanded) {
        setExpanded(true);
      }
      let start = getFirstDayOfMonth(year, month);
      setMonthName(getMonthYearName(start));
      let end = getLastDayOfMonth(year, month);

      // Classes that started on a given month
      let allClasses = await getClasses({
        tutorId: userInfo._id,
        start: { $gte: start, $lt: end },
        // end: { $lte: end },
      });
      allClasses = allClasses?.data || [];
      let totalHours = 0;
      let totalPending = 0;
      let totalMissingReports = 0;
      for (const thisClass of allClasses) {
        totalHours += thisClass.duration;
        if (thisClass.pending) {
          totalPending += 1;
        }
        if (thisClass.tutorFeedback == "") {
          totalMissingReports += 1;
        }
      }
      setPendingReports(totalMissingReports);
      setTotalHours(totalHours);
      setPendingClassCount(totalPending);
      setTotalClasses(allClasses.length);

      try {
        let response = await getStrikes({
          created: { $gte: start, $lt: end },
          tutor: userInfo._id,
        });
        let total = 0;
        for (let strike of response) {
          total += strike.value;
        }
        setStrikes(response);
        setTotalStrike(total);
      } catch (err) {
        console.log(`Getting error from getting strikes:`);
        console.log(err);
      }
    }
    get_info();
  }, [userInfo]);
  if (!userInfo) {
    return "Cargando...";
  }
  if (userInfo.role !== "Tutor" && userInfo.role !== "Admin") {
    return `Esta componente solo esta disponible para tutores, pero se encontro ${userInfo.role}`;
  }
  const getPriceString = (price) => {
    return `${userInfo.currency === "USD" ? "$" : "S/."} ${price}`;
  };
  return (
    <Stack
      backgroundColor={"#ffffff"}
      //   flex={1}
      rounded={"xl"}
      shadow={"md"}
      bgColor={"white"}
      p={6}
    >
      <HStack alignItems={"center"}>
        <BsNewspaper fontSize={"140%"} color={MAIN_COLOR} />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDir={"row"}
          flex={1}
          cursor="hand"
        >
          <Text fontSize={"3xl"} fontWeight={500}>
            {monthName}
          </Text>
          <Stack
            cursor="pointer"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? (
              <ChevronDownIcon />
            ) : (
              <HStack>
                <Text fontSize="85%">
                  {totalHours - totalStrike}{" "}
                  {totalHours - totalStrike !== 1
                    ? "horas totales"
                    : "hora total"}
                </Text>
                <ChevronRightIcon />
              </HStack>
            )}
          </Stack>
        </Flex>
      </HStack>
      <Divider />
      {expanded &&
        (totalHours === 0 ? (
          <Text>No dió ninguna clase</Text>
        ) : (
          <Stack>
            {/* <SimpleGrid columns={1}> */}
            {/* <Stack> */}
            <Stack direction gap={5} p={3}>
              <Text fontSize="85%">#Classes:</Text>
              <Text fontSize="85%" fontWeight={500}>
                {totalClasses}
              </Text>
            </Stack>
            <Stack direction gap={5} p={3}>
              <Text fontSize="85%">Clases pendientes:</Text>
              <Text fontSize="85%" fontWeight={500}>
                {pendingClassCount}
              </Text>
            </Stack>
            <Stack direction gap={5} p={3}>
              <Text fontSize="85%">Reportes pendientes:</Text>
              <Text fontSize="85%" fontWeight={500}>
                {pendingReports}
              </Text>
            </Stack>
            <Stack direction gap={5} p={3}>
              <Text fontSize="85%">Horas programadas:</Text>
              <Text fontSize="85%" fontWeight={500}>
                {totalHours}
              </Text>
            </Stack>
            <Stack direction gap={5} p={3}>
              <Text fontSize="85%" color={totalStrike === 0 ? "green" : "red"}>
                Strikes:
              </Text>
              {totalStrike === 0 ? (
                <Text fontSize="85%" fontWeight={500} color={"green"}>
                  0. Buen trabajo! 🤩
                </Text>
              ) : (
                <Tooltip
                  hasArrow
                  label={
                    <Stack>
                      {strikes.map((strike) => (
                        <Text key={strike._id}>
                          {strike.value}h por {strike.reason}
                        </Text>
                      ))}
                    </Stack>
                  }
                >
                  <Text fontSize="85%" fontWeight={500} color={"red"}>
                    {-totalStrike} 😬
                  </Text>
                </Tooltip>
              )}
            </Stack>
            <Stack direction gap={5} p={3}>
              <Text fontSize="85%">
                Pago previsto:{" "}
                <Text fontSize="sm">
                  (asumiendo un salario de {getPriceString(userInfo.salary)} por
                  hora)
                </Text>
              </Text>

              <Text fontSize="85%" fontWeight={500}>
                {getPriceString(userInfo.salary * (totalHours - totalStrike))}
              </Text>
            </Stack>
            {/* </Stack> */}
          </Stack>
        ))}
      {/* </SimpleGrid> */}
      {/* </VStack> */}
    </Stack>
  );
};

export default MonthPayInfo;
