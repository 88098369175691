import React, { useContext, useState, useEffect } from "react";
import {
  Stack,
  VStack,
  HStack,
  Image,
  Flex,
  SimpleGrid,
  Divider,
  Text,
  Avatar,
  Link,
  AvatarBadge,
  Button,
  ChakraProvider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { BsNewspaper, BsCalculator } from "react-icons/bs";
import { BellIcon, CloseIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { TbBooks, TbTestPipe2 } from "react-icons/tb";
import { Images } from "../utils";
import { Link as NavigationLink, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useSelector } from "react-redux";
// import { ProfileInfoTutor, TutorProfileComponent } from "../components";
import ProfileInfoTutor from "../components/ProfileInfo/ProfileInfoTutor";
import TutorProfileComponent from "../components/TutorProfileComponent/TutorProfileComponent";
import {
  extractMoreInfoFromClass,
  requiresCalendarUpdate,
  formatWorkingWeek,
} from "../utils";
import {
  getClasses,
  getFilteredSubjects,
  confirmCalendarUpdate,
} from "../utils/ApiConfig";
import ClassWithCountdown from "../pages/Clases/ClassWithCountdown";
import MonthPayInfo from "./Teacher/MonthPayInfo";
import { MAIN_COLOR } from "../utils/constants";

const HomeTutor = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirmation,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [showCalendarAlert, setShowCalendarAlert] = useState(true);
  const [futureClasses, setFutureClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const isSunday = new Date().getDay() === 0;

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    async function get_info() {
      // Getting the subjects the teacher teaches
      let subjectsRes = await getFilteredSubjects({
        _id: { $in: userInfo.subjects },
      });
      setSubjects(subjectsRes?.data || []);

      let futureClassesRes = await getClasses({
        tutorId: userInfo._id,
        pending: false,
        end: { $gte: new Date() },
      });
      let future = futureClassesRes?.data || [];
      future = await Promise.all(
        future.map((futureClass) =>
          extractMoreInfoFromClass(futureClass, "future")
        )
      );
      setFutureClasses(future);
    }
    get_info();
  }, [userInfo]);
  const handleUpdateCalendar = async () => {
    setIsLoading(true);
    // To make the spinner show
    await new Promise((resolve, reject) => {
      setTimeout(resolve, 2000);
    });
    await confirmCalendarUpdate(userInfo._id);
    setIsLoading(false);
    onClose();
    onOpenConfirmation();
    setShowCalendarAlert(false);
  };
  return (
    <div>
      <ChakraProvider>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay onClick={onClose} />
          <ModalContent>
            <ModalBody style={{ margin: 10 }}>
              <Flex justifyContent="space-around" flexDir="column">
                <Text fontSize={"120%"} fontWeight={500}>
                  Ya editaste tu calendario de esta semana?
                </Text>
                <Text m={5}>
                  Necesitas actualizar el calendario para la semana{" "}
                  {formatWorkingWeek(isSunday ? 1 : 0)}
                </Text>
                <HStack alignItems="center">
                  <Button
                    bg={MAIN_COLOR}
                    color="white"
                    onClick={handleUpdateCalendar}
                    isLoading={isLoading}
                  >
                    Si lo hice! 😎
                  </Button>
                  <Button onClick={onClose} isDisabled={isLoading}>
                    ...espera dame un segundo 🫣
                  </Button>
                </HStack>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal isOpen={isOpenConfirmation} onClose={onCloseConfirmation}>
          <ModalOverlay onClick={onCloseConfirmation} />
          <ModalContent>
            <ModalBody style={{ margin: 10 }}>
              <Flex
                justifyContent="space-around"
                flexDir="column"
                alignItems="center"
              >
                <Text fontSize={"120%"} fontWeight={500}>
                  Gracias por confirmar! 🤩
                </Text>
                <HStack alignItems="center">
                  <Button onClick={onCloseConfirmation}>Volver</Button>
                </HStack>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>

        {showCalendarAlert &&
          requiresCalendarUpdate(userInfo.lastCalendarUpdate) && (
            <Stack
              w={"101%"}
              align={"center"}
              direction
              color={"white"}
              bgColor={"red"}
              justify={"space-between"}
            >
              <Stack direction w={"100%"} align={"center"}>
                <Text w={"100%"} textAlign={"center"} p={3} fontSize={"90%"}>
                  <InfoOutlineIcon color={"white"} /> Tu calendario se encuentra
                  desactualizado.{" "}
                  <Link onClick={onOpen} cursor="pointer">
                    ¡Actualízalo aquí!
                  </Link>
                </Text>
              </Stack>
              {/* <Button
                justify={"right"}
                align={"right"}
                _hover={{ bgColor: "red" }}
                color={"white"}
                bgColor={"red"}
                onClick={() => {
                  setShowCalendarAlert(false);
                }}
              >
                <CloseIcon />
              </Button> */}
            </Stack>
          )}
        <Header />
        <Flex flexDirection="column" style={{ padding: 30 }}>
          <Text
            marginLeft={{ base: "3", md: "none" }}
            textAlign={"start"}
            fontSize={{ base: "xl", md: "3xl" }}
            fontWeight={600}
          >
            {` ¡Que bueno verte, ${userInfo?.name}!`}
          </Text>
          {futureClasses.length > 0 && (
            <ClassWithCountdown
              name={futureClasses[0].info.subjectDescription}
              schedule={futureClasses[0].info.timeDescription}
              tutorName={futureClasses[0].info.tutorInfo.name}
              // tutorImage={getValidDriveLink(
              //   futureClasses[0].info.tutorInfo.imageDriveLink
              // )}
              startTime={futureClasses[0].class.start}
              endTime={futureClasses[0].class.end}
              classId={futureClasses[0].class._id}
              participantMessage={futureClasses[0].info.participantMessage}
              isTutor={true}
            />
          )}
          <NavigationLink
            // alignSelf={"flex-end"}
            // href="/clases"
            to="/clases"
            // color={"#07A58E"}
          >
            <Text
              fontSize={"xl"}
              textAlign={"right"}
              color={MAIN_COLOR}
              textDecoration={"underline"}
            >
              Ver todas las clases
            </Text>
          </NavigationLink>
          <Flex
            //   marginBottom={80}
            width={"100%"}
            direction={{ base: "column", lg: "row" }}
            gap={5}
          >
            <Flex flexDir="column">
              <MonthPayInfo
                year={new Date().getFullYear()}
                month={new Date().getMonth()}
                startExpanded={true}
              />
              <Button
                alignSelf={"center"}
                bgColor={MAIN_COLOR}
                color={"white"}
                marginTop={5}
                onClick={() => {
                  navigate("/ajustes?start=2");
                }}
              >
                Ver meses pasados
              </Button>
            </Flex>
            {/*  */}
            <Stack
              flex={1.3}
              backgroundColor={"#ffffff"}
              rounded={"xl"}
              shadow={"md"}
              bgColor={"white"}
              p={6}
            >
              <HStack alignItems={"center"}>
                <TbBooks fontSize={"140%"} color={MAIN_COLOR} />
                <Stack>
                  {/* <Link to="/recursors/universidades"> */}
                  <Text fontSize={"3xl"} fontWeight={500}>
                    Materiales
                  </Text>
                  {/* </Link> */}
                </Stack>
              </HStack>
              <Divider />
              <VStack p={2} alignItems={"flex-start"} width={"100%"}>
                <SimpleGrid columns={1}>
                  {subjects.map((subject) => (
                    <Stack align={"center"} direction gap={2} p={3}>
                      {/* See if it's possible to change the icon depending on the subject */}
                      <BsCalculator fontSize={"110%"} color={MAIN_COLOR} />
                      <Link
                        _hover={{
                          color: MAIN_COLOR,
                          textDecoration: "underline",
                        }}
                        href={subject.folder}
                        target="_blank"
                      >
                        {subject.name}
                      </Link>
                    </Stack>
                  ))}
                  {/* <Stack align={"center"} direction gap={2} p={3}>
                    <BsCalculator fontSize={"110%"} color={MAIN_COLOR} />
                    <Text
                      _hover={{ color: MAIN_COLOR, textDecoration: "underline" }}
                    >
                      IB - Clases - Matemáticas AA HL
                    </Text>
                  </Stack>
                  <Stack align={"center"} direction gap={2} p={3}>
                    <TbTestPipe2 fontSize={"110%"} color={MAIN_COLOR} />
                    <Text
                      _hover={{ color: MAIN_COLOR, textDecoration: "underline" }}
                    >
                      Tutorías - Matemática
                    </Text>
                  </Stack> */}
                </SimpleGrid>
              </VStack>
            </Stack>
          </Flex>
        </Flex>

        {/* 
        <VStack
          marginTop={10}
          width={{ base: "100%", md: "70%", lg: "60%" }}
          marginLeft={"auto"}
          gap={5}
          marginRight={"auto"}
        >
          <VStack
            width={"100%"}
            minH={"20vh"}
            alignSelf={"flex-start"}
            alignItems={"flex-start"}
            gap={4}
          >
            <Text
              marginLeft={{ base: "3", md: "none" }}
              textAlign={"start"}
              fontSize={{ base: "xl", md: "3xl" }}
              fontWeight={600}
            >
              {` ¡Que bueno verte, ${userInfo?.name}!`}
            </Text>

            <ProfileInfoTutor />
          </VStack>
          <VStack
            width={"100%"}
            alignSelf={"flex-start"}
            alignItems={"flex-start"}
            gap={4}
          >
            <TutorProfileComponent />
          </VStack>
        </VStack> */}
      </ChakraProvider>
    </div>
  );
};

export default HomeTutor;
