import React, { useState, useEffect } from "react";
import { HStack, Text, Select } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { getStudent } from "../../utils/ApiConfig";
const SelectStudent = ({ currentStudent, setCurrentStudent, disabled }) => {
  const { userInfo } = useSelector((state) => state.user);
  const [allStudents, setAllStudents] = useState([]);

  useEffect(() => {
    async function getStudents() {
      try {
        const studentArray = userInfo.students.map(async (studentId) => {
          const response = await getStudent(studentId);
          return response.data;
        });

        const students = await Promise.all(studentArray);
        setAllStudents(students);
      } catch (err) {
        console.log(err);
      }
    }
    getStudents();
  }, [userInfo]);

  const SelectHandler = (item) => {
    let selected = allStudents.filter(
      (student) => student._id === item.target.value
    );
    if (selected && selected.length > 0) {
      setCurrentStudent(selected[0]);
    } else {
      setCurrentStudent({});
    }
  };
  if (!userInfo) {
    return "Cargando...";
  }
  if (userInfo.role !== "Parent") {
    return `Esto solo es para padres de familia, no ${userInfo.role}`;
  }
  return (
    <HStack width={"100%"} py={5}>
      <Text fontSize={"xl"} fontWeight={500}>
        Ver clases de mi hijo/a:
      </Text>
      <Select
        top="0"
        left="0"
        opacity={1}
        height={"5vh"}
        w={"max-content"}
        icon={<ChevronDownIcon />}
        onChange={SelectHandler}
        placeholder="Seleccione uno de sus hijos"
        isDisabled={disabled}
      >
        {allStudents.map((student) => (
          <option
            key={student._id}
            value={student._id}
          >{`${student.name} ${student.surname}`}</option>
        ))}
      </Select>
    </HStack>
  );
};

export default SelectStudent;
