import React, { useState, useEffect } from "react";
import {
  HStack,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ChakraProvider,
  Select,
  Spinner,
  Center,
  useDisclosure,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { MAIN_COLOR } from "../../utils/constants";

const MAX_SECONDS_TO_SHOW_LINK = 60 * 5; //5 minutes

const ClassWithCountdown = ({
  tutorName,
  tutorImage,
  name,
  schedule,
  startTime,
  classId,
  participantMessage,
  isParent,
  isTutor,
  endTime,
}) => {
  const [enableLink, setEnableLink] = useState(false);

  const navigate = useNavigate();
  // const diff = (new Date(startTime) - new Date()) / 1000;
  // let enable_link = diff < MAX_SECONDS_TO_SHOW_LINK;
  // const {clasesId} = useParams();

  const onClassClick = useCallback(() => {
    navigate(`/call?id=${classId}`);
  }, [navigate]);

  // Lost Numbers: just for fun
  const [secondsLeft, setSecondsLeft] = useState(42);
  const [minutesLeft, setMinutesLeft] = useState(23);
  const [hoursLeft, setHoursLeft] = useState(16);
  const [daysLeft, setDaysLeft] = useState(4815162342);
  const [hasPassedStart, setHasPassedStart] = useState(false);
  const [hasPassedEnd, setHasPassedEnd] = useState(false);
  const SECONDS_PER_MINUTE = 60;
  const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
  const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

  useEffect(() => {
    const countdown = setInterval(() => {
      let diff = (new Date(startTime) - new Date()) / 1000;
      if (diff < MAX_SECONDS_TO_SHOW_LINK) {
        setEnableLink(true);
      }
      if (diff < 0) {
        setHasPassedStart(true);
        diff = (new Date(endTime) - new Date()) / 1000; // If it passed start it cannot have passed the end
        if (diff < 0) {
          setHasPassedEnd(true);
          return;
        }
      }
      diff = Math.floor(diff);
      let days = Math.floor(diff / SECONDS_PER_DAY);
      diff %= SECONDS_PER_DAY;
      let hours = Math.floor(diff / SECONDS_PER_HOUR);
      diff %= SECONDS_PER_HOUR;
      let minutes = Math.floor(diff / SECONDS_PER_MINUTE);
      diff %= SECONDS_PER_MINUTE;
      let seconds = diff;
      setSecondsLeft(seconds);
      setMinutesLeft(minutes);
      setHoursLeft(hours);
      setDaysLeft(days);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, []);
  const pad = (n) => {
    return n.toString().padStart(2, "0");
  };
  const getTextMessage = () => {
    const start = isParent ? "La clase de tu hijo/a" : "Tu clase";
    const description = hasPassedStart ? "se acabará en" : "empezará en";
    return start + " " + description;
  };
  return (
    <ChakraProvider>
      <Stack
        h={{ base: "22vh", sm: "18vh", md: "16vh" }}
        justify={{ base: "center", md: "start" }}
        direction={{ base: "column", md: "row" }}
        p={6}
        rounded={"lg"}
        boxShadow={"lg"}
        bgColor={"white"}
        // borderColor={"#07A58E"}
        borderColor={MAIN_COLOR}
        borderWidth={1}
      >
        <Tooltip label={participantMessage}>
          <Stack alignSelf={"center"} marginRight={5}>
            <Avatar
              size={{ base: "lg", md: "lg", lg: "xl" }}
              // size={{ base: "lg", md: "2xl" }}
              name={isTutor ? participantMessage : tutorName}
              src={isTutor ? "" : tutorImage}
            >
              {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
            </Avatar>
          </Stack>
        </Tooltip>
        {/* <Stack marginRight={10} marginLeft={10}> */}
        <Divider
          orientation="vertical"
          //   display={{ base: "initial", md: "none" }}
        />
        {/* </Stack> */}
        {/* <Stack w={"80%"} alignSelf={"center"}> */}
        <Stack flex={1} alignItems={"center"} justifyContent="center">
          <Text
            fontWeight={600}
            fontSize={{ base: "80%", md: "110%", lg: "120%", xl: "140%" }}
            textAlign="center"
          >
            {name}
          </Text>
          {/* <Text
            fontSize={{ base: "50%", md: "80%", lg: "90%", xl: "110%" }}
            textAlign="center"
          >
            con {participantMessage}
          </Text> */}
          <Text
            // fontWeight={600}
            fontSize={{ base: "80%", md: "110%", lg: "120%", xl: "140%" }}
          >
            {schedule}
          </Text>
          <Stack justifySelf={{ base: "none", md: "end" }}>
            {/* <Stack> */}
          </Stack>
        </Stack>

        <Divider orientation="vertical" />

        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          display="flex"
          flex={1}
        >
          {hasPassedEnd ? (
            <Text>Esta clase ya se acabó 🫣</Text>
          ) : (
            <>
              <Text
                // color="#07A58E"
                color={hasPassedStart ? "red" : MAIN_COLOR}
                fontSize={{ base: "75%", md: "100%" }}
                textAlign="center"
              >
                {/** TODO: See if a countdown might be worth doing */}
                {getTextMessage()}
                <Text
                  fontSize={{
                    base: "120%",
                    md: "130%",
                    lg: "140%",
                    xl: "160%",
                  }}
                  fontWeight="bold"
                >
                  {daysLeft > 1
                    ? `${daysLeft} días`
                    : daysLeft == 1
                    ? `1 día`
                    : `${pad(hoursLeft)}h: ${pad(minutesLeft)}m: ${pad(
                        secondsLeft
                      )}s`}
                  {/* {daysLeft > 0 && `${pad(daysLeft)}d,`} */}
                </Text>
                {/* (en ~ {getHumanReadableTimeUntil(startTime)}) */}
              </Text>
              {/* {enableLink && ( */}
              <Button
                onClick={onClassClick}
                rounded={"lg"}
                color={"white"}
                fontWeight={500}
                bgColor={"#07A58E"}
                // isDisabled={!enableLink}
              >
                Ingresar
              </Button>
              {/* // )} */}
            </>
          )}
          {/* )} */}
        </Stack>

        {/* <Stack
          justify={"space-between"}
          direction={{ base: "column", md: "row" }}
          marginLeft={2}
        >


        </Stack> */}
      </Stack>
      {/* </Stack> */}
    </ChakraProvider>
  );
};

export default ClassWithCountdown;
