import React from "react";
import {
  Stack,
  VStack,
  HStack,
  Image,
  Flex,
  SimpleGrid,
  Divider,
  Text,
  Avatar,
  AvatarBadge,
  Box,
  Button,
  WrapItem,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Images } from "../../utils";
import { BsNewspaper, BsCalculator } from "react-icons/bs";
import { TbBooks, TbTestPipe2 } from "react-icons/tb";
import { MAIN_COLOR } from "../../utils/constants";

function Cards(props) {
  return (
    <HStack display={"flex"} gap={2}>
      <Image src={props.address} alt="..." />
      <Text fontSize={{ base: "md", md: "xl" }}>{props.name}</Text>
    </HStack>
  );
}
const TutorProfileComponent = () => {
  return (
    <Flex
      //   marginBottom={80}
      width={"100%"}
      direction={{ base: "column", lg: "row" }}
      gap={5}
    >
      <Stack
        backgroundColor={"#ffffff"}
        flex={1}
        rounded={"xl"}
        shadow={"md"}
        bgColor={"white"}
        p={6}
      >
        <HStack alignItems={"center"}>
          <BsNewspaper fontSize={"140%"} color={MAIN_COLOR} />
          <Stack>
            {/* <Link to="/recursos/ib"> */}
            <Text fontSize={"3xl"} fontWeight={500}>
              Información del Mes
            </Text>
            {/* </Link> */}
          </Stack>
        </HStack>
        <Divider />
        <VStack p={2} alignItems={"flex-start"} width={"100%"}>
          <SimpleGrid columns={1}>
            <Stack direction gap={5} p={3}>
              <Text>Clases pendientes:</Text>
              <Text fontWeight={500}>0</Text>
            </Stack>
            <Stack direction gap={5} p={3}>
              <Text>Reportes pendientes:</Text>
              <Text fontWeight={500}>0</Text>
            </Stack>
            <Stack direction gap={5} p={3}>
              <Text>Horas programadas:</Text>
              <Text fontWeight={500}>0</Text>
            </Stack>
            <Stack direction gap={5} p={3}>
              <Text>Pago previsto:</Text>
              <Text fontWeight={500}>S/0.00</Text>
            </Stack>
          </SimpleGrid>
          <Button alignSelf={"center"} bgColor={MAIN_COLOR} color={"white"}>
            Ver meses pasados
          </Button>
        </VStack>
      </Stack>
      {/*  */}
      <Stack
        flex={1.3}
        backgroundColor={"#ffffff"}
        rounded={"xl"}
        shadow={"md"}
        bgColor={"white"}
        p={6}
      >
        <HStack alignItems={"center"}>
          <TbBooks fontSize={"140%"} color={MAIN_COLOR} />
          <Stack>
            {/* <Link to="/recursors/universidades"> */}
            <Text fontSize={"3xl"} fontWeight={500}>
              Materiales
            </Text>
            {/* </Link> */}
          </Stack>
        </HStack>
        <Divider />
        <VStack p={2} alignItems={"flex-start"} width={"100%"}>
          <SimpleGrid columns={1}>
            <Stack align={"center"} direction gap={2} p={3}>
              <BsCalculator fontSize={"110%"} color={MAIN_COLOR} />
              <Text _hover={{ color: MAIN_COLOR, textDecoration: "underline" }}>
                IB - Clases - Matemáticas AA HL
              </Text>
            </Stack>
            <Stack align={"center"} direction gap={2} p={3}>
              <TbTestPipe2 fontSize={"110%"} color={MAIN_COLOR} />
              <Text _hover={{ color: MAIN_COLOR, textDecoration: "underline" }}>
                Tutorías - Matemática
              </Text>
            </Stack>
          </SimpleGrid>
        </VStack>
      </Stack>
    </Flex>
  );
};

export default TutorProfileComponent;
