import React, { useState, useEffect, useReducer } from "react";
import {
  HStack,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ChakraProvider,
  Select,
  Spinner,
  Center,
  useDisclosure,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import {
  ChatIcon,
  CloseIcon,
  HamburgerIcon,
  CalendarIcon,
  ExternalLinkIcon,
  CheckIcon,
  InfoOutlineIcon,
  ChevronDownIcon,
  ViewIcon,
} from "@chakra-ui/icons";
// import ModalRating from "../../components/ModalRating";
import RateClassModal from "./RateClassModal";
import { useNavigate } from "react-router-dom";
import CalendarClasses from "./CalendarClasses.jsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@chakra-ui/react";
import SelectStudent from "./SelectStudent";
import {
  getClasses,
  getSubjectById,
  getTutorDetail,
} from "../../utils/ApiConfig.js";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
} from "../../utils/functions.js";
import {
  PENDING_CLASS_COLOR,
  PAST_CLASS_COLOR,
  CONFIRMED_CLASS_COLOR,
  MAIN_COLOR,
} from "../../utils/constants";
import CancelClassModal from "./CancelClassModal";
import StarRating from "../../components/StarRating";
import ViewTutorFeedbackModal from "./ViewTutorFeedbackModal";

const ListaClases = ({ type, classInfo, info, listInfo }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo } = useSelector((state) => state.user);
  const [submittedRating, setSubmittedRating] = useState(0);
  const {
    isOpen: isOpenViewTutorFeedback,
    onOpen: onOpenViewTutorFeedback,
    onClose: onCloseViewTutorFeedback,
  } = useDisclosure();

  // const [subjectName, setSubjectName] = useState("");
  // const [tutorName, setTutorName] = useState("");
  // const [tutorPic, setTutorPic] = useState("");
  // useEffect(() => {
  //   const getInfo = async () => {
  //     let subjectRes = await getSubjectById(props.class.subjectId);
  //     let subject = subjectRes.data;

  //     let tutorRes = await getTutorDetail(props.class.tutorId);
  //     let tutor = tutorRes.data;
  //     setSubjectName(subject.name);
  //     setTutorName(tutor.name);
  //     setTutorPic(getValidDriveLink(tutor.imageDriveLink));
  //   };
  //   getInfo();
  // }, []);
  if (!userInfo) {
    return "Cargando...";
  }
  // Each feedback has sender, text and rating
  const getUserFeedback = () => {
    let feedback = classInfo.individualStudentFeedback.filter(
      (feedback) => feedback.sender === userInfo._id
    );
    if (feedback && feedback.length > 0) {
      return feedback[0];
    }
    return null;
  };
  const isHappeningNow =
    new Date(classInfo.start) < new Date() &&
    new Date() < new Date(classInfo.end);
  return (
    <ChakraProvider>
      {(type === "pendientes") | (type === "próximas") ? (
        <>
          <Stack
            // h={{ base: "21vh", sm: "14vh", md: "16vh" }}
            justify={"start"}
            direction={"row"}
            p={6}
            rounded={"lg"}
            boxShadow={"lg"}
            bgColor={"white"}
          >
            <CancelClassModal
              onOpen={onOpen}
              isOpen={isOpen}
              onClose={onClose}
              classId={classInfo._id}
              tutorName={info.tutorInfo.name}
              tutorImage={getValidDriveLink(info.tutorInfo.imageDriveLink)}
              classTitle={info.subjectDescription}
              classDescription={info.timeDescription}
              isTutor={false}
              classInfo={classInfo}
            />
            <Tooltip label={info.tutorInfo.name}>
              <Stack alignSelf={"center"}>
                <Avatar
                  size={{ base: "lg", md: "lg", lg: "xl" }}
                  name={info.tutorInfo.name}
                  src={getValidDriveLink(info.tutorInfo.imageDriveLink)}
                >
                  {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
                </Avatar>
              </Stack>
            </Tooltip>

            <Divider orientation="vertical" />
            <Stack w={"100vw"} direction={"column"}>
              <HStack w={"100%"} justify={"space-between"}>
                <Stack>
                  <Text
                    fontWeight={500}
                    fontSize={{
                      base: "80%",
                      md: "100%",
                      lg: "120%",
                      xl: "150%",
                    }}
                  >
                    {info.timeDescription}
                    {isHappeningNow && (
                      <Text color="red" as="span">
                        {" "}
                        Pasando ahora!
                      </Text>
                    )}
                  </Text>
                </Stack>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<HamburgerIcon />}
                    variant="outline"
                    border={0}
                  />
                  <MenuList>
                    {/* <MenuItem icon={<ChatIcon />}>Chat</MenuItem> */}
                    <MenuItem
                      icon={<ExternalLinkIcon />}
                      onClick={() => {
                        navigate(`/tutor?id=${classInfo.tutorId}`);
                      }}
                    >
                      Ver tutor
                    </MenuItem>
                    {userInfo.role === "Student" && (
                      <MenuItem
                        color={"red"}
                        icon={<CloseIcon />}
                        onClick={onOpen}
                      >
                        Cancelar
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </HStack>
              <Text
                fontSize={{ base: "70%", md: "80%", lg: "100%", xl: "120%" }}
              >
                {info.subjectDescription}
              </Text>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <Stack
            // h={{ base: "27vh", sm: "20vh", md: "16vh" }}
            justify={"start"}
            direction={"row"}
            p={6}
            rounded={"lg"}
            boxShadow={"lg"}
            bgColor={"white"}
          >
            <ViewTutorFeedbackModal
              isOpen={isOpenViewTutorFeedback}
              onOpen={onOpenViewTutorFeedback}
              onClose={onCloseViewTutorFeedback}
              tutorFeedback={classInfo.tutorFeedback}
            />
            <Tooltip label={info.tutorInfo.name}>
              <Stack alignSelf={"center"}>
                <Avatar
                  size={{ base: "lg", md: "lg", lg: "xl" }}
                  name={info.tutorInfo.name}
                  src={getValidDriveLink(info.tutorInfo.imageDriveLink)}
                >
                  {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
                </Avatar>
              </Stack>
            </Tooltip>

            <Divider orientation="vertical" />
            <Stack w={"100%"}>
              <Stack
                justify={"space-between"}
                direction={{ base: "column", md: "row" }}
              >
                <Stack>
                  <Text
                    fontWeight={500}
                    fontSize={{
                      base: "80%",
                      md: "100%",
                      lg: "120%",
                      xl: "150%",
                    }}
                  >
                    {info.timeDescription}
                  </Text>
                  <Text
                    fontSize={{
                      base: "70%",
                      md: "80%",
                      lg: "100%",
                      xl: "120%",
                    }}
                  >
                    {info.subjectDescription}
                  </Text>
                  {/* Assume for now that all past classes ocurred*/}
                  <>
                    <Text
                      fontSize={{
                        base: "60%",
                        md: "70%",
                        lg: "100%",
                        xl: "120%",
                      }}
                      color={"#07A58E"}
                    >
                      <CheckIcon /> Usaste {classInfo.duration}h de clase.
                    </Text>
                  </>
                </Stack>
                {/* <ModalRating /> */}
                <Flex alignItems="center">
                  {submittedRating || getUserFeedback() ? (
                    <StarRating
                      size={{ base: 4, md: 4, lg: 5 }}
                      icon="star"
                      scale={5}
                      fillColor="gold"
                      strokeColor="grey"
                      rating={submittedRating || getUserFeedback().rating}
                      setRating={(val) => {}} //static
                    />
                  ) : userInfo.role === "Student" ? (
                    <RateClassModal
                      subjectInfo={info.subjectInfo}
                      tutorInfo={info.tutorInfo}
                      classInfo={classInfo}
                      subjectDescription={info.subjectDescription}
                      timeDescription={info.timeDescription}
                      senderInfo={userInfo}
                      onSubmitFeedback={({ rating }) => {
                        setSubmittedRating(rating);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Flex>
              </Stack>
            </Stack>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon />}
                variant="outline"
                border={0}
              />
              <MenuList>
                {/* <MenuItem icon={<ChatIcon />}>Chat</MenuItem> */}
                <MenuItem
                  icon={<ExternalLinkIcon />}
                  onClick={() => {
                    navigate(`/tutor?id=${classInfo.tutorId}`);
                  }}
                >
                  Ver tutor
                </MenuItem>
                <MenuItem icon={<ViewIcon />} onClick={onOpenViewTutorFeedback}>
                  Ver notas del tutor
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </>
      )}
    </ChakraProvider>
  );
};

const PAST_CLASSES_TO_SHOW = 2;
const SHOW_CLASSES_STEP = 10;

const ListClasses = ({ pendingClasses, futureClasses, pastClasses }) => {
  // const [expand, setExpand] = useState(false);
  // const [pastClassesShow, setPastClassesShow] = useState([]);
  // const [pastClassesHide, setPastClassesHide] = useState([]);

  const [classesToShow, setClassesToShow] = useState(PAST_CLASSES_TO_SHOW);

  const onExpandClick = () => {
    // setExpand((prev) => !prev);
    setClassesToShow((prev) => prev + SHOW_CLASSES_STEP);
  };
  const onResetClasses = () => {
    setClassesToShow(PAST_CLASSES_TO_SHOW);
  };

  // useEffect(() => {
  //   if (pastClasses.length > PAST_CLASSES_TO_SHOW) {
  //     setPastClassesShow(pastClasses.slice(0, PAST_CLASSES_TO_SHOW));
  //     setPastClassesHide(
  //       pastClasses.slice(PAST_CLASSES_TO_SHOW, pastClasses.length)
  //     );
  //   } else {
  //     setPastClassesShow(pastClasses);
  //   }
  // }, []);
  return (
    <ChakraProvider>
      <Stack>
        <Text fontSize={"130%"} fontWeight={500}>
          Clases pendientes &nbsp;
          <Popover placement="top-start" trigger="hover">
            <PopoverTrigger>
              <InfoOutlineIcon boxSize={"15px"} color={"#07A58E"} />
            </PopoverTrigger>
            <PopoverContent w={"200px"}>
              <PopoverBody>
                {" "}
                <Text fontSize={"70%"} textAlign={"start"}>
                  Espera a que el profesor apruebe la clase
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Text>
        {/** Pending classes */}
        {pendingClasses.length === 0 && <Text>No se encontraron clases.</Text>}
        {pendingClasses.map((pendingClass) => (
          <ListaClases
            key={pendingClass.class._id}
            type="pendientes"
            classInfo={pendingClass.class}
            info={pendingClass.info}
            listInfo={pendingClass.listInfo}
          />
        ))}
        <Text paddingTop={5} fontSize={"130%"} fontWeight={500}>
          Próximas clases
        </Text>
        {/* Future Classes */}
        {futureClasses.length === 0 && <Text>No se encontraron clases.</Text>}
        {futureClasses.map((futureClass) => (
          <ListaClases
            key={futureClass.class._id}
            type="próximas"
            classInfo={futureClass.class}
            info={futureClass.info}
            listInfo={futureClass.listInfo}
          />
        ))}
        <Text paddingTop={5} fontSize={"130%"} fontWeight={500}>
          Clases pasadas
        </Text>
        {/* Past Classes */}
        {pastClasses.length === 0 && <Text>No se encontraron clases.</Text>}

        {pastClasses.slice(0, classesToShow).map((pastClass) => (
          <ListaClases
            key={pastClass.class._id}
            type="pasadas"
            classInfo={pastClass.class}
            info={pastClass.info}
            listInfo={pastClass.listInfo}
          />
        ))}
        <Flex flexDir="column" alignItems="flex-end">
          <Text
            textAlign={"end"}
            fontSize={{ base: "70%", sm: "80%", md: "90%" }}
          >
            Viendo {Math.min(pastClasses.length, classesToShow)}/
            {pastClasses.length} clases pasadas.
          </Text>
          {pastClasses.length > classesToShow && (
            <Text
              onClick={onExpandClick}
              textAlign={"end"}
              fontSize={{ base: "70%", sm: "80%", md: "90%" }}
              // color={"#07A58E"}
              color={MAIN_COLOR}
              textDecoration={"underline"}
              _hover={{ cursor: "pointer" }}
            >
              Ver más clases
            </Text>
          )}
          {pastClasses.length <= classesToShow && pastClasses.length > 0 && (
            <Text
              onClick={onResetClasses}
              textAlign={"end"}
              fontSize={{ base: "70%", sm: "80%", md: "90%" }}
              // color={"#07A58E"}
              color={MAIN_COLOR}
              textDecoration={"underline"}
              _hover={{ cursor: "pointer" }}
            >
              Reiniciar
            </Text>
          )}
        </Flex>
      </Stack>
    </ChakraProvider>
  );
};

export default ListClasses;
