import React from "react";
import {
  Modal,
  InputGroup,
  Center,
  InputRightElement,
  Image,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  FormControl,
  Input,
  FormLabel,
  useDisclosure,
  ModalBody,
  Button,
  Stack,
  Checkbox,
  VStack,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { CalendarIcon, CheckCircleIcon } from "@chakra-ui/icons";
import PaymentPage from "../pages/Buy/PaymentPage.jsx";

function PaymentModal({
  amountPEN,
  onPay,
  isOpen,
  onOpen,
  onClose,
  payText,
  onError,
  payTitle,
}) {
  return (
    <>
      <Modal
        size={"xl"}
        // initialFocusRef={initialRef}
        // finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalBody>
            <PaymentPage
              amount={amountPEN}
              onPay={(param) => {
                onPay(param);
                onClose();
              }}
              payText={payText}
              payTitle={payTitle}
              onError={onError}
            />
          </ModalBody>

          {/* <ModalHeader>
            <Stack direction={"row"} h={"32px"}>
              <Image src="#" alt="..." />
              <Divider orientation="vertical" />
              <Text>Comprar {pay} </Text>
            </Stack>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack mb={5} justify={"space-between"} direction={"row"}>
              <Text fontWeight={500}>Tarjeta de crédito o débito</Text>
              <Image src="#" alt="..." />
            </Stack>

            <FormControl>
              <FormLabel>Número de tarjeta</FormLabel>
              <Input ref={initialRef} />
            </FormControl>

            <Stack direction={{ base: "column", md: "row" }}>
              <FormControl>
                <FormLabel>Vencimiento</FormLabel>
                <Input placeholder="MM/AA" />
              </FormControl>
              <FormControl>
                <FormLabel>CCV</FormLabel>
                <InputGroup>
                  <Input />
                  <InputRightElement width="4.5rem">
                    <CalendarIcon />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Stack>

            <FormControl mt={4}>
              <FormLabel>Nombre del titular</FormLabel>
              <Input />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Documento de identidad del titular</FormLabel>
              <Input />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Ingresar código de descuento</FormLabel>
              <Input />
            </FormControl>

            <FormControl mt={4}>
              <Checkbox>Recordar método de pago</Checkbox>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <VStack>
              <Button
                color={"white"}
                bgColor={"#09B98D"}
                onClick={() => {
                  onClose();
                  onOpenStatus();
                }}
              >
                Pagar S/240
              </Button>
              <Text textAlign={"start"} fontSize={"80%"}>
                Este método de pago viene directamente de Stripe. IB Masters no
                tiene acceso a ningún tipo de información sobre su pago.
              </Text>
            </VStack>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
}

export default PaymentModal;
