import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  Input,
  HStack,
  Select,
  InputGroup,
  InputLeftElement,
  Text,
  Stack,
} from "@chakra-ui/react";
import Flag from "react-world-flags";
import { AsYouType } from "libphonenumber-js";
import { getCountryIso2, getCountryTelCode } from "./countries";
import { PhoneIcon, ChevronDownIcon } from "@chakra-ui/icons";

export default function PhoneNumberInput({
  size,
  value,
  country,
  options,
  onChange,
  setGetCountryCode,
  placeholder,
  isDisabled,
  settings,
  ...rest
}) {
  let [number, setNumber] = useState(value || "");
  let [selectedCountry, setSelectedCountry] = useState(country || "");
  let [countryCode, setCountryCode] = useState(
    getCountryTelCode(country) || ""
  );

  useEffect(() => {
    setSelectedCountry(country);
    setCountryCode(getCountryTelCode(country));
  }, [country]);

  const onCountryChange = (e) => {
    let value = e.target.value;
    let code = getCountryTelCode(value);
    let CountryCodeIos2 = getCountryIso2(value);
    let parsedNumber = new AsYouType().input(`${code}${number}`);
    setGetCountryCode(CountryCodeIos2);
    setCountryCode(code);
    setSelectedCountry(value);
    onChange(parsedNumber);
  };

  const onPhoneNumberChange = (e) => {
    let value = e.target.value;
    let parsedNumber = `${countryCode}${value}`;
    // let parsedNumber = new AsYouType().input(`${countryCode}${value}`);
    setNumber(value);
    console.log(parsedNumber);
    onChange(parsedNumber);
  };

  return (
    <InputGroup width={"100%"} {...rest}>
      <InputLeftElement width="30%">
        <Select
          top="0"
          left="0"
          zIndex={1}
          bottom={0}
          opacity={0}
          height="100%"
          position="absolute"
          value={isDisabled ? "none" : selectedCountry}
          onChange={onCountryChange}
          icon={"none"}
          isDisabled={isDisabled ? isDisabled : false}
        >
          <option value="" />
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <Flex pl={2} width="100%" alignItems="center" alignContent={"center"}>
          {/* <HStack> */}
          {selectedCountry ? (
            <>
              {settings ? (
                <Box
                  mr={settings ? "none" : "4px"}
                  width={settings ? "0.8rem" : "1.5rem"}
                  height={settings ? "0.8rem" : "1.5rem"}
                  // flex={1}
                >
                  <Flag
                    // height="100%"
                    // width={settings ? "50%" : "100%"}
                    code={selectedCountry || ""}
                  />
                </Box>
              ) : (
                // </Box>
                <Box
                  mr={settings ? "none" : "4px"}
                  width={settings ? "0.8rem" : "1.5rem"}
                  height={settings ? "0.8rem" : "1.5rem"}
                  flex={1}
                >
                  <Flag
                    height="100%"
                    width={settings ? "50%" : "100%"}
                    code={selectedCountry || ""}
                  />
                </Box>
              )}
            </>
          ) : (
            <PhoneIcon />
          )}
          <Text fontSize={"80%"}>{countryCode}</Text>
          <ChevronDownIcon />
          {/* </HStack> */}
        </Flex>
      </InputLeftElement>
      <Input
        pl={settings ? "4rem" : "6rem"}
        type="tel"
        value={number}
        pattern="[0-9]"
        placeholder={placeholder}
        onChange={onPhoneNumberChange}
        w={"80%"}
        isDisabled={isDisabled ? isDisabled : false}
      />
    </InputGroup>
  );
}

PhoneNumberInput.defaultProps = {
  options: [],
  size: "md",
};
