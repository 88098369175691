import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Axios } from "../utils/ApiConfig";
/* Fetch User for fetching user data if token present in localStorage  */
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, thunkAPI) => {
    console.log("Fetching user");
    let token = localStorage.getItem("token");
    if (token) {
      try {
        token = JSON.parse(token);
        console.log("Fetching user from token");
        const response = await Axios.post("auth/checkToken", { token });
        console.log(response);
        return response?.data?.user;
      } catch (err) {
        console.log("Fetching user from token error: ");
        console.log(err);
      }
    }
    let googleToken = localStorage.getItem("googleRefreshToken");

    if (googleToken) {
      try {
        googleToken = JSON.parse(googleToken);
        let responseGoogle = await Axios.post("auth/checkGoogleRefreshToken", {
          refresh_token: googleToken,
        });
        console.log("Fetching user from google token");
        console.log(responseGoogle);
        return responseGoogle?.data?.user;
      } catch (err) {
        console.log("Fetching user from google token error: ");
        console.log(err);
      }
    }
    return;
  }
);

/* Login Thunk */
export const loginUser = createAsyncThunk(
  "user/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await Axios.post("auth/login", { email, password });
      let data = response.data;
      console.log("response", data);
      if (response.status === 200) {
        localStorage.setItem("token", JSON.stringify(data.token));
        return data.user;
      } else {
        throw new Error(data.message);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUserGoogle = createAsyncThunk(
  "user/googleLogin",
  async ({ code }, thunkAPI) => {
    try {
      console.log(`From Google : ${code}`);
      const response = await Axios.post("/auth/googleLogin", {
        code: code,
      });
      console.log(response);
      let data = response.data;
      if (response.status === 200) {
        localStorage.setItem(
          "googleRefreshToken",
          JSON.stringify(data.refresh_token)
        );
        return data.user;
      } else {
        throw new Error("Hubo un error!");
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
// Only for student/parent
export const newAccount = createAsyncThunk(
  "user/newaccount",
  async (userData, thunkAPI) => {
    try {
      const response = await Axios.post("/auth/signup_temp_user", userData);
      let data = response.data;
      console.log("new Account", data);
      if (response.status === 200) {
        localStorage.setItem("token", JSON.stringify(data.token));
        return data.user;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const newTempTutorAccount = createAsyncThunk(
  "user/newTempTutorAccount",
  async (userData, thunkAPI) => {
    try {
      const response = await Axios.post("/tutor/temp", userData);
      let data = response.data;
      console.log("new Account", data);
      if (response.status === 200) {
        localStorage.setItem("token", JSON.stringify(data.token));
        return data.user;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const RegisterTemp = createAsyncThunk(
  "user/register",
  async ({ email, password, joinFamilyId, wantNews }, thunkAPI) => {
    try {
      const response = await Axios.post("/auth/create_temp_user", {
        email,
        password,
        join_family_id: joinFamilyId,
      });
      let data = { ...response.data.user, joinFamilyId, wantNews };
      console.log("register", data);
      if (response.status === 200) {
        return data;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
export const RegisterTempUsingGoogle = createAsyncThunk(
  "user/register",
  async (googleResponse, thunkAPI) => {
    try {
      const response = await Axios.post("/auth/create_temp_user_using_google", {
        code: googleResponse.code,
      });
      let data = { ...response.data.user, joinFamilyId: "", wantNews: true };
      console.log("register", data);
      if (response.status === 200) {
        return data;
      } else {
        throw new Error(data.message);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: null,
    isFetching: false,
    isSuccess: false,
    isLogged: false,
    isError: false,
    isNewRegister: false,
    isNewAccount: false,
    errorMessage: "",
  },
  reducers: {
    clearState(state, action) {
      state.isFetching = false;
      state.isError = false;
      state.isSuccess = false;
      state.isLogged = false;
      state.isNewAccount = false;
      state.isNewRegister = false;
      state.errorMessage = "";
    },

    logout(state, action) {
      state.userInfo = null;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      console.log("login rejected ", payload);
      state.isFetching = false;
      state.isError = true;
      state.isLogged = false;
      state.isSuccess = false;
      state.errorMessage = payload.message;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      console.log("payload");
      state.userInfo = payload;
      state.isFetching = false;
      state.isLogged = true;
      state.isSuccess = true;
      return state;
    },
    [loginUserGoogle.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUserGoogle.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isLogged = false;
      state.isSuccess = false;
      state.errorMessage = payload.message;
    },
    [loginUserGoogle.fulfilled]: (state, { payload }) => {
      state.userInfo = payload;
      state.isFetching = false;
      state.isLogged = true;
      state.isSuccess = true;
      return state;
    },
    // fetchUser on page refresh if token is in localstorage
    [fetchUser.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload;
    },
    [fetchUser.fulfilled]: (state, { payload }) => {
      state.userInfo = payload;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [RegisterTemp.pending]: (state) => {
      state.isFetching = true;
    },
    [RegisterTemp.rejected]: (state, { payload }) => {
      console.log("login rejected ", payload);
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.isNewRegister = false;
      state.errorMessage = payload.message;
    },
    [RegisterTemp.fulfilled]: (state, { payload }) => {
      console.log("payload");
      state.userInfo = payload;
      state.isFetching = false;
      state.isNewRegister = true;
      state.isSuccess = true;
      return state;
    },
    [RegisterTempUsingGoogle.pending]: (state) => {
      state.isFetching = true;
    },
    [RegisterTempUsingGoogle.rejected]: (state, { payload }) => {
      console.log("login rejected ", payload);
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.isNewRegister = false;
      state.errorMessage = payload.message;
    },
    [RegisterTempUsingGoogle.fulfilled]: (state, { payload }) => {
      console.log("payload");
      state.userInfo = payload;
      state.isFetching = false;
      state.isNewRegister = true;
      state.isSuccess = true;
      return state;
    },
    // newAccount
    [newAccount.pending]: (state) => {
      state.isFetching = true;
    },
    [newAccount.rejected]: (state, { payload }) => {
      console.log("login rejected ", payload);
      state.isFetching = false;
      state.isError = true;
      state.isNewAccount = false;
      state.isSuccess = false;
      state.errorMessage = payload.message;
    },
    [newAccount.fulfilled]: (state, { payload }) => {
      console.log("payload");
      state.userInfo = payload;
      state.isFetching = false;
      state.isNewAccount = true;
      state.isSuccess = true;
      return state;
    },
  },
});

export const { clearState, logout } = userSlice.actions;
export default userSlice.reducer;
