import React from "react";
import {
  Stack,
  VStack,
  HStack,
  Image,
  Flex,
  SimpleGrid,
  Divider,
  Text,
  Avatar,
  AvatarBadge,
  Box,
  Button,
  WrapItem,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Images } from "../../utils";
function Cards(props) {
  return (
    <HStack display={"flex"} gap={2}>
      <Image src={props.address} alt="..." />
      <Text fontSize={{ base: "md", md: "xl" }}>{props.name}</Text>
    </HStack>
  );
}
const UniversityComponent = () => {
  return (
    <Flex
      //   marginBottom={80}
      width={"100%"}
      direction={{ base: "column", lg: "row" }}
      gap={5}
    >
      <Stack
        backgroundColor={"#ffffff"}
        flex={1}
        rounded={"xl"}
        shadow={"md"}
        bgColor={"white"}
        p={6}
      >
        <HStack justify={"space-between"} alignItems={"flex-start"}>
          <Stack>
            <Link to="/recursos/ib">
              <Text
                fontSize={"3xl"}
                fontWeight={500}
                style={{ textDecorationLine: "underline" }}
              >
                Bachillerato Internacional
              </Text>
            </Link>
            <Text fontSize={"2xl"}>Guía obligatoria para entender el IB</Text>
          </Stack>
          <Image src={Images.BACHILLERATO_IMAGES} alt="..." />
        </HStack>
        <Divider />
        <VStack p={2} alignItems={"flex-start"} width={"100%"}>
          <SimpleGrid columns={2} spacing={10}>
            <Cards name="IB Historia" address={Images.RESOURCE_BOOK} />
            <Cards name="IB Química" address={Images.RESOURCE_XYZ} />
            <Cards name="IB Biología" address={Images.RESOURCE_LEAF} />
            <Cards name="IB Inglés" address={Images.RESOURCE_EARTH} />
            <Cards name="IB Literatura" address={Images.RESOURCE_BOOK} />
            <Cards name="IB Física" address={Images.RESOURCE_STAR} />
            <Cards name="IB TOK" address={Images.RESOURCE_BRAIN} />
          </SimpleGrid>
        </VStack>
      </Stack>
      {/*  */}
      <Stack
        flex={1.3}
        backgroundColor={"#ffffff"}
        rounded={"xl"}
        shadow={"md"}
        bgColor={"white"}
        p={6}
      >
        <HStack justify={"space-between"} alignItems={"flex-start"}>
          <Stack>
            <Link to="/recursors/universidades">
              <Text
                fontSize={"3xl"}
                fontWeight={500}
                style={{ textDecorationLine: "underline" }}
              >
                Universidades americanas
              </Text>
            </Link>
            <Text fontSize={"2xl"}>Guía para universidades americanas</Text>
          </Stack>
          <Image src={Images.UNIVERSIDADES_IMAGES} alt="..." />
        </HStack>
        <Divider />
        <VStack p={2}>
          <SimpleGrid columns={2} spacing={10}>
            <Cards name="Ensayos personales" address={Images.RESOURCE_EDIT} />
            <Cards
              name="Actividades extracurriculares"
              address={Images.RESOURCE_SAKET}
            />
            <Cards name="Exámenes de entrada" address={Images.RESOURCE_100} />
            <Cards
              name="Cartas de recomendación"
              address={Images.RESOURCE_MAIL}
            />
            <Cards
              name="Becas y ayuda financiera"
              address={Images.RESOURCE_DOLLAR}
            />
            <Cards
              name="Lista de universidades"
              address={Images.RESOURCE_CAP}
            />
          </SimpleGrid>
        </VStack>
      </Stack>
    </Flex>
  );
};

export default UniversityComponent;
