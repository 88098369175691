import { Container } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { useState } from "react";
import { Text } from "@chakra-ui/react";

const SelectedComponent = ({
  dataOptions,
  setSelectedOptions,
  value,
  placeholder,
  loading,
  style,
}) => {
  const [groupedOptions, setGroupedOptions] = useState(dataOptions);

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };
  if (loading) {
    return <Text> Cargando... </Text>;
  }
  return (
    <Container
      zIndex={10}
      cursor={"pointer"}
      width={"100%"}
      padding={0}
      {...style}
    >
      <Select
        // name={`jfjfjfj  ${Value} `}
        value={value}
        options={groupedOptions}
        onChange={handleSelectChange}
        placeholder={placeholder}
        closeMenuOnSelect={true}
        size="md"
        // value="xs"
      />
    </Container>
  );
};

export default SelectedComponent;
