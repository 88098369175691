import { FunctionsSharp } from "@mui/icons-material";
import axios from "axios";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const Axios = axios.create({
  baseURL: isLocalhost
    ? "http://localhost:4000"
    : process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export async function getAllSchool(code) {
  try {
    const response = await Axios.get(
      `/school/all?filter={"country":"${code}"}`
    );
    const data = response.data?.map((item) => {
      return {
        label: item.name,
        value: item?._id,
        id: item?._id,
      };
    });
    return [
      ...data,
      { label: "Mi colegio no aparece", value: "Otro", id: "Otro" },
    ];
  } catch (err) {
    console.log(err);
  }
}
export async function getSubjects(service) {
  try {
    // const response = await Axios.get(
    //   `/subject/all?filter={"status":"Activo"}&selection={}`
    // );
    let filter = { status: "Activo" };
    let selection = {};
    const response = await Axios.get("/subject/all", {
      filter,
      selection,
    });
    const data = response.data?.map((item) => {
      return {
        label: item.name,
        value: item?._id,
        id: item?._id,
      };
    });
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function getFilteredSubjects(filter = {}, selection = {}) {
  let params = { filter, selection };
  try {
    const response = await Axios.get(`/subject/all`, { params });
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    console.log(err);
  }
}
export async function getRoomsForUser(userId) {
  try {
    const response = await Axios.get("/chat/rooms", { params: { userId } });
    // console.log(response);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
}
export async function getMessagesForRoom(roomId) {
  try {
    const response = await Axios.get("/chat/messages", { params: { roomId } });
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
}

export async function sendInviteToFamily({ sender, email }) {
  try {
    const response = await Axios.post("/family/send_email_invite_to_family", {
      sender,
      email,
    });
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getFamilyInfo(id) {
  try {
    const response = await Axios.get(`/family?id=${id}`);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getFamilies(filter = {}, selection = {}) {
  try {
    let params = { filter, selection };
    const response = await Axios.get(`/family/all`, { params });
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getTutorDetail(id) {
  try {
    const response = await Axios.get(`/tutor/?id=${id}`);
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    return err;
  }
}
export async function getTutors(filter = {}, selection = {}) {
  try {
    let params = { filter, selection };

    const response = await Axios.get(`/tutor/all`, { params });
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    return err;
  }
}

export async function confirmCalendarUpdate(tutorId) {
  try {
    let response = await Axios.patch(`/tutor`, {
      id: tutorId,
      update: {
        lastCalendarUpdate: Date.now(),
        status: "Activo", // If they do this they are considered active
      },
    });

    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    return err;
  }
}

// axios
//   .patch(`${process.env.REACT_APP_BACKENDURL}/tutor/publicprofile`, formData)
//   .then((response) => window.location.reload(false))
//   .catch((err) => alert(err.response.data.message));

// let formData = {
//   id: userInfo._id,
//   tutorDescriptionList: tutorDescriptionList,
//   tutorSelfDescription: tutorSelfDescription,
// };
// if (selectedFile) {
//   formData.photoObject = selectedFile;
// }

export async function updatePublicTutorProfile(
  data, //string -> string
  tutorDescriptionList, // array
  photoObject //File
) {
  try {
    const formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    formData.append(
      "tutorDescriptionList",
      JSON.stringify(tutorDescriptionList)
    );
    if (photoObject) {
      formData.append("photoObject", photoObject);
    }

    // let res = await Axios.patch("/tutor/publicprofile", data);
    // Not using Axios as that one only accepts JSON, not files
    let res = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/tutor/publicprofile`,
      formData
    );
    console.log(res);
    if (res.status !== 200) {
      return { success: false, data: res };
    }
    return { success: true, data: res.data };
  } catch (err) {
    return { success: false, data: err };
  }
}

export async function getParent(id) {
  try {
    const response = await Axios.get(`/parent/?id=${id}`);
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    return err;
  }
}
export async function getStudent(id) {
  try {
    const response = await Axios.get(`/student/?id=${id}`);
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    return err;
  }
}
export async function getStudents(filter = {}, selection = {}) {
  try {
    let params = { filter, selection };
    const response = await Axios.get(`/student/all/`, { params });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (err) {
    return err;
  }
}
export async function getSubjectById(id) {
  try {
    const response = await Axios.get(`/subject/?id=${id}`);
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    return err;
  }
}
export async function createNewClass(newClass) {
  try {
    const response = await Axios.post("/class/newclass", newClass);
    if (response.status !== 200) {
      return { success: false, data: response };
    }
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, data: err };
  }
}
export async function getClasses(
  filter,
  selection,
  populate,
  invert_order = false
) {
  try {
    let params = { filter, selection, populate, invert_order };
    const response = await Axios.get("/class/all", { params });
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    return err;
  }
}

export async function getClass(id) {
  try {
    let params = { id: id };
    const response = await Axios.get("/class/", { params });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (err) {
    return err;
  }
}

export async function isValidClass(tutorId, start, end) {
  try {
    let params = { tutorId, start, end };
    const response = await Axios.get("class/isValidClass", { params });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (err) {
    return err;
  }
}
export async function acceptClass(classId) {
  try {
    const response = await Axios.post("class/acceptClass", { classId });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (err) {
    return err;
  }
}
export async function rejectClass(classId, rejectReason) {
  try {
    const response = await Axios.post("class/rejectClass", {
      classId,
      rejectReason,
    });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (err) {
    return err;
  }
}
export async function FetchUniversidad() {
  try {
    const response = await Axios.get(`/school/all?`);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
}
export async function getSchool(id) {
  try {
    let params = { id };
    const response = await Axios.get(`/school/`, { params });
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function CancelClass(id, info = {}) {
  let data = { id, info };
  try {
    console.log(`id to cancel = ${id}`);
    const response = await Axios.delete(`/class/`, {
      data: data,
    });
    console.log(response);
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getTutorFreeEvents(tutor, start, end) {
  try {
    let params = { calendarId: tutor.calendarId, start, end };
    const response = await Axios.get(`/calendar/freeEvents`, { params });
    if (response.status !== 200) {
      return;
    }
    return response;
  } catch (err) {
    console.log(err);
  }
}
/**
 *let formData = {
    hoursBought,
    referralId,
    hourType,
    currency,
    familyId: user.family,
  };
 * @param {*} data 
 */
export async function getSaleAmount(data) {
  try {
    let response = await Axios.get(`/sale/calculate_amount`, {
      params: data,
    });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (e) {
    console.log(e);
    return;
  }
}

export async function getValidNumHours() {
  try {
    let response = await Axios.get(`/sale/get_valid_num_hours`);
    if (response.status !== 200) {
      return { success: false, message: response.data.msg };
    }
    return { success: true, data: response.data };
  } catch (e) {
    console.log(e);
    return;
  }
}

export async function getFamily(id) {
  try {
    let params = { id };
    let response = await Axios.get(`/family/`, { params });
    if (response.status !== 200) {
      return response.data;
    }
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function processPayment(data) {
  try {
    let response = await Axios.post("/mp/process_payment/", data);

    if (response?.data?.status !== "approved") {
      console.log(response);
      return { success: false, data: response.data };
    }

    return { success: true, data: response.data };
  } catch (e) {
    console.log(e);
    return { success: false, error: e };
  }
}

export async function registerSale(data) {
  try {
    let response = await Axios.post("/sale/register_class", data);
    if (response.status !== 200) {
      return { success: false };
    }

    return { success: true, data: response.data };
  } catch (e) {
    console.log(e);
    return { success: false, error: e };
  }
}

export async function updateStudent(id, update) {
  try {
    let response = await Axios.patch("/student/", { id, update });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function updateParent(id, update) {
  try {
    let response = await Axios.patch("/parent/", { id, update });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
}
// The `updateReason` method is often used for when admin makes changes
export async function updateTutor(id, update, updateReason = "") {
  try {
    let response = await Axios.patch("/tutor/", { id, update, updateReason });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
}

export async function getFamilyHistory(familyId) {
  try {
    let params = { familyId };
    let response = await Axios.get("/history/family", { params });
    if (response.status !== 200) {
      return;
    }
    return response.data;
  } catch (e) {
    console.log(e);
    return e;
  }
}

/**
 *
 * @param {*} data should contain id (id of class), rating (number), newStudentFeedback (encoded text), sender: sender (user id)
 * @returns
 */
export async function sendStudentFeedback(data) {
  try {
    let response = await Axios.patch("/class/studentfeedback", data);
    if (response.status !== 200) {
      return { success: false, response: response };
    }
    return { success: true, data: response.data };
  } catch (e) {
    console.log(e);
    return { succcess: false, error: e };
  }
}

export async function sendTutorFeedback(classId, tutorFeedback) {
  try {
    let data = {
      id: classId,
      update: {
        tutorFeedback: tutorFeedback,
      },
    };
    let response = await Axios.patch("/class/tutorFeedback", data);
    if (response.status !== 200) {
      return { success: false, response: response };
    }
    return { success: true, data: response.data };
  } catch (e) {
    console.log(e);
    return { succcess: false, error: e };
  }
}

export async function sendGoogleLoginToServer(googleResponse) {
  try {
    let serverResponse = await Axios.post(`/auth/googleLogin`, {
      code: googleResponse.code,
    });
    return serverResponse?.data;

    localStorage.setItem(
      "googleRefreshToken",
      serverResponse.data.refresh_token
    );

    return { success: true, user: serverResponse.data.user };
  } catch (e) {
    return { success: false, error: e };
  }

  //   .then(response => {
  //     localStorage.setItem('googleRefreshToken',response.data.refresh_token)
  //     dispatch(setUser(response.data.user))
  //     if (response.data.user.role == "Tutor" && response.data.user.calendarId == ""){
  //       navigate("/createcalendar")
  //     }else{
  //       navigate("/")
  //     }
  // }).catch(error => {
  //   setError(error.response.data.message)
  //   setLoading(false)
  // })
}

export async function getStrikes(filter = {}, selection = {}) {
  let params = { filter, selection };
  const response = await Axios.get("/strike/all", { params });
  return response.data;

  // try {
  //   const response = await Axios.get("/strike/all", { params });
  //   if (response.status !== 200) {
  //     console.error(response);
  //     return;
  //   }
  //   return response.data;
  // } catch (err) {
  //   console.error(err);
  //   return;
  // }
}

export async function sendResetPasswordEmail(email) {
  let response = await Axios.post(`/auth/checkemail`, { email });
  return response.data;
}

export async function resetPassword(token, password) {
  let response = await Axios.post(`/auth/newpassword`, { password, token });
  return response.data;
}
// export async function recordEnteredClassroom(classId, userId) {
//   let update = {
//     $addToSet: { enteredClassroom: userId },
//   };
//   let response = await Axios.patch("/class", { id: classId, update: update });
//   return response?.data;
// }

export async function notifyAdminNoStudents(classId, userId) {
  await Axios.post("/class/notifyAdminNoStudents", { classId, userId });
}
export async function notifyAdminNoTutor(classId, userId) {
  await Axios.post("/class/notifyAdminNoTutor", { classId, userId });
}
