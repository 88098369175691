import React from "react";
// import { CalendarModal, ReportClassModal } from "../../components";
import CalendarModal from "../../components/CalendarModal/CalendarModal";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import {
  Button,
  Text,
  useDisclosure,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Avatar,
  AvatarBadge,
  Box,
  WrapItem,
} from "@chakra-ui/react";
import { CANCEL_CLASS_COLOR } from "../../utils/constants";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
} from "../../utils/functions.js";
import { useSelector } from "react-redux";
import CancelClassModal from "./CancelClassModal";

const PendingClassModal = ({ event }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const ModalData1 = {
    header: "¿Seguro que deseas cancelar esta clase?",
    className: "Matemáticas IB en 2 meses",
    tutor: "con Valentín Quezada",
    timing: "Viernes 11 mar, 11:00 - 12:00 hrs.",
  };
  const ModalData2 = {
    header: "Clase cancelada",
    className: "Matemáticas IB en 2 meses",
    tutor: "con Valentín Quezada",
    timing: "Viernes 11 mar, 11:00 - 12:00 hrs.",
  };

  const classCancelHandler = () => {
    onClose();
    setTimeout(() => {
      onOpen1();
    }, 600);
  };
  const { userInfo } = useSelector((state) => state.user);

  return (
    <>
      {/* <Stack direction={"col"} gap={3}> */}

      <Text fontSize="md" margin={3}>
        El tutor aún necesita confirmar la clase.
      </Text>
      <Button
        backgroundColor={CANCEL_CLASS_COLOR}
        color={"white"}
        onClick={onOpen}
      >
        <Text>Cancelar clase</Text>
      </Button>

      {/* <Button backgroundColor={"#0ED37B"} color={"white"} onClick={onOpen2}>
          <Text>Report Class Modal</Text>
        </Button> */}
      {/* </Stack> */}
      <CancelClassModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        classId={event.extra.class._id}
        tutorName={event.extra.info.tutorInfo.name}
        tutorImage={getValidDriveLink(
          event.extra.info.tutorInfo.imageDriveLink
        )}
        classTitle={event.extra.info.subjectDescription}
        classDescription={event.extra.info.timeDescription}
        isTutor={false}
        classInfo={event.extra.class}
      />

      {/* <CalendarModal
        classCancelHandler={classCancelHandler}
        classCancel={false}
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        modalData={{
          header: "¿Seguro que deseas cancelar esta clase?",
          className: event.title,
          tutor: "",
          timing: `${getDayInfo(event.start)},${getHourAndMinutes(
            event.start
          )} - ${getHourAndMinutes(event.end)}`,
        }}
      />
      <CalendarModal
        classCancel={true}
        modalData={ModalData2}
        onOpen={onOpen1}
        isOpen={isOpen1}
        onClose={onClose1}
      />
      <ReportClassModal onOpen={onOpen2} isOpen={isOpen2} onClose={onClose2} /> */}
    </>
  );
};

export default PendingClassModal;
