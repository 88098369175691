import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  WrapItem,
} from "@chakra-ui/react";
import React from "react";
import { Images } from "../../utils";
import { MAIN_COLOR } from "../../utils/constants";

const ReportClassModal = ({ isOpen, onClose }) => {
  const ReportmodelData = [
    {
      question: `¿Qué temas se estudiaron y qué temas se estudiarán?`,
      answer: `Lörem ipsum hål prerade. Viren teletris. Siböhet tetrast och
      hödos och aspludd.`,
    },
    {
      question: `¿Se hicieron las tareas?`,
      answer: `Lörem ipsum hål prerade.`,
    },
    {
      question: `¿Qué nuevas tareas se le ha dejado al estudiante?`,
      answer: `Lörem ipsum huvåra makyling revis, så hirat. Satt prarade oruktiga. Termosocial dikånar, mobbning. Monominera.`,
    },
    {
      question: `¿Qué se le ha dado bien al estudiante? ¿Y en qué cosas puede mejorar?`,
      answer: `Lörem ipsum huvåra makyling revis, så hirat. Satt prarade oruktiga. Termosocial dikånar, mobbning. Monominera.`,
    },
  ];
  return (
    <>
      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          onClick={onClose}
          backgroundColor={"rgba(65, 65, 65, 0.3)"}
        />
        <ModalContent p={5}>
          <ModalHeader fontSize={"2xl"} alignSelf={"center"}>
            Reporte de clase
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              display={"flex"}
              gap={4}
              justifyContent={"center"}
              flexDir={{ base: "column", md: "row" }}
            >
              <Box width={{ base: "100%", md: "initial" }} display={"flex"}>
                <WrapItem position={"relative"}>
                  <Avatar
                    zIndex={1}
                    border={" 3.64583px solid rgba(68, 68, 68, 0.25)"}
                    size={"lg"}
                    name="Segun Adebayo"
                    src="https://bit.ly/dan-abramov"
                  ></Avatar>

                  <Avatar
                    bottom={2}
                    right={0}
                    zIndex={10}
                    position={"absolute"}
                    size={"xs"}
                    borderRadius={"50%"}
                    src={Images.IB_IMAGE}
                  ></Avatar>
                </WrapItem>
              </Box>
              <Box>
                <Text fontSize={"lg"} fontWeight={600}>
                  Matemáticas IB en 2 meses
                </Text>
                <Text fontSize={"sm"} fontWeight={500}>
                  con Valentín Quezada
                </Text>
                <Text fontSize={"lg"} color={MAIN_COLOR} fontWeight={600}>
                  Viernes 11 mar, 11:00 - 12:00 hrs.
                </Text>
              </Box>
            </Box>
            <VStack
              p={5}
              gap={5}
              marginTop={5}
              borderRadius={12}
              alignItems={"flex-start"}
              overflowY={"scroll"}
              scrollBehavior={"smooth"}
              backgroundColor={"#4444441A"}
              maxH={"50vh"}
            >
              {ReportmodelData.map((item, index) => {
                return (
                  <Box
                    key={`item-${index}`}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Text fontSize={"lg"} fontWeight={500}>
                      {item.question}
                    </Text>
                    <Text fontSize={"md"} fontWeight={400}>
                      {item.answer}
                    </Text>
                  </Box>
                );
              })}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportClassModal;
