import React, { useState, useEffect } from "react";
import {
  HStack,
  Stack,
  Text,
  Avatar,
  AvatarBadge,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  Wrap,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  ChakraProvider,
  Select,
  Spinner,
  Center,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import {
  ChatIcon,
  CloseIcon,
  HamburgerIcon,
  CalendarIcon,
  ExternalLinkIcon,
  CheckIcon,
  InfoOutlineIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import ModalRating from "../../components/ModalRating";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CalendarClasses from "./CalendarClasses.jsx";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@chakra-ui/react";
import SelectStudent from "./SelectStudent";
import {
  getClasses,
  getSubjectById,
  getTutorDetail,
} from "../../utils/ApiConfig.js";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
  getHumanReadableTimeUntil,
  extractMoreInfoFromClass,
} from "../../utils/functions.js";

import CancelClassModal from "./CancelClassModal";
import ListClasses from "./ListClasses";
import ClassWithCountdown from "./ClassWithCountdown";
import { MAIN_COLOR } from "../../utils/constants";

// import Schedule from '../components/Calendar/Schedule'

const Clases = ({ clases }) => {
  const [pendingClasses, setPendingClasses] = useState([]);
  const [futureClasses, setFutureClasses] = useState([]);
  const [pastClasses, setPastClasses] = useState([]);

  const [list, setList] = useState(true);
  const [calen, setCalen] = useState(false);
  const [width] = useMediaQuery("(max-width: 650px)");
  const [currentStudent, setCurrentStudent] = useState({}); // gives the object of current selected child in parent view
  const { userInfo } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const getOwnClasses = async (params, invert_order = false) => {
    if (!currentStudent?._id) {
      return [];
    }
    let res;
    if (!invert_order) {
      res = await getClasses({
        studentIds: currentStudent._id,
        ...params,
      });
    } else {
      res = await getClasses(
        {
          studentIds: currentStudent._id,
          ...params,
        },
        {},
        [],
        true
      );
    }

    return res ? res.data : [];
  };
  useEffect(() => {
    if (!userInfo) {
      return;
    }
    if (userInfo.role === "Student") {
      setCurrentStudent(userInfo);
    }
  }, [userInfo]);
  useEffect(() => {
    // if(lista === true) {}

    setPendingClasses([]);
    setFutureClasses([]);
    setPastClasses([]);
    if (!currentStudent) {
      return;
    }
    setLoading(true);

    const updateDataFromDb = async () => {
      let today = new Date();
      let pending = await getOwnClasses({
        pending: true,
        end: { $gte: today }, //has not ended and it's pending
      });
      pending = await Promise.all(
        pending.map((pendingClass) =>
          extractMoreInfoFromClass(pendingClass, "pending")
        )
      );
      let future = await getOwnClasses({
        pending: false,
        end: { $gte: today }, //hasn't ended yet
      });
      future = await Promise.all(
        future.map((futureClass) =>
          extractMoreInfoFromClass(futureClass, "future")
        )
      );
      let past = await getOwnClasses(
        { pending: false, end: { $lt: today } }, //has ended
        true
      );
      past = await Promise.all(
        past.map((pastClass) => extractMoreInfoFromClass(pastClass, "past"))
      );
      console.log("pendings");
      console.log(pending);
      console.log("future");
      console.log(future);
      console.log("past");
      console.log(past);
      setPendingClasses(pending);
      setFutureClasses(future);
      setPastClasses(past);

      setLoading(false);
    };
    updateDataFromDb();
  }, [currentStudent]); // Load when user is logged in

  const onListClick = () => {
    setList(true);
    setCalen(false);
  };
  const onCalendarClick = () => {
    setCalen(true);
    setList(false);
  };
  if (!userInfo?._id) {
    return <></>;
  }
  const ROLE_TO_MESSAGE = {
    your_classes: {
      Student: "Tus clases",
      Parent: "Las clases de tus hijos",
    },
    has: {
      Student: "tienes",
      Parent: "tiene",
    },
  };
  return (
    <div style={{ padding: 30 }}>
      {/* // <div> */}
      <ChakraProvider>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize={"150%"} fontWeight={500}>
            {ROLE_TO_MESSAGE.your_classes[userInfo.role]}
          </Text>
          {(userInfo.role === "Student" || currentStudent._id) && (
            <Text fontSize="lg" as="u">
              {userInfo.role === "Student"
                ? "Haz"
                : `${currentStudent.name} ha`}{" "}
              tomado{" "}
              <Text fontWeight={"bold"} as="span">
                {pastClasses.length}
              </Text>{" "}
              clases, y {ROLE_TO_MESSAGE.has[userInfo.role]}{" "}
              <Text fontWeight={"bold"} as="span">
                {futureClasses.length + pendingClasses.length}
              </Text>{" "}
              por venir!
            </Text>
          )}
        </Flex>
        {userInfo?.role === "Parent" && (
          <>
            <SelectStudent
              setCurrentStudent={setCurrentStudent}
              currentStudent={currentStudent}
              disabled={!list} //Once in calendar mode disable this because chaing it won't update the calendar
            />
            <Divider marginBottom={"lg"} />
          </>
        )}
        {loading ? (
          <Center spacing={4}>
            <Spinner boxSize={24} />
            <Text fontSize="lg" ml={3}>
              Cargando ...
            </Text>
          </Center>
        ) : !currentStudent._id ? (
          <></>
        ) : (
          <>
            {futureClasses.length > 0 && (
              <ClassWithCountdown
                // link={futureClasses[0].length}
                // name="Matemáticas IB en 2 meses"
                name={futureClasses[0].info.subjectDescription}
                schedule={futureClasses[0].info.timeDescription}
                tutorName={futureClasses[0].info.tutorInfo.name}
                participantMessage={futureClasses[0].info.participantMessage}
                tutorImage={getValidDriveLink(
                  futureClasses[0].info.tutorInfo.imageDriveLink
                )}
                startTime={futureClasses[0].class.start}
                endTime={futureClasses[0].class.end}
                classId={futureClasses[0].class._id}
                isParent={userInfo.role === "Parent"}
                // schedule="Jueves 09 de marzo a las 15:00 hrs."
              />
            )}
            {/* {
          futureClasses.length === 0 &&
        } */}
            <HStack
              borderBottom={"0.7px solid black"}
              paddingTop={7}
              gap={5}
              justifyContent={"start"}
            >
              <Stack>
                <Text
                  paddingBottom={"10px"}
                  // borderBottom={list ? "2px solid #07A58E" : ""}
                  borderBottom={list ? `2px solid ${MAIN_COLOR}` : ""}
                  // color={list ? "#07A58E" : "black"}
                  color={list ? MAIN_COLOR : "black"}
                  cursor={"pointer"}
                  fontWeight={500}
                  fontSize={"150%"}
                  onClick={onListClick}
                >
                  <HamburgerIcon /> Lista
                </Text>
              </Stack>
              <Stack>
                <Text
                  paddingBottom={"10px"}
                  // borderBottom={calen ? "2px solid #07A58E" : ""}
                  borderBottom={list ? "" : `2px solid ${MAIN_COLOR}`}
                  cursor={"pointer"}
                  fontWeight={500}
                  fontSize={"150%"}
                  color={list ? "black" : MAIN_COLOR}
                  // color={list ? "black" : "#07A58E"}
                  onClick={onCalendarClick}
                >
                  <CalendarIcon /> Calendario
                </Text>
              </Stack>
            </HStack>
            {list && (
              <ListClasses
                pendingClasses={pendingClasses}
                futureClasses={futureClasses}
                pastClasses={pastClasses}
                width={width}
              />
            )}
          </>
        )}
      </ChakraProvider>
      {/* //The calendar cannot be wrapped in ChakraProvider */}
      {!list && (
        <CalendarClasses
          pendingClasses={pendingClasses}
          futureClasses={futureClasses}
          pastClasses={pastClasses}
          width={width}
        />
      )}
    </div>
  );
};

export default Clases;
