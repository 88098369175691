import sc from "states-cities-db";

const COUNTRIES = sc.getCountries();

const getCountryTelCode = (country) =>
  country && COUNTRIES.find(({ iso }) => iso === country).prefix;
const getCountryIso2 = (country) =>
  country && COUNTRIES.find(({ iso }) => iso === country).iso2;

export { COUNTRIES, getCountryTelCode, getCountryIso2 };
