import moment from "moment";
import "moment/locale/es";
import {
  getClasses,
  getTutorFreeEvents,
  getTutorDetail,
  getSubjectById,
  getStudents,
} from "./ApiConfig";
import {
  PENDING_CLASS_COLOR,
  PAST_CLASS_COLOR,
  CONFIRMED_CLASS_COLOR,
} from "./constants";

export function validateEmail(value) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!regex.test(value)) {
    return false;
  }
  return true;
}

export const convertTime = (dateString) => {
  const date = moment.utc(dateString).local();
  const now = moment();
  const diffInDays = now.diff(date, "days");

  if (diffInDays === 0) {
    return date.format("h:mm a");
  } else if (diffInDays === 1) {
    return "Yesterday";
  } else {
    return date.format("M/D/YYYY");
  }
};

export function getTime(timestamp) {
  try {
    const date = moment(timestamp);
    const formattedTime = date.format("h:mm A");
    return formattedTime;
  } catch (error) {
    console.error("Error:", error.message);
  }
}
export function getMonthYearName(timestamp) {
  const date = moment(timestamp);
  date.locale("es");
  return capitalize(date.format("MMMM YYYY"));
}
const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export const getDayInfo = (timestamp) => {
  try {
    const date = moment(timestamp);
    date.locale("es");
    const formattedDate = date.format("dddd D [de] MMMM");
    // const capitalizedDate = formattedTime.replace(/\b\w/g, (match) => match.toUpperCase());
    const capitalizedDate = capitalize(formattedDate);
    return capitalizedDate;
  } catch (error) {
    console.error("Error:", error.message);
  }
};
export const getHourAndMinutes = (timestamp) => {
  try {
    const date = moment(timestamp);
    const formattedTime = date.format("HH:mm");
    return formattedTime;
  } catch (error) {
    console.error("Error:", error.message);
  }
};

const getStringBetween = (s, a, b) => {
  let begin = s.indexOf(a) + a.length;
  let end = s.indexOf(b);
  return s.substring(begin, end);
};

export const getValidDriveLink = (link) => {
  if (!link) {
    return "";
  }
  try {
    let id = getStringBetween(link, "/file/d/", "/view?");
    let driveLink = `https://drive.google.com/uc?export=view&id=${id}`;
    // console.log(`Final driveLink:`);
    // console.log(driveLink);
    return driveLink;
  } catch (err) {
    console.log(`Error: ${err}`);
    return "TUTOR_NO_PIC_LINK";
  }
};
function getYouTubeId(url) {
  const urlObject = new URL(url);
  const host = urlObject.host;
  let videoId = null;

  if (host === "www.youtube.com" || host === "youtube.com") {
    const searchParams = new URLSearchParams(urlObject.search);
    if (searchParams.has("v")) {
      videoId = searchParams.get("v");
    }
  } else if (host === "youtu.be") {
    const path = urlObject.pathname;
    videoId = path.substr(1);
  }

  return videoId;
}
export const getYoutubeEmbedLink = (url) => {
  if (!url) {
    return "";
  }
  try {
    let id = getYouTubeId(url); // url.split("v=")[1].split("&")[0];
    if (!id) {
      return "";
    }
    let embed_url = `https://www.youtube.com/embed/${id}`;
    return embed_url;
  } catch (err) {
    return "";
  }
};
export function getHumanReadableTimeUntil(timestamp) {
  const currentTimestamp = new Date().getTime() / 1000;
  const futureTimestamp = new Date(timestamp).getTime() / 1000;
  const timeDifference = futureTimestamp - currentTimestamp;

  if (timeDifference < 0) {
    return "Ahora mismo";
  }

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;

  if (timeDifference < minute) {
    const seconds = Math.round(timeDifference);
    return `${seconds} segundo${seconds !== 1 ? "s" : ""}`;
  } else if (timeDifference < hour) {
    const minutes = Math.round(timeDifference / minute);
    return `${minutes} minuto${minutes !== 1 ? "s" : ""}`;
  } else if (timeDifference < day) {
    const hours = Math.round(timeDifference / hour);
    return `${hours} hora${hours !== 1 ? "s" : ""}`;
  } else if (timeDifference < month) {
    const days = Math.round(timeDifference / day);
    return `${days} día${days !== 1 ? "s" : ""}`;
  } else {
    const months = Math.round(timeDifference / month);
    return `${months} ${months !== 1 ? "meses" : "mes"}`;
  }
}
export const extractMoreInfoFromClass = async (
  currClass,
  classType,
  isTutor = false
) => {
  let tutor;
  if (currClass.tutorInfo?._id) {
    tutor = currClass.tutorInfo;
  } else {
    tutor = await getTutorDetail(currClass.tutorId);
    tutor = tutor.data;
    tutor = {
      _id: tutor._id,
      name: tutor.name,
      imageDriveLink: tutor.imageDriveLink,
    };
  }
  let subject;
  if (currClass.subjectInfo?._id) {
    subject = currClass.subjectInfo;
  } else {
    subject = await getSubjectById(currClass.subjectId);
    subject = subject.data;
    subject = {
      _id: subject._id,
      name: subject.name,
    };
  }
  let studentsInfo;
  if (currClass.studentsInfo && currClass.studentsInfo?.length > 0) {
    studentsInfo = currClass.studentsInfo;
  } else {
    let students = await getStudents({ _id: { $in: currClass.studentIds } });
    studentsInfo = students.map((student) => ({
      _id: student._id,
      name: student.name,
    }));
  }
  let participantMessage;
  if (isTutor) {
    participantMessage = studentsInfo.map((student) => student.name).join(" ");
  } else {
    participantMessage = `${tutor.name}`;
  }
  // General info
  let info = {
    type: classType,
    tutorInfo: tutor,
    subjectInfo: subject,
    studentsInfo: studentsInfo,
    subjectDescription: subject.name,
    participantMessage: participantMessage,
    timeDescription: `${getDayInfo(currClass.start)}, ${getHourAndMinutes(
      currClass.start
    )} - ${getHourAndMinutes(currClass.end)}`,
  };
  let TYPE_TO_COLOR = {
    pending: PENDING_CLASS_COLOR,
    past: PAST_CLASS_COLOR,
    future: CONFIRMED_CLASS_COLOR,
  };
  //Calendar-specific info
  let calendarInfo = {
    color: TYPE_TO_COLOR[classType],
    event_id: currClass._id,
    editable: false,
    deletable: false,
    draggable: false,
  };
  //List-specific info
  let listInfo = {};
  return {
    class: currClass,
    info: info,
    calendar: calendarInfo,
    list: listInfo,
  };
};
export const getHoursFromFamily = (family) => {
  let hoursIB = family.hoursIB || 0;
  let hoursIBGroup = family.hoursIBGroup || 0;
  let hoursSAT = family.hoursSAT || 0;
  let hoursSATGroup = family.hoursSATGroup || 0;
  let hoursTutoring = family.hoursTutoring || 0;
  let hoursTutoringGroup = family.hoursTutoringGroup || 0;
  let hoursOlympiad = family.hoursOlympiad || 0;
  let hoursOlympiadGroup = family.hoursOlympiadGroup || 0;
  return (
    hoursIB +
    hoursIBGroup +
    hoursSAT +
    hoursSATGroup +
    hoursTutoring +
    hoursTutoringGroup +
    hoursOlympiad +
    hoursOlympiadGroup
  );
};
export const getHourParts = (family) => {
  let hoursIB = family.hoursIB || 0;
  let hoursIBGroup = family.hoursIBGroup || 0;
  let hoursSAT = family.hoursSAT || 0;
  let hoursSATGroup = family.hoursSATGroup || 0;
  let hoursTutoring = family.hoursTutoring || 0;
  let hoursTutoringGroup = family.hoursTutoringGroup || 0;
  let hoursOlympiad = family.hoursOlympiad || 0;
  let hoursOlympiadGroup = family.hoursOlympiadGroup || 0;

  return {
    tutoring: hoursTutoring,
    tutoringGroup: hoursTutoringGroup,
    specialized: hoursIB + hoursSAT + hoursOlympiad,
    specializedGroup: hoursIBGroup + hoursSATGroup + hoursOlympiadGroup,
  };
};
/**
 * Replaces [colon] and [dash] with : and -
 * @param {} feedback
 */
export const formatFeedback = (feedback) => {
  return feedback.replace(/\[colon\]/g, ":").replace(/\[dash\]/g, "-");
};

/**
 * Replaces : with [colon] and - with [dash]
 * @param {*} feedback
 * @returns
 */
export const encodeFeedback = (feedback) => {
  return feedback.replace(/:/g, "[colon]").replace(/-/g, "[dash]");
};
/**
 *
 * @param {*} studentFeedbacks Array of {sender: , text: } object
 * @returns
 */
export const formatIndividualStudentFeedback = (studentFeedbacks) => {
  let res = "";
  for (const studentFeedback of studentFeedbacks) {
    res += "Enviado por: " + studentFeedback.sender.name + "\n";
    res += formatFeedback(studentFeedback.text) + "\n";
  }
  return res;
};
/**
 * @param {*} questions
 * @param {*} answers
 */
export const formatTutorFeedback = (questions, answers) => {
  let res = "";
  for (let i = 0; i < questions.length; i++) {
    let question = encodeFeedback(questions[i]);
    let answer = encodeFeedback(answers[i]);

    let line = `-${question}:\n\n${answer}`;
    res += line;
  }
  return res;
};
/*
 Takes an, already encoded feedback and returns an array of [q1, a1], [q2, a2], ...
 for questions and answers. 
*/
export const feedbackToData = (feedback) => {
  console.log(feedback);
  let res = [];
  for (let lines of feedback.split("-")) {
    if (!lines) {
      //Might be empty string
      continue;
    }
    let [question, answer] = lines.split(":");
    console.log(question);
    console.log(answer);
    question = formatFeedback(question);
    answer = formatFeedback(answer);
    res.push([question, answer]);
  }
  return res;
};

const PERU_TIMEZONE = -5; // TODO: What if there are some other timezones?
/**
 *
 * @returns Peruvian time
 */
export const getFirstDayOfMonth = (year, month) => {
  // return new Date(today.getFullYear(), today.getMonth(), 1);
  return new Date(Date.UTC(year, month, 1, -PERU_TIMEZONE, 0, 0));
};
/**
 * Peruvian time
 * @returns
 */
export const getLastDayOfMonth = (year, month) => {
  // return new Date(today.getFullYear(), today.getMonth(), 1);
  return new Date(Date.UTC(year, month + 1, 1, -PERU_TIMEZONE, 0, 0));
};

// Working week = Monday - Sunday
// This function should return a Date with the monday before today (inclusive)
export function getFirstDayOfWorkingWeek() {
  let prevMonday = new Date();
  prevMonday = new Date(
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7))
  );
  return prevMonday;
}
export function moveNumDays(date, numDays, lastHour = true) {
  let prev = new Date(date);
  prev.setDate(prev.getDate() + numDays);
  if (lastHour) {
    prev.setHours(23, 59, 59);
  } else {
    prev.setHours(0, 0, 0);
  }
  return prev;
}

export const isWithinDates = (date, startDate, endDate) => {
  startDate.setHours(0, 0, 0);
  endDate.setHours(23, 59, 59);

  return (
    startDate.getTime() <= date.getTime() && date.getTime() <= endDate.getTime()
  );
};

export const isActiveInWeek = (lastCalendarUpdate, nWeeks) => {
  let first = getFirstDayOfWorkingWeek();
  first.setHours(0, 0, 0);
  first.setDate(first.getDate() - 1); //Get sunday prior
  let firstShow = moveNumDays(first, nWeeks * 7);
  let lastShow = moveNumDays(firstShow, 6);
  lastShow.setHours(23, 59, 59);
  let updatedDate = new Date(Date.parse(lastCalendarUpdate));
  return isWithinDates(updatedDate, firstShow, lastShow);
};

export const formatWorkingWeek = (nWeeks = 0) => {
  let first = getFirstDayOfWorkingWeek();
  let firstShow = moveNumDays(first, nWeeks * 7);
  let lastShow = moveNumDays(firstShow, 6);

  return `${firstShow.toLocaleDateString()} - ${lastShow.toLocaleDateString()}`;
};

export const requiresCalendarUpdate = (lastCalendarUpdate) => {
  if (!lastCalendarUpdate) {
    return true;
  }
  //nWeeks is the number of (working weeks) from now
  // If active week is Monday x - Sunday x+6,
  // then today should be Sunday x-1 to Saturday x+5
  const isActiveInWeekUser = (nWeeks) =>
    isActiveInWeek(lastCalendarUpdate, nWeeks);
  const isSunday = new Date().getDay() === 0;
  const needsCalendarUpdate =
    (isSunday && !isActiveInWeekUser(1)) ||
    (!isSunday && !isActiveInWeekUser(0));

  return needsCalendarUpdate;
};

const EXPIRE_HOURS = 3 * 30 * 24 * 60 * 60 * 1000; //3 months
export const getTimeUntilHoursExpire = (lastTimePurchase) => {
  if (!lastTimePurchase) {
    lastTimePurchase = new Date();
  }

  let purchase = new Date(lastTimePurchase);
  let deadline = new Date(purchase.getTime() + EXPIRE_HOURS);
  const MINUTE = 60;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const MONTH = 30 * DAY;

  let timeDifference = (deadline.getTime() - new Date().getTime()) / 1000;
  timeDifference = Math.round(timeDifference);
  let totalDays = Math.floor(timeDifference / DAY);
  if (totalDays > 0) {
    return `${totalDays} ${totalDays !== 1 ? "días" : "día"}`;
  } else {
    return "menos de un día!";
  }

  // let months = Math.floor(timeDifference / MONTH);
  // let diff = timeDifference - months * MONTH;
  // let days = Math.floor(diff / DAY);

  // if (months > 0) {
  //   return `${months} ${months !== 1 ? "meses" : "mes"} y ${days} ${
  //     days !== 1 ? "días" : "día"
  //   }`;
  // } else {
  //   return `${days} ${days !== 1 ? "días" : "día"}`;
  // }
};
/**
 * Returns a list of the events (to be consudmed by React Scheduler) that are all the blocking events, merged.
 *
 * Al the tutor's or user's classes in that timeframe is blocked. From the rest, block anything that falls outside the
 * tutor's freeEvents. As per React Scheduler, each event should have a title, start, end, color (gray) and the
 * end events should not overlap.
 *
 * Each event should be contained in one day. If one event takes all day then it should have a 'allDay' property
 * equal to true.
 */
export const getBlockingEventsReserve = async (start, end, tutorId, userId) => {
  // This assumes its on week view

  ////
  let today = new Date(start);
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  let todayMorning = new Date(today);
  todayMorning.setHours(0, 0, 0, 0);
  let endWeek = new Date(today);
  endWeek.setDate(today.getDate() + 7);
  endWeek.setHours(0, 0, 0, 0);
  ///

  start = new Date(start);
  end = new Date(end);

  let tutor = await getTutorDetail(tutorId);
  tutor = tutor.data;
  // Tutor's events that are free
  let tutorFreeEvents = await getTutorFreeEvents(tutor, start, end);
  tutorFreeEvents = tutorFreeEvents.data;
  tutorFreeEvents = tutorFreeEvents.map((event) => ({
    start: new Date(event.start.dateTime),
    end: new Date(event.end.dateTime),
  }));
  const sortByStartTime = (a, b) => a.start - b.start;
  const sortedFreeEvents = tutorFreeEvents.slice().sort(sortByStartTime);
  console.log("sortedFreeEvents");
  console.log(sortedFreeEvents);

  //index_day -> events that started and ended on that day (will split if needed)
  const eventsDict = {};
  for (const event of sortedFreeEvents) {
    if (!event) {
      continue;
    }
    let start = event.start;
    let end = event.end;
    if (start.getDay() == end.getDay()) {
      if (start > tomorrow) {
        if (start.getDay() in eventsDict) {
          eventsDict[start.getDay()].push(event);
        } else {
          eventsDict[start.getDay()] = [event];
        }
      }
    } else {
      let breakPoint = new Date(start);
      breakPoint.setDate(start.getDate() + 1);
      breakPoint.setHours(0, 0, 0, 0);
      console.log(`For start = ${start} and end = ${end}: `);
      console.log(`Adding breakpoint ${breakPoint}`);
      let newEvent1 = {
        // id: event.id,
        // title: event.title,
        // body: event.body,
        start: start, //.toJSON(),
        end: end, //.toJSON(),
        // calendarId: event.calendarId,
      };
      let newEvent2 = {
        id: event.id,
        start: breakPoint, //.toJSON(),
        title: event.title,
        body: event.body,
        end: end, //.toJSON(),
        calendarId: event.calendarId,
      };
      if (start > todayMorning && start < breakPoint) {
        if (start.getDay() in eventsDict) {
          eventsDict[start.getDay()].push(newEvent1);
        } else {
          eventsDict[start.getDay()] = [newEvent1];
        }
      }
      if (end < endWeek && breakPoint < end) {
        if (end.getDay() in eventsDict) {
          eventsDict[end.getDay()].push(newEvent2);
        } else {
          eventsDict[end.getDay()] = [newEvent2];
        }
      }
    }
  }

  console.log("eventsDict");
  console.log(eventsDict);
  //Now making the blocked events from the freeEvents (basically the oppositve to the freeEvents)
  let blockedEvents = [];
  for (let key = 0; key < 7; key++) {
    let start = new Date(today);
    start.setHours(0, 0, 0, 0);
    start.setDate(today.getDate() + key);
    let end = new Date(start);
    end.setDate(start.getDate() + 1);
    console.log(`Start: ${start.getDay()}`);
    if (!(start.getDay() in eventsDict)) {
      console.log("Not found, so add all day");
      // If there were no events for that day, then add a blocked time for the full day
      blockedEvents.push({
        // id: makeid(8),
        // calendarId: tutor.calendarId,
        start: start,
        end: end,
        // category: "time",
        // isReadOnly: true,
        // backgroundColor: "rgba(52, 52, 52, 0.2)",
        // borderColor: "rgba(52, 52, 52, 0.2)",
      });
      continue;
    }
    let eventsThatDay = eventsDict[start.getDay()];
    if (!eventsThatDay) {
      console.log("Not found :(");
      continue;
    }
    // If the first one doesn't start at 00:00, then add [0, start] as a blocked time
    console.log(eventsThatDay[0].start);
    console.log(start);
    if (eventsThatDay[0].start > start) {
      console.log(
        "First one doesn't start at 00:00, so add [0, start] as a blocked time"
      );
      blockedEvents.push({
        // id: makeid(8),
        start: start,
        end: eventsThatDay[0].start,
        // calendarId: tutor.calendarId,
        // category: "time",
        // isReadOnly: true,
        backgroundColor: "rgba(52, 52, 52, 0.2)",
        borderColor: "rgba(52, 52, 52, 0.2)",
      });
    }
    // If it doesn't end at 23:59, then add [end, 00:00] as a blocked time
    if (eventsThatDay[eventsThatDay.length - 1].end < end) {
      console.log(
        "Last one doesn't end at 23:59, then add [end, 00:00] as a blocked time"
      );
      console.log("Adding ");
      console.log(eventsThatDay[eventsThatDay.length - 1].end);
      console.log("to" + end);
      blockedEvents.push({
        // id: makeid(8),
        calendarId: tutor.calendarId,
        start: eventsThatDay[eventsThatDay.length - 1].end,
        end: end,
        category: "time",
        isReadOnly: true,
        backgroundColor: "rgba(52, 52, 52, 0.2)",
        borderColor: "rgba(52, 52, 52, 0.2)",
      });
    }
    //Go through all events for that day and add gaps as blocked times
    for (let i = 0; i < eventsThatDay.length - 1; i++) {
      // If there is a gap with the next one, add that as a blocked time
      if (eventsThatDay[i + 1].start > eventsThatDay[i].end) {
        console.log("Added gap!");
        blockedEvents.push({
          // id: makeid(8),
          start: eventsThatDay[i].end, //.toJSON(),
          end: eventsThatDay[i + 1].start, //.toJSON(),
          // calendarId: tutor.calendarId,
          // category: "time",
          // isReadOnly: true,
          // backgroundColor: "rgba(52, 52, 52, 0.2)",
          // borderColor: "rgba(52, 52, 52, 0.2)",
        });
      }
    }
  }
  console.log("blocked events only counting free events");
  console.log(blockedEvents);

  //Add all classes as blocked events, where each event is on one day only
  ////

  // Tutor's classes. Should be blocked
  let tutorClasses = await getClasses({
    tutorId: tutorId,
    start: { $gte: start },
    end: { $lte: end },
  });
  tutorClasses = tutorClasses.data;

  // Student's classes. Should be blocked, there might be overlap
  let studentClasses = await getClasses({
    studentIds: userId,
    start: { $gte: start },
    end: { $lte: end },
  });
  studentClasses = studentClasses.data;
  console.log("tutorClasses");
  console.log(tutorClasses);
  console.log("studentClasses");
  console.log(studentClasses);
  /////

  let classes = [...tutorClasses, ...[]];
  console.log("classes condisering");
  console.log(classes);
  classes = classes.map((currClass) => ({
    start: new Date(currClass.start),
    end: new Date(currClass.end),
  }));

  let sortedClasses = classes.slice().sort(sortByStartTime);
  for (let currentClass of sortedClasses) {
    let start = new Date(currentClass.start);
    let end = new Date(currentClass.end);
    if (start.getDay() === end.getDay()) {
      blockedEvents.push({
        // id: makeid(8),
        start: currentClass.start, //.toJSON(),
        end: currentClass.end, //.toJSON(),
        // calendarId: tutor.calendarId,
        // category: "time",
        // isReadOnly: true,
        // backgroundColor: "rgba(52, 52, 52, 0.2)",
        // borderColor: "rgba(52, 52, 52, 0.2)",
      });
    } else {
      let breakPoint = new Date(start);
      breakPoint.setDate(start.getDate() + 1);
      breakPoint.setHours(0, 0, 0, 0);
      blockedEvents.push({
        // id: currentClass.id,
        // title: currentClass.title,
        // body: currentClass.body,
        start: start, //.toJSON(),
        end: breakPoint, //.toJSON(),
        // calendarId: currentClass.calendarId,
      });
      blockedEvents.push({
        // id: currentClass.id,
        start: breakPoint, //.toJSON(),
        // title: currentClass.title,
        // body: currentClass.body,
        end: end, //.toJSON(),
        // calendarId: currentClass.calendarId,
      });
    }
  }
  console.log(
    "blocked events counting free events and classes, possible overlapping"
  );
  console.log(blockedEvents);

  // Now `blockedEvents` are possibly-crashing, so merge overlappting ones
  let noCrashEvents = [];
  let eventsSorted = blockedEvents.slice().sort((a, b) => a.start - b.start);
  console.log("sorting by start...");
  console.log(eventsSorted);
  let i = 0;
  while (i < eventsSorted.length) {
    let noCrashStart = eventsSorted[i].start;
    let noCrashEnd = eventsSorted[i].end;
    let j = i;
    //As long as it overlaps, merge the blocked event
    while (
      j + 1 < eventsSorted.length && //valid index
      noCrashStart <= eventsSorted[j + 1].start && //valid start
      eventsSorted[j + 1].start <= noCrashEnd && //overlap
      eventsSorted[j + 1].start.getDay() === noCrashStart.getDay() //same day
    ) {
      // Only update the end if it's bigger than the current one
      if (eventsSorted[j + 1].end > noCrashEnd) {
        noCrashEnd = eventsSorted[j + 1].end;
      }
      j += 1;
    }
    i = j + 1;
    //Add merged event

    if (noCrashStart.getDay() !== noCrashEnd.getDay()) {
      noCrashEnd = new Date(new Date(noCrashStart).setHours(23, 59, 59));
    }
    noCrashEvents.push({
      // id: makeid(8),
      // event_id: new Date(noCrashStart).getTime(),

      event_id: i,
      color: "gray",
      start: new Date(noCrashStart), //.toJSON(),
      end: new Date(noCrashEnd), //.toJSON(),
      title: "Ocupado",

      draggable: false,
      editable: false,
      deletable: false,
      disabled: true,

      // calendarId: tutor.calendarId,
      // category: "time",
      // isReadOnly: true,
      // backgroundColor: "rgba(52, 52, 52, 0.2)",
      // borderColor: "rgba(52, 52, 52, 0.2)",
    });
  }
  console.log("Final no crash events: ");
  console.log(noCrashEvents);
  // return noCrashEvents;
  return noCrashEvents;
};
