import React from "react";
import {
  Modal,
  Image,
  InputRightElement,
  ModalBody,
  Text,
  Link,
  InputGroup,
  Checkbox,
  VStack,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalFooter,
  FormControl,
  ModalCloseButton,
  Button,
  FormLabel,
  Input,
  Stack,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import divide from "./../assets/Group 108.png";
import google from "../assets/Group 6.png";
import { useNavigate } from "react-router";
import { useState } from "react";
import { validateEmail } from "../utils";
import { loginUser, loginUserGoogle } from "../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MAIN_COLOR } from "../utils/constants";
import { useGoogleLogin } from "@react-oauth/google";

export default function LoginModal() {
  const loginGoogleButton = useGoogleLogin({
    onSuccess: async (response) => {
      console.log(`Response from google`);
      console.log(response);
      dispatch(loginUserGoogle(response));
    },
    flow: "auth-code",
    scope: [],
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { isFetching, isSuccess } = useSelector((state) => state.user);
  //login for mobile devices
  const LoginHandler = async () => {
    const Email = email.trim();
    const isEmail = validateEmail(Email);
    const Password = password.trim();

    if (!Email || !Password) {
      toast({
        title: "All field are required",
        status: "error",
        position: "top-center",
        duration: 4000,
        isClosable: true,
      });
    }
    if (!isEmail && Email !== "") {
      toast({
        title: "Please Enter a Valid Email",
        status: "error",
        position: "top-center",
        duration: 5000,
        isClosable: true,
      });
    }

    if (!!Email && isEmail && !!Password) {
      const loginObj = {
        email: Email,
        password: Password,
      };
      console.log(loginObj);
      dispatch(loginUser(loginObj));

      setEmail("");
      setPassword("");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);
  return (
    <>
      <Button onClick={onOpen}>Iniciar sesión</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Iniciar sesión</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack justifySelf={"center"}>
              <Button
                bgColor={"#2A99FF"}
                color={"whiteAlpha.900"}
                fontWeight={500}
                fontSize={23}
                p={7}
                onClick={loginGoogleButton}
              >
                <Image src={google} />
                &nbsp; | Continúa con Google
              </Button>
              <Stack mt={10}></Stack>
              <Image maxW={"100%"} src={divide} />
              <Stack mt={10}></Stack>
            </Stack>
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                ref={initialRef}
                placeholder="First name"
              />
            </FormControl>

            <FormControl mt={4} verticalAlign={"center"}>
              <FormLabel>Contraseña</FormLabel>
              {/* <Input placeholder='Last name' /> */}

              <PasswordSmInput setPassword={setPassword} password={password} />
            </FormControl>
            <Text textAlign={"right"} color={MAIN_COLOR} fontSize={12}>
              <u>
                <Link href="/newpassword">¿Olvidaste tu contraseña?</Link>
              </u>
            </Text>
            {/* <Checkbox defaultChecked fontWeight={500} mt={10}>
              Recordarme
            </Checkbox> */}
          </ModalBody>

          <VStack align={"center"} m={10}>
            <Button
              leftIcon={isFetching ? <Spinner /> : null}
              bgColor={MAIN_COLOR}
              color={"whiteAlpha.900"}
              mr={3}
              onClick={LoginHandler}
            >
              Iniciar sesión
            </Button>
            {/* <Text color={"black.400"} fontSize={12}>
              ¿Aún no tienes una cuenta?
            </Text>
            <Text color={MAIN_COLOR} fontSize={12}>
              <u>
                <Link href="#">¿Olvidaste tu contraseña?</Link>
              </u>
            </Text> */}
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

function PasswordSmInput({ setPassword, password }) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <VStack display={"block"}>
      <InputGroup size="md">
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Your password"
          pr="4.5rem"
          type={show ? "text" : "password"}
          border={"1px solid gray"}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClick}>
            {show ? <ViewOffIcon /> : <ViewIcon />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </VStack>
  );
}
