import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  WrapItem,
  Stack,
  Input,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Images } from "../../utils";
import {
  getDayInfo,
  getHourAndMinutes,
  getValidDriveLink,
} from "../../utils/functions";
import { CancelClass } from "../../utils/ApiConfig";
import { MAIN_COLOR } from "../../utils/constants";
import { useSelector } from "react-redux";

const CancelClassModal = ({
  isOpen,
  onClose,
  classId,
  tutorName,
  tutorImage,
  classTitle,
  classDescription,
  isTutor,
  participantMessage,
  classInfo,
}) => {
  const [canceled, setCanceled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.user);
  const [cancelReason, setCancelReason] = useState("");

  const classCancelHandler = async () => {
    setIsLoading(true);
    let user_who_canceled = {
      _id: userInfo._id,
      name: userInfo.name,
      role: userInfo.role,
      surname: userInfo.surname,
      phone: userInfo.phone,
    };
    CancelClass(classId, {
      user_who_canceled,
      cancelReason,
    }).then(() => {
      setIsLoading(false);
      setCanceled(true);
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    });
  };
  const closeHandler = () => {
    setCanceled(false);
    onClose();
  };
  const getStrikeMessage = () => {
    let now = new Date();
    let start = new Date(classInfo.start);
    let diff = start - now;
    const SHORT_TIME_TO_REJECT_CONFIRMED_CLASS = 24 * 60 * 60 * 1000; // 24 hours
    let too_short = diff < SHORT_TIME_TO_REJECT_CONFIRMED_CLASS;
    let strike = too_short ? 1 : 0.5;
    let reason = too_short
      ? "canceló con menos de 24 horas"
      : "canceló con más de 24 horas";

    return `Se te descontará ${strike}h [${reason}]`;
  };
  if (!userInfo?._id) {
    return "Cargando usuario... (si toma mucho timepo vuelva a iniciar sesión)";
  }
  return (
    <>
      <Modal size={"lg"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          onClick={onClose}
          backgroundColor={"rgba(65, 65, 65, 0.3)"}
        />
        <ModalContent p={5}>
          <ModalHeader alignSelf={"center"}>
            Estás seguro que quieres cancelar esta clase?
            {isTutor && (
              <Text color="red" fontSize="sm">
                {getStrikeMessage()}
              </Text>
            )}
          </ModalHeader>

          <ModalBody>
            <Box
              display={"flex"}
              gap={4}
              flexDir={{ base: "column", md: "row" }}
            >
              <Box width={{ base: "100%", md: "initial" }} display={"flex"}>
                <WrapItem position={"relative"}>
                  <Avatar
                    zIndex={1}
                    border={" 3.64583px solid rgba(68, 68, 68, 0.25)"}
                    size={"xl"}
                    name={isTutor ? participantMessage : tutorName}
                    src={isTutor ? "" : tutorImage}
                  ></Avatar>

                  {/* <Avatar
                    bottom={0}
                    right={0}
                    zIndex={10}
                    position={"absolute"}
                    size={"sm"}
                    borderRadius={"50%"}
                    src={Images.IB_IMAGE}
                  ></Avatar> */}
                </WrapItem>
              </Box>
              <Box>
                <Text
                  textDecoration={canceled ? "line-through" : "none"}
                  fontSize={"lg"}
                  fontWeight={600}
                >
                  {classTitle}
                </Text>
                <Text
                  textDecoration={canceled ? "line-through" : "none"}
                  fontSize={"md"}
                  fontWeight={500}
                >
                  {""}
                  {/* {modalData.tutor} */}
                </Text>
                <Text
                  fontSize={"xl"}
                  textDecoration={canceled ? "line-through" : "none"}
                  color={canceled ? "#FF2B2B" : MAIN_COLOR}
                  fontWeight={600}
                >
                  {/* {modalData.timing} */}
                  {classDescription}
                </Text>
              </Box>
            </Box>
            <Stack>
              <Text fontSize={"md"}>
                Por favor provee una razón para el cancelo de la clase.
              </Text>
              <Input
                type="text"
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
              />
            </Stack>
          </ModalBody>

          <ModalFooter alignSelf={{ base: "flex-start", md: "center" }}>
            <Button
              onClick={closeHandler}
              backgroundColor={"#E8E8E8"}
              color={"#8D8D8D"}
              isDisabled={isLoading}
            >
              Volver
            </Button>

            {!canceled && (
              <Button
                backgroundColor={"#FF2B2B"}
                ml={12}
                color={"#FCFCFC"}
                onClick={classCancelHandler}
                isLoading={isLoading}
                isDisabled={cancelReason === ""}
              >
                Sí, cancelar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelClassModal;
