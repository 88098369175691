import React, { useState } from "react";
// import { CalendarModal, ReportClassModal } from "../../components";
import CalendarModal from "../../components/CalendarModal/CalendarModal";
import ReportClassModal from "../../components/CalendarModal/ReportClassModal";
import { Button, Text, useDisclosure, Stack, Flex } from "@chakra-ui/react";
import RateClassModal from "./RateClassModal";
import { useSelector } from "react-redux";
import StarRating from "../../components/StarRating";
import ViewTutorFeedbackModal from "./ViewTutorFeedbackModal";

const PastClassModal = ({
  event,
  subjectInfo,
  tutorInfo,
  classInfo,
  subjectDescription,
  timeDescription,
}) => {
  const { userInfo } = useSelector((state) => state.user);
  const [submittedRating, setSubmittedRating] = useState(0);
  const {
    isOpen: isOpenViewTutorFeedback,
    onOpen: onOpenViewTutorFeedback,
    onClose: onCloseViewTutorFeedback,
  } = useDisclosure();

  if (!userInfo) {
    return "Cargando...";
  }
  // Each feedback has sender, text and rating
  const getUserFeedback = () => {
    let feedback = classInfo.individualStudentFeedback.filter(
      (feedback) => feedback.sender === userInfo._id
    );
    if (feedback && feedback.length > 0) {
      return feedback[0];
    }
    return null;
  };

  return (
    <Flex style={{ margin: 20 }} flexDirection="column" alignItems="center">
      {/* <Stack direction gap={3}>
        <Button backgroundColor={"#0ED37B"} color={"white"} onClick={onOpen}>
          <Text>Cuéntanos cómo te fue!</Text>
        </Button>

      </Stack> */}
      {submittedRating || getUserFeedback() ? (
        <StarRating
          size={{ base: 4, md: 4, lg: 5 }}
          icon="star"
          scale={5}
          fillColor="gold"
          strokeColor="grey"
          rating={submittedRating || getUserFeedback().rating}
          setRating={(val) => {}} //static
        />
      ) : (
        <RateClassModal
          subjectInfo={subjectInfo}
          tutorInfo={tutorInfo}
          classInfo={classInfo}
          subjectDescription={subjectDescription}
          timeDescription={timeDescription}
          senderInfo={userInfo}
          onSubmitFeedback={({ rating }) => {
            setSubmittedRating(rating);
          }}
        />
      )}
      <ViewTutorFeedbackModal
        isOpen={isOpenViewTutorFeedback}
        onOpen={onOpenViewTutorFeedback}
        onClose={onCloseViewTutorFeedback}
        tutorFeedback={classInfo.tutorFeedback}
      />
      <Flex alignItems={"center"} marginTop={10}>
        {classInfo.tutorFeedback ? (
          <Button color="white" bg="#07A58E" onClick={onOpenViewTutorFeedback}>
            Ver reporte del tutor
          </Button>
        ) : (
          <Text color="#07A58E" alignSelf={{ base: "none", md: "center" }}>
            El tutor aún no manda su reporte. Este atento!
          </Text>
        )}
      </Flex>

      {/* <CalendarModal
        classCancelHandler={classCancelHandler}
        classCancel={false}
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        modalData={ModalData1}
      />
      <CalendarModal
        classCancel={true}
        modalData={ModalData2}
        onOpen={onOpen1}
        isOpen={isOpen1}
        onClose={onClose1}
      /> */}
      {/* <ReportClassModal onOpen={onOpen2} isOpen={isOpen2} onClose={onClose2} /> */}
    </Flex>
  );
};

export default PastClassModal;
