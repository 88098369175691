import React, { useState, useEffect } from "react";
import {
  Button,
  Stack,
  Textarea,
  HStack,
  Avatar,
  AvatarBadge,
  VStack,
  ModalBody,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  Text,
  ModalCloseButton,
  ModalHeader,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
// import StarRating from "./StarRating";
import { getValidDriveLink, encodeFeedback } from "../../utils/functions";
import { sendStudentFeedback } from "../../utils/ApiConfig";
import StarRating from "../../components/StarRating";
import { MAIN_COLOR } from "../../utils/constants";

export default function RateClassModal({
  subjectInfo,
  tutorInfo,
  classInfo,
  subjectDescription,
  timeDescription,
  senderInfo,
  onSubmitFeedback,
  startOpen,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    isOpen: isOpenMod,
    onOpen: onOpenMod,
    onClose: onCloseMod,
  } = useDisclosure();

  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();
  useEffect(() => {
    if (startOpen) {
      onOpen();
    }
  }, []);
  const handleSendFeedback = async () => {
    if (rating === 0) {
      setErrorMessage("Por favor califica esta clase del 1 al 5");
      return;
    }
    if (feedback === "") {
      setErrorMessage("Por favor provee feedback para la clase.");
      return;
    }
    setIsLoading(true);

    // Following this format to be compatible with the previous feedback given by students
    let newStudentFeedback = `-Feedback de ${
      senderInfo.name
    }:\n\n${encodeFeedback(feedback)}`;
    let { success } = await sendStudentFeedback({
      id: classInfo._id,
      rating: rating,
      newStudentFeedback,
      sender: senderInfo._id,
    });
    setIsLoading(false);
    if (success) {
      onClose(); //Close current modal
      onOpenMod(); //Open next modal
    } else {
      onOpenErrorModal();
    }
  };
  return (
    <>
      <Button
        onClick={onOpen}
        minW={"120px"}
        w={"120px"}
        alignSelf={"right"}
        rounded={"lg"}
        color={"#7A4E4E"}
        fontWeight={500}
        bgColor={"#F6EBAE"}
        leftIcon={<StarIcon />}
      >
        Evaluar
      </Button>

      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={{ base: 5, md: 10 }}>
          <ModalHeader>
            <Text textAlign={"center"} color={MAIN_COLOR}>
              Feedback de {senderInfo.name} {senderInfo.surname}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={{ base: 2, md: 5 }}>
              <Text
                fontSize={{ base: "90%", md: "125%" }}
                fontWeight={600}
                textAlign={"center"}
              >
                ¡Gracias por tomar clases con IB Masters! <br /> Cuéntanos tu
                experiencia en esta clase
              </Text>
              <Avatar
                size={{ base: "lg", md: "xl", lg: "2xl" }}
                name={tutorInfo?.name}
                src={getValidDriveLink(tutorInfo?.imageDriveLink)}
              >
                {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
              </Avatar>
              <Text>{subjectDescription}</Text>
              <StarRating
                size={{ base: 4, md: 5, lg: 8 }}
                icon="star"
                scale={5}
                fillColor="gold"
                strokeColor="grey"
                rating={rating}
                setRating={(val) => {
                  setErrorMessage(""); //resetting
                  setRating(val);
                }}
              />
              <Textarea
                placeholder="¿Qué te gustó de la clase y del tutor? ¿En qué crees que tú puedes mejorar? ¿Cómo podría mejorar la clase y tu tutor?"
                size="sm"
                resize={"None"}
                value={feedback}
                onChange={(e) => {
                  setErrorMessage("");
                  setFeedback(e.target.value);
                }}
              />
              {errorMessage && <Text color={"red"}>{errorMessage}</Text>}
              <Button
                bgColor={MAIN_COLOR}
                color={"white"}
                onClick={() => {
                  handleSendFeedback();
                }}
                isLoading={isLoading}
              >
                Enviar
              </Button>
              <Text
                fontSize={{ base: "80%", md: "110%" }}
                fontWeight={600}
                textAlign={"center"}
              >
                Tu calificación nos ayuda a seguir mejorando. Muchas gracias.
              </Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal size={"xl"} isOpen={isOpenErrorModal} onClose={onCloseErrorModal}>
        <ModalOverlay />
        <ModalContent p={{ base: 5, md: 10 }}>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={{ base: 2, md: 5 }}>
              <Text
                fontSize={{ base: "90%", md: "125%" }}
                fontWeight={600}
                textAlign={"center"}
              >
                Oops! Algo inesperado pasó 🫠
              </Text>

              <Text>Por favor vuelve a intentarlo</Text>

              <Button
                bgColor={"red"}
                color={"white"}
                onClick={onCloseErrorModal}
              >
                Volver
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size={"2xl"}
        isOpen={isOpenMod}
        onClose={() => {
          onSubmitFeedback({ rating, text: feedback, sender: senderInfo._id });
          onCloseMod();
        }}
      >
        <ModalOverlay />
        <ModalContent p={{ base: 5, md: 10 }}>
          <ModalHeader>
            <Text textAlign={"center"} color={MAIN_COLOR}>
              Feedback de {senderInfo.name} {senderInfo.surname}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack gap={10}>
              <Text
                fontSize={{ base: "90%", md: "125%" }}
                fontWeight={600}
                textAlign={"center"}
              >
                ¡Gracias por compartirnos tu opinión! 🤩
              </Text>
              <HStack gap={{ base: 2, md: 5 }}>
                <Avatar
                  size={{ base: "lg", md: "xl", lg: "2xl" }}
                  name={tutorInfo?.name}
                  src={getValidDriveLink(tutorInfo?.imageDriveLink)}
                >
                  {/* <AvatarBadge boxSize="1.25em" bg="green.500" /> */}
                </Avatar>
                <Stack align={"start"} direction={"column"}>
                  <Text fontWeight={500}>{subjectDescription}</Text>
                  <Text fontSize={"80%"}>{timeDescription}</Text>
                  <StarRating
                    size={{ base: 4, md: 4, lg: 5 }}
                    icon="star"
                    scale={5}
                    fillColor="gold"
                    strokeColor="grey"
                    rating={rating}
                    setRating={(val) => {}}
                  />
                  {/* <Text as="i">{feedback}</Text> */}
                </Stack>
              </HStack>
              <Button
                bgColor={MAIN_COLOR}
                color={"white"}
                onClick={() => {
                  onSubmitFeedback({
                    rating,
                    text: feedback,
                    sender: senderInfo._id,
                  });
                  onCloseMod();
                }}
              >
                Volver!
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
