import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const ProtectedRoute = ({ children }) => {
  const { userInfo, isSuccess } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const googleToken = localStorage.getItem("googleRefreshToken");

  if (!userInfo && isSuccess) {
    return <Navigate to="/login" />;
  }

  if (userInfo?.role === "TempUser") {
    return <Navigate to="/login" />;
  }

  if (!token && !googleToken) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;
