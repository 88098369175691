import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Image,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  ChakraProvider,
  Tooltip,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";
// import logo from "../assets/LOGO-2 1.png";
import logo from "../assets/IB_MASTERS_LOGO.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/userSlice";
import {
  getHoursFromFamily,
  getValidDriveLink,
  getHourParts,
  getTimeUntilHoursExpire,
} from "../utils/functions";
import { getFamilyInfo, getClasses } from "../utils/ApiConfig";
import { MAIN_COLOR, LIGHT_COLOR } from "../utils/constants";
// const Links = ['clases', 'profesores', 'chat'];

export default function Header() {
  const [hours, setHours] = useState(0);
  const [hourParts, setHourParts] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pendingClasses, setPendingClasses] = useState([]);
  const { userInfo } = useSelector((state) => state.user);
  const [foundFamily, setFoundFamily] = useState({});

  const onClickHandler = useCallback(() => {
    navigate("/comprar");
  }, [navigate]);

  const SettingsHandler = useCallback(() => {
    navigate("/ajustes");
  }, [navigate]);
  const FaqNavigate = useCallback(() => {
    navigate("/faq");
  }, [navigate]);
  const logoutHandler = useCallback(() => {
    dispatch(logout());
    localStorage.clear();
    navigate("/login");
  }, [navigate]);

  const MyProfileHandler = useCallback(() => {
    navigate("/miperfil");
  }, [navigate]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    async function get_info() {
      if (userInfo.role === "Tutor") {
        let found = await getClasses({
          pending: true,
          tutorId: userInfo._id,
          end: { $gte: new Date() },
        });
        found = found?.data || [];
        setPendingClasses(found);
      } else {
        let family = await getFamilyInfo(userInfo.family);
        setHours(getHoursFromFamily(family));
        let allHours = getHourParts(family);
        setHourParts(allHours);
        setFoundFamily(family);
      }
    }
    get_info();
  }, [userInfo]);
  const getExpireColor = (hours) => {
    // TODO: Check what colors look good and also good thresholds
    return "";

    if (hours < 3) {
      return "red";
    } else if (hours < 6) {
      return "orange";
    } else {
      return "green";
    }
  };
  return (
    <ChakraProvider>
      <Box w={"100vw"} bg={useColorModeValue(MAIN_COLOR, MAIN_COLOR)} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} gap={10} alignItems={"center"}>
            <Box color={"white"}>
              <Link to="/" as={NavLink}>
                <Image src={logo} w={"120px"} alt="..." />
              </Link>
            </Box>
            <HStack
              color={"white"}
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
              gap={{ lg: 3, xl: 7 }}
            >
              <Link
                as={NavLink}
                px={2}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                }}
                to={"/clases"}
                _activeLink={{
                  bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                }}
              >
                <Box display={"flex"} gap={2} alignItems={"center"}>
                  <Text>Clases</Text>
                  {userInfo?.role === "Tutor" && pendingClasses.length > 0 && (
                    <Text
                      width={"1.1rem"}
                      height={"1.1rem"}
                      color={"white"}
                      backgroundColor={"red"}
                      rounded={"50%"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontSize={"sm"}
                    >
                      {pendingClasses.length}
                    </Text>
                  )}
                </Box>
              </Link>
              {userInfo?.role !== "Tutor" && (
                <Link
                  as={NavLink}
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    // bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                    bg: LIGHT_COLOR,
                  }}
                  to={"/reservar"}
                  // _activeLink={{ bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR) }}
                  _activeLink={{ bg: LIGHT_COLOR }}
                >
                  <Box display={"flex"} gap={2} alignItems={"center"}>
                    <Text>Reservar</Text>
                  </Box>
                </Link>
              )}
              {userInfo?.role === "Tutor" ? (
                <Link
                  as={NavLink}
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: LIGHT_COLOR,
                  }}
                  _activeLink={{ bg: LIGHT_COLOR }}
                  to={"/miperfil"}
                >
                  Perfil
                </Link>
              ) : (
                <></>
                // <Link
                //   as={NavLink}
                //   px={2}
                //   py={1}
                //   rounded={"md"}
                //   _hover={{
                //     textDecoration: "none",
                //     bg: LIGHT_COLOR,
                //   }}
                //   _activeLink={{ bg: LIGHT_COLOR }}
                //   to={"/profesores"}
                // >
                //   Profesores
                // </Link>
              )}
              {/* <Link
                as={NavLink}
                px={2}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                }}
                _activeLink={{ bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR) }}
                to={"/chat"}
              >
                Chat
              </Link> */}
              <Link
                as={NavLink}
                px={2}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: LIGHT_COLOR,
                }}
                _activeLink={{ bg: LIGHT_COLOR }}
                to={"/referidos"}
              >
                Referidos
              </Link>
              {/* {userInfo?.role === "Tutor" ? (
                <></>
              ) : (

              )} */}
            </HStack>
          </HStack>
          <Flex alignItems={"center"}>
            {(userInfo?.role === "Student" || userInfo?.role === "Parent") && (
              <>
                <Tooltip
                  hasArrow
                  label={
                    <Stack>
                      <Text>Tutorías: {hourParts.tutoring} </Text>
                      <Text>Tutorías grupo: {hourParts.tutoringGroup} </Text>
                      <Text>Especializadas: {hourParts.specialized} </Text>
                      <Text>
                        Especializadas en grupo: {hourParts.specializedGroup}
                      </Text>
                    </Stack>
                  }
                >
                  <Text
                    display={{ base: "none", md: "block" }}
                    color={"white"}
                    textAlign="center"
                  >
                    Balance: {hours} {hours !== 1 ? "horas" : "hora"}
                    {hours > 0 && (
                      <Text
                        fontSize={"sm"}
                        fontStyle={"italic"}
                        fontWeight={"bold"}
                        color={getExpireColor(hours)}
                      >
                        Expirarán en{" "}
                        {getTimeUntilHoursExpire(foundFamily.lastTimePurchase)}.
                      </Text>
                    )}
                  </Text>
                </Tooltip>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant={"solid"}
                  bgColor={"white"}
                  color={MAIN_COLOR}
                  size={"sm"}
                  mr={4}
                  // leftIcon={<AddIcon />}
                  onClick={onClickHandler}
                >
                  Comprar
                </Button>
              </>
            )}
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Avatar
                  size={"sm"}
                  src={
                    userInfo?.role === "Tutor" &&
                    getValidDriveLink(userInfo.imageDriveLink)
                  }
                  name={userInfo?.name}
                  // src={
                  //   'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  // }
                />
              </MenuButton>
              <MenuList zIndex={"tooltip"}>
                {userInfo?.role === "Tutor" ? (
                  <></>
                ) : (
                  <MenuItem onClick={MyProfileHandler}>Mi Perfil</MenuItem>
                )}
                <MenuItem onClick={SettingsHandler}>Ajustes</MenuItem>
                <MenuItem onClick={FaqNavigate}>Preguntas Frecuentes</MenuItem>
                {/* <MenuItem>Ayuda</MenuItem> */}
                <MenuDivider />
                <MenuItem onClick={logoutHandler} color={"red"}>
                  <CloseIcon /> &nbsp; Cerrar sesión
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "none" }}>
            <Stack as={"nav"} spacing={4} color={"white"}>
              <Link
                px={2}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: LIGHT_COLOR,
                  // bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                }}
                href={"/clases"}
              >
                Clases
              </Link>
              {userInfo?.role !== "Tutor" && (
                <Link
                  as={NavLink}
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    // bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                    bg: LIGHT_COLOR,
                  }}
                  to={"/reservar"}
                  // _activeLink={{ bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR) }}
                  _activeLink={{ bg: LIGHT_COLOR }}
                >
                  <Box display={"flex"} gap={2} alignItems={"center"}>
                    <Text>Reservar</Text>
                  </Box>
                </Link>
              )}
              {userInfo?.role === "Tutor" ? (
                <Link
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: LIGHT_COLOR,
                    // bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                  }}
                  href={"/miperfil"}
                >
                  Perfil
                </Link>
              ) : (
                <></>
                // <Link
                //   px={2}
                //   py={1}
                //   rounded={"md"}
                //   _hover={{
                //     textDecoration: "none",
                //     bg: LIGHT_COLOR,
                //     // bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                //   }}
                //   href={"/profesores"}
                // >
                //   Profesores
                // </Link>
              )}
              {/* <Link
                px={2}
                py={1}
                rounded={"md"}
                _hover={{
                  textDecoration: "none",
                  bg: LIGHT_COLOR,
                  // bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                }}
                href={"/chat"}
              >
                Chat
              </Link> */}
              {userInfo?.role === "Tutor" ? (
                <></>
              ) : (
                <Link
                  px={2}
                  py={1}
                  rounded={"md"}
                  _hover={{
                    textDecoration: "none",
                    bg: LIGHT_COLOR,
                    // bg: useColorModeValue(LIGHT_COLOR, LIGHT_COLOR),
                  }}
                  href={"/referidos"}
                >
                  Referidos
                </Link>
              )}
              {(userInfo?.role === "Student" ||
                userInfo?.role === "Parent") && (
                <Tooltip
                  hasArrow
                  label={
                    <Stack>
                      <Text>Tutorías: {hourParts.tutoring} </Text>
                      <Text>Tutorías grupo: {hourParts.tutoringGroup} </Text>
                      <Text>Especializadas: {hourParts.specialized} </Text>
                      <Text>
                        Especializadas en grupo: {hourParts.specializedGroup}
                      </Text>
                    </Stack>
                  }
                >
                  <Text
                    display={{ base: "block", md: "none" }}
                    color={"white"}
                    textAlign="center"
                  >
                    Balance: {hours} {hours !== 1 ? "horas" : "hora"}
                    {hours > 0 && (
                      <Text
                        fontSize={"sm"}
                        fontStyle={"italic"}
                        fontWeight={"bold"}
                        color={getExpireColor(hours)}
                      >
                        Expirarán en{" "}
                        {getTimeUntilHoursExpire(foundFamily.lastTimePurchase)}.
                      </Text>
                    )}
                  </Text>
                </Tooltip>
              )}
            </Stack>
          </Box>
        ) : null}
      </Box>

      {/* <Box p={4}>Main Content Here</Box> */}
    </ChakraProvider>
  );
}
